import { type ChangeEvent, type ReactNode } from "react";
import { inputStyles, labelledInputStyles } from "./inputStyles";
import { Box, Typography, useTheme } from "@mui/material";
import {
    InputValidationError,
    ValidationError,
} from "components/properties/ValidationError";

interface SelectProps<T extends string> {
    name: string;
    enumObject: Record<string, T>;
    handleSelectChange: (selectedOption: T, name: string) => void;
    label?: string | JSX.Element | ReactNode;
    mb?: string;
    fullWidth?: boolean;
    disabled?: boolean;
    hasError?: boolean;
}

interface LabeledSelectProps<T extends string> extends SelectProps<T> {
    value: string;
}

export const SearchSelectInput = <T extends string>({
    name,
    enumObject,
    hasError,
    handleSelectChange,
}: SelectProps<T>) => {
    const { customColors } = useTheme();
    const keys = Object.keys(enumObject);

    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.value as T;
        handleSelectChange(selectedOption, name);
    };

    return (
        <select
            onChange={handleChange}
            name={name}
            style={{
                ...inputStyles,
                width: "15%",
                margin: "10px",
                border: hasError
                    ? `2px solid ${customColors.colorError}`
                    : "1px solid gray",
            }}
        >
            {keys.map((key) => (
                <option key={key} value={enumObject[key]}>
                    {key}
                </option>
            ))}
        </select>
    );
};

export const LabeledSelectInput = <T extends string>({
    label,
    name,
    enumObject,
    handleSelectChange,
    fullWidth,
    value,
    mb = ".5rem",
    hasError,

    disabled,
}: LabeledSelectProps<T>) => {
    const { customColors } = useTheme();
    const keys = Object.keys(enumObject);

    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.value as T;
        handleSelectChange(selectedOption, name);
    };

    return (
        <Box sx={{ ...labelledInputStyles.wrapper }}>
            <Typography variant="caption">{label}</Typography>
            <select
                onChange={handleChange}
                name={name}
                style={{
                    ...inputStyles,
                    width: fullWidth ? "100%" : "15%",
                    border: hasError
                        ? `2px solid ${customColors.colorError}`
                        : "1px solid gray",
                }}
                value={value}
                disabled={disabled}
            >
                <option value="">Bitte Auswählen</option>
                {keys.map((key) => (
                    <option key={key} value={enumObject[key]}>
                        {key}
                    </option>
                ))}
            </select>
            {hasError && <InputValidationError />}
        </Box>
    );
};
