import { Box, Button } from "@mui/material";
import { useApp } from "services";
import { useNavigate } from "react-router-dom";
import { PrivatePropertySummary } from "components";

const PropertyListPage = () => {
    const navigate = useNavigate();
    const { privateProperties: properties, fetchPrivateProperties } = useApp();
    const loggedInUser = localStorage.getItem("user");

    return (
        <>
            <Button
                onClick={() => {
                    navigate("/property/search");
                }}
                sx={{ marginBottom: "25px" }}
            >
                {"Zur öffentlichen Immobilien suche  >"}
            </Button>
            <Box>
                {properties.map((p) => (
                    <PrivatePropertySummary
                        key={p.id}
                        property={p}
                        isOwner={p.user?.toString() === loggedInUser}
                        refetchProperties={fetchPrivateProperties}
                    />
                ))}
            </Box>
        </>
    );
};

export default PropertyListPage;
