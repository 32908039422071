import { type ChangeEvent, type ReactNode } from "react";
import { inputStyles, labelledInputStyles } from "./inputStyles";
import { Box } from "@mui/system";
import { type ChangeInputEvent } from "types";
import { Typography, useTheme } from "@mui/material";
import { usePropertyCreation } from "services/property-creation-context";
import { useParams } from "react-router-dom";
import { usePropertyEdit } from "services/property-edit-context";
import { InputValidationError } from "components/properties/ValidationError";

interface DateInputProps {
    name: string;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    value: string;
    fullWidth?: boolean;
    placeholder?: string;
    disabled?: boolean;
    mb?: string;
    hasError?: boolean;
}

export const DateSelectInput = ({
    name,
    placeholder,
    handleChange,
    fullWidth,
    value,
    disabled = false,
    hasError,
    mb = "1rem",
}: DateInputProps) => {
    const { customColors } = useTheme();
    return (
        <input
            type="date"
            name={name}
            placeholder={placeholder}
            onChange={handleChange}
            value={!value ? "" : value}
            disabled={disabled}
            style={{
                ...inputStyles,
                width: fullWidth ? "100%" : "15%",
                backgroundColor: disabled ? "#cecece" : "",
                cursor: disabled ? "default" : "pointer",
                marginBottom: mb || "",
                border: hasError
                    ? `2px solid ${customColors.colorError}`
                    : "1px solid gray",
            }}
        />
    );
};

export const LabelledDateSelectInput = ({
    label,
    name,
    placeholder,
    handleChange,
    fullWidth,
    mb,
    value,
    disabled = false,
    hasError,
}: {
    fullWidth?: boolean;
    name: string;
    label?: string | JSX.Element | ReactNode;
    placeholder?: string;
    handleChange: (e: ChangeInputEvent) => void;
    mb?: string;
    value: string;
    disabled?: boolean;
    hasError?: boolean;
}) => {
    const { id } = useParams();
    const { property } = id ? usePropertyEdit() : usePropertyCreation();
    return (
        <Box sx={{ ...labelledInputStyles.wrapper }}>
            <Typography variant="caption" sx={{ ml: ".5rem" }}>
                {label}
            </Typography>
            <DateSelectInput
                name={name}
                placeholder="dd.mm.yyyy"
                handleChange={handleChange}
                value={value}
                fullWidth={fullWidth}
                disabled={property.free_from !== "fix_date"}
                hasError={hasError}
            />
            {hasError && <InputValidationError />}
        </Box>
    );
};
