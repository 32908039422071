import {
    StepTwoForm,
    StepOneForm,
    StepFourForm,
    StepThreeForm,
    StepFiveForm,
    StepSixForm,
    StepSevenForm,
    StepEightForm,
    StepNineForm,
    StepTenForm,
    StepElevenForm,
    StepTwelveForm,
} from "components";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { PropertyCreationStepsMenu } from "components/properties/PropertyCreationStepsMenu";
import { propertyFormStyles } from "components/styles";
import { defaultProperty } from "defaults/property.default";
import { useNavigate } from "react-router-dom";
import {
    usePropertyCreation,
    type CurrentStep as Step,
} from "services/property-creation-context";
import {
    BASE_AWS_S3_URL,
    IS_DEV,
    createPropertyAPI,
    createPropertyPackageAPI,
} from "services";

import { uploadImagesToAWS } from "services/img-utils";
import { useState } from "react";
import {
    validateStepEight,
    validateStepFive,
    validateStepFour,
    validateStepOne,
    validateStepSeven,
    validateStepSix,
    validateStepThree,
    validateStepTwelve,
    validateStepTwo,
} from "services/validators";
import { Property } from "@interfaces";

const PropertyCreationPage = () => {
    const navigate = useNavigate();
    const {
        currentStep,
        property,
        previewImages,
        setCurrentStep,
        setProperty,
        agreedToTerms,
        setValidationErrors,
        setValidationErrorMessages,
        loadingImages,
        setLoadingImages,
    } = usePropertyCreation();
    const [loading, setLoading] = useState(false);

    const advance = () => {
        if (currentStep === 0) {
            const missingFields = validateStepOne(property);
            const defaultName =
                localStorage.getItem("first_name") +
                " " +
                localStorage.getItem("last_name");
            setProperty((prev) => ({ ...prev, name: defaultName }));
            setValidationErrors(missingFields);
            if (missingFields.length > 0) return;
        }
        if (currentStep === 1) {
            const missingFields = validateStepTwo(property);
            setValidationErrors(missingFields);
            if (missingFields.length > 0) return;
        }
        if (currentStep === 2) {
            const missingFields = validateStepThree(property);
            setValidationErrors(missingFields);
            if (missingFields.length > 0) return;
        }
        if (currentStep === 3) {
            const missingFields = validateStepFour(property);
            setValidationErrors(missingFields);
            if (missingFields.length > 0) return;
        }
        if (currentStep === 4) {
            const missingFields = validateStepFive(property);
            setValidationErrors(missingFields);
            if (missingFields.length > 0) return;
        }
        if (currentStep === 5) {
            const missingFields = validateStepSix(property);
            setValidationErrors(missingFields);
            if (missingFields.length > 0) return;
        }
        if (currentStep === 6) {
            const { errors, errorMessages } = validateStepSeven(property);
            setValidationErrors(errors);
            setValidationErrorMessages(errorMessages);
            if (errors.length > 0) return;
        }
        if (currentStep === 7) {
            const missingFields = validateStepEight(property);
            setValidationErrors(missingFields);
            if (missingFields.length > 0) return;
        }

        if (currentStep < 11) setCurrentStep((currentStep + 1) as Step);
    };

    const goBack = () => {
        if (currentStep > 0) setCurrentStep((currentStep - 1) as Step);
    };

    const resetCreation = () => {
        localStorage.setItem("property_form", "");
        setCurrentStep(0);
        setProperty(defaultProperty);
    };

    const submitPropertyForm = async () => {
        if (!agreedToTerms) {
            alert(
                "Bitte stimmen Sie den Nutzungsbedingungen zu, um fortzufahren.",
            );
            return;
        }
        if (currentStep === 11) {
            const missingFields = validateStepTwelve(property);
            setValidationErrors(missingFields);
            if (missingFields.length > 0) return;
        }
        setLoading(true);
        const loggedInUser = localStorage.getItem("user");
        try {
            const created_property = await createPropertyAPI({
                ...property,
                user: Number(loggedInUser),
            });
            const uploadedImages = await uploadImagesToAWS(
                previewImages,
                created_property,
            );
            if (created_property.id) {
                const res = await createPropertyPackageAPI(
                    property,
                    created_property.id,
                );
                if (res.url) window.open(res.url, "_self");
            }

            !IS_DEV && navigate("/property/success");
        } catch (error) {
            console.error(error);
            alert("oops, da ist etwas schief gegangen");
        } finally {
            setLoading(false);
            !IS_DEV && resetCreation();
        }
    };

    return !loading ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            {/* Steps Menu */}
            {currentStep < 12 && (
                <PropertyCreationStepsMenu
                    property={defaultProperty}
                    currentStep={currentStep}
                />
            )}
            {/* Forms */}
            {currentStep === 0 && <StepOneForm />}
            {currentStep === 1 && <StepTwoForm />}
            {currentStep === 2 && <StepThreeForm />}
            {currentStep === 3 && <StepFourForm />}
            {currentStep === 4 && <StepFiveForm />}
            {currentStep === 5 && <StepSixForm />}
            {currentStep === 6 && <StepSevenForm />}
            {currentStep === 7 && <StepEightForm />}
            {currentStep === 8 && <StepNineForm />}
            {currentStep === 9 && <StepTenForm />}
            {currentStep === 10 && <StepElevenForm />}
            {currentStep === 11 && <StepTwelveForm />}

            {/* Navigation */}
            {loadingImages ? (
                <Box
                    sx={{
                        ...propertyFormStyles.navigationRow,
                        marginBlock: "1rem",
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Box sx={propertyFormStyles.navigationRow}>
                    <Button
                        disabled={currentStep === 0}
                        sx={{ margin: "1rem", minWidth: "100px" }}
                        onClick={goBack}
                    >
                        Zurück
                    </Button>

                    {currentStep < 11 && (
                        <Button
                            sx={{ margin: "1rem", minWidth: "100px" }}
                            onClick={advance}
                        >
                            Weiter
                        </Button>
                    )}
                    {currentStep === 11 && (
                        <Button
                            sx={{ margin: "1rem", maxHeight: "44px" }}
                            onClick={submitPropertyForm}
                        >
                            Jetzt kostenpflichtig buchen
                        </Button>
                    )}
                </Box>
            )}
        </Box>
    ) : (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "60vh",
                }}
            >
                <CircularProgress
                    sx={{ mb: "2rem", animationDuration: "3s" }}
                    size={60}
                />
                <Typography
                    sx={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        textAlign: "center",
                    }}
                >
                    Ihr Inserat wird erstellt. Dieser Vorgang kann einige Minute
                    dauern.<br></br> Bitte schließen Sie das Fenster nicht.
                </Typography>
            </Box>
        </Box>
    );
};

export default PropertyCreationPage;
