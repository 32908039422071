export const LABELS = {
    firstname: {
        en: "First Name",
        de: "Vorname",
    },
    lastname: {
        en: "Last Name",
        de: "Nachname",
    },
    phoneno: {
        en: "Phone Number",
        de: "Telefonnummer",
    },
    email: {
        en: "Email Address",
        de: "E-Mail-Adresse",
    },
    password: {
        en: "Password",
        de: "Passwort",
    },
    passwordConfirm: {
        en: "Confirm Password",
        de: "Passwort bestätigen",
    },
    forgetPassword: {
        en: "Forgot a password ?",
        de: "Passwort vergessen ?",
    },
    login: {
        en: "Login",
        de: "Einloggen",
    },
    register: {
        en: "Registration",
        de: "Registrieren",
    },
    welcome: {
        en: "Welcome to ",
        de: "Willkommen zu ",
    },
    heading: {
        en: "Real Estate made simple",
        de: "Immobilien leicht gemacht",
    },
    haveAnAccount: {
        en: "Have an account ?",
        de: "Ein Konto haben ?",
    },
    registrationSuccess: {
        en: "Registration Successfull",
        de: "Registrierung erfolgreich",
    },
};
