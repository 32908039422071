import React, {
    type ChangeEvent,
    useCallback,
    useState,
    useEffect,
} from "react";
import CloseIcon from "@mui/icons-material/Close";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
    SidebarItem,
    LabeledTextInput,
    TwoColumnForm,
    CheckboxInput,
    ErrorDisplay,
    DisplayDialog,
} from "components";
import {
    Grid,
    IconButton,
    Typography,
    Box,
    Button,
    Snackbar,
    Alert,
} from "@mui/material";
import { updatePasswordAPI, updateProfileAPI, useApp } from "services";
import { FaLock, FaUser } from "react-icons/fa";
import { User } from "@interfaces";
import { SuccessDisplay } from "components/shared/SuccessDisplay";

const ProfilePage = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [open, setOpen] = useState(false);
    const [password, setPassword] = useState<string>("");
    const [passwordConfirm, setPasswordConfirm] = useState<string>("");
    const [responseMessage, setResponseMessage] = useState<string>("");
    const [resetPasswordSuccess, setResetPasswordSuccess] =
        useState<boolean>(false);
    const { user, setUser } = useApp();

    const handleProfileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = e.target;

        if (user) setUser({ ...user, [name]: value });
    };

    const updateProfile = async () => {
        if (!user?.id) return;
        const res = await updateProfileAPI(user);
        if (res?.id) {
            setResponseMessage("Die Änderungen wurden erfolgreich übernommen.");
            setOpen(true);
        }
    };
    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setResponseMessage("");
        if (e.target.name === "password") setPassword(e.target.value);
        if (e.target.name === "passwordConfirm")
            setPasswordConfirm(e.target.value);
    };

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string,
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
        setResponseMessage("");
    };
    const handleAllowEmailNotificationChange = (
        e: ChangeEvent<HTMLInputElement>,
    ) => {
        if (!user) return;
        const isSelected = user["email_notification_disabled" as keyof User];

        setUser({ ...user, [e.target.name]: Boolean(!isSelected) });
    };

    const submitUpdatePassword = async () => {
        if (password !== passwordConfirm) {
            setResponseMessage("Die Passwörter müssen übereinstimmen.");
        }
        if (password === passwordConfirm) {
            const res = await updatePasswordAPI({ password: password });
            setResponseMessage(res.detail);
            setResetPasswordSuccess(true);
            setPassword("");
            setPasswordConfirm("");
        }
    };

    return (
        <Grid
            sx={{
                margin: "auto",
                bgcolor: "#fff",
                padding: "1rem",
                borderRadius: "8px",
            }}
            container
            flexDirection={"column"}
        >
            <Box sx={{ mb: "2rem" }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                    Profil
                </Typography>

                <Typography variant="body1">
                    Verwalten Sie hier ihre persönlichen Daten.
                </Typography>
            </Box>
            <Grid container>
                <Grid item xs={12} md={3}>
                    <Box sx={{ mb: "0rem" }}>
                        <SidebarItem
                            paramName={"profileTab"}
                            paramValue={""}
                            label="Persönliche Informationen"
                            Icon={FaUser}
                        />
                        <SidebarItem
                            paramName={"profileTab"}
                            paramValue={"password"}
                            label="Passwort ändern"
                            Icon={FaLock}
                        />
                    </Box>
                </Grid>

                {!searchParams.has("profileTab") && (
                    <Grid item xs={12} md={9}>
                        <TwoColumnForm
                            leftSide={
                                <>
                                    <LabeledTextInput
                                        label="Vorname"
                                        value={user?.first_name || ""}
                                        name="first_name"
                                        placeholder="Vorname"
                                        handleChange={handleProfileChange}
                                        fullWidth
                                    />
                                    <LabeledTextInput
                                        label="Nachname"
                                        value={user?.last_name || ""}
                                        name="last_name"
                                        placeholder="Nachname"
                                        handleChange={handleProfileChange}
                                        fullWidth
                                    />
                                </>
                            }
                            rightSide={
                                <>
                                    <LabeledTextInput
                                        label="Email"
                                        value={user?.email || ""}
                                        name="email"
                                        placeholder="Email"
                                        handleChange={() => {}}
                                        fullWidth
                                        disabled
                                    />
                                    <LabeledTextInput
                                        label="Telefon"
                                        value={user?.mobile || ""}
                                        name="mobile"
                                        placeholder="Tel."
                                        handleChange={handleProfileChange}
                                        fullWidth
                                    />
                                </>
                            }
                        />
                        <TwoColumnForm
                            leftSide={
                                <>
                                    <CheckboxInput
                                        label="Email Benachrichtigungen erlauben"
                                        checked={
                                            !user?.email_notification_disabled
                                        }
                                        name="email_notification_disabled"
                                        handleChange={
                                            handleAllowEmailNotificationChange
                                        }
                                    />
                                </>
                            }
                            rightSide={<></>}
                        />
                        <Button
                            sx={{ mt: "1rem", ml: "1rem" }}
                            onClick={updateProfile}
                        >
                            {" "}
                            Speichern
                        </Button>
                    </Grid>
                )}

                {searchParams.get("profileTab") === "password" && (
                    <Grid item xs={12} md={9}>
                        <Box>
                            <Box display="flex" alignItems="center">
                                <LabeledTextInput
                                    label="Neues passwort"
                                    value={password}
                                    name="password"
                                    handleChange={handlePasswordChange}
                                    placeholder="neues Passwort eingeben"
                                    type="password"
                                    fullWidth
                                />
                            </Box>
                            <LabeledTextInput
                                label="Passwort bestätigen"
                                value={passwordConfirm}
                                name="passwordConfirm"
                                handleChange={handlePasswordChange}
                                placeholder="...Passwort erneut eingeben"
                                type="password"
                                fullWidth
                            />
                            {responseMessage && !resetPasswordSuccess && (
                                <ErrorDisplay msg={responseMessage} />
                            )}
                            {responseMessage && resetPasswordSuccess && (
                                <SuccessDisplay
                                    msg={responseMessage}
                                    color="#000"
                                />
                            )}
                            <Button onClick={submitUpdatePassword}>
                                Passwort erneuern
                            </Button>
                        </Box>
                    </Grid>
                )}
            </Grid>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                action={
                    <React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            >
                <Alert severity="success" onClose={handleClose}>
                    {responseMessage}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default ProfilePage;
