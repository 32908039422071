import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AddchartIcon from "@mui/icons-material/Addchart";
import { Box } from "@mui/material";
import { CustomLink } from "components";
import { NavbarItemCentered } from "./NavbarItemCentered";
import { TopbarNavRightSide } from "./TopNavRightSide";
import { TopbarNavLeftSide } from "./TopBarLeftSide";
import { IS_DEV, useApp } from "services";
import { useEffect, useState } from "react";

export const TopbarNav = (): JSX.Element => {
    const styles = {
        icon: {
            mr: ".5rem",
        },
    };
    const { isAdmin } = useApp()
    const isAdminStored = localStorage.getItem("isAdmin")
    const isAdminStoredBoolean = isAdminStored ? JSON.parse(isAdminStored) : false

    return (

        <Box
            sx={{
                width: "100%",
                height: "5rem",
                display: "flex",
                justifyContent: "center",
                backgroundColor: IS_DEV ? "rgb(0,0,139)" : "#fff",
                boxShadow: "0 2px 15px 0 rgba(0,0,0,.05)",
                position: "relative",
            }}
        >
            {/* left side of nav */}
            <TopbarNavLeftSide />

            {/* center of nav */}
            <Box
                sx={{
                    flex: 3,
                    display: { xs: "none", md: "flex" },
                    justifyContent: "center",
                }}
            >
                {(isAdmin || isAdminStoredBoolean) && (
                    <CustomLink to="/estate_admin">
                        <NavbarItemCentered>
                            <AddchartIcon sx={styles.icon} /> Estate Sync Admin
                        </NavbarItemCentered>
                    </CustomLink>
                )}
                <CustomLink to="/property/new">
                    <NavbarItemCentered>
                        <NoteAddOutlinedIcon sx={styles.icon} /> Inserieren
                    </NavbarItemCentered>
                </CustomLink>
                <CustomLink to="/property/list">
                    <NavbarItemCentered>
                        <HomeOutlinedIcon sx={styles.icon} /> Meine Immobilien
                    </NavbarItemCentered>
                </CustomLink>
                <CustomLink to="/mailbox">
                    <NavbarItemCentered>
                        <ChatBubbleOutlineOutlinedIcon sx={styles.icon} />
                        <div style={{ transform: "translateY(-1px)" }}>
                            Nachrichten
                        </div>
                    </NavbarItemCentered>
                </CustomLink>
            </Box>
            {/* right side of nav */}
            <TopbarNavRightSide />
        </Box>
    );
};
