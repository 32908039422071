import { type Property } from "interfaces";

export const defaultProperty: Property = {
    ad_optimization: false,
    additional_price: null,
    agent_handover_requested: false,
    archived: false,
    balcony: false,
    barrier_free: false,
    base_rent: null,
    basement: false,
    bathroom_count: null,
    bedroom_count: null,
    by_agreement: false,
    central_heating: false,
    chimney: false,
    city: "",
    country: "",
    created_on: "",
    date_of_creation: "",
    deed: false,
    deposit: null,
    description: "",
    description_of_equipment: "",
    description_of_location: "",
    district_heating: false,
    electric: false,
    elevator: false,
    email: "",
    energy_card_type: "",
    energy_certificate: "",
    energy_consumption: null,
    energy_data: null,
    energy_date_of_creation: "",
    energy_for_hot_water_included: false,
    energy_need: null,
    energy_year_of_construction: null,
    estate_sync_contact_id: null,
    estate_sync_property_id: null,
    export_property: false,
    fitted_kitchen: false,
    flat_type: "",
    floor: null,
    floor_count: null,
    floor_plan: false,
    free_from: "",
    free_from_date: "",
    full_bath: false,
    garage_count: null,
    garage_price: null,
    garden: false,
    gas: false,
    guest_toilet: false,
    heating_cost: null,
    heating_cost_included: false,
    hide_address: false,
    hide_email: true,
    hide_name: false,
    hide_phone: false,
    house_number: "",
    house_type: "",
    id: 1,
    images: [],
    immoscout_listing_id: "",
    immowelt_listing_id: "",
    in_need_of_renovation: false,
    invoice_city: "",
    invoice_coupon_id: "",
    invoice_company: "",
    invoice_country: "",
    invoice_first_name: "",
    invoice_last_name: "",
    invoice_phone: "",
    invoice_postcode: "",
    invoice_street: "",
    is_active: false,
    lat: 52.123456,
    living_space: null,
    lng: 13.987654,
    message_count: null,
    name: "",
    new_building: false,
    oil: false,
    old_building: false,
    open_fireplace: false,
    package_end_date: null,
    patio: false,
    pellets: false,
    phone: "",
    plan: null,
    plan_duration: null,
    plot_area: null,
    post_code: null,
    price: null,
    professional_contract_review: false,
    professional_photo: false,
    property_type: undefined,
    refurbished: false,
    renovated: false,
    rooftop_terrace: false,
    room_count: null,
    sauna: false,
    shower_room: false,
    solar: false,
    state_of_development: undefined,
    storey_heating: false,
    street: "",
    swimming_pool: false,
    tiled_stove: false,
    title: "",
    total_rental_cost: null,
    transaction_type: undefined,
    underfloor_heating: false,
    updated_on: "",
    user: null,
    winter_garden: false,
    year_of_construction: null,
    coupon_code: "",
    invoice_amount: "",
    package_selected: null,
};
