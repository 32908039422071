import axios from "axios";
import React, { useState, ChangeEvent } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import {
    loginAPI,
    sendRecoveryEmailAPI,
    setNewPasswordAPI,
    updatePasswordAPI,
} from "services/authentication";
import { getLocalizedLabels } from "services/translations";
import { ErrorDisplay, LABELS, LabeledTextInput, LogInForm } from "components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SuccessDisplay } from "components/shared/SuccessDisplay";

const SetNewPasswordPage = () => {
    const { token } = useParams();

    const [password, setPassword] = useState<string>("");
    const [passwordConfirm, setPasswordConfirm] = useState<string>("");
    const [responseMessage, setResponseMessage] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [resetPasswordSuccess, setResetPasswordSuccess] =
        useState<boolean>(false);

    const labels = getLocalizedLabels(LABELS);
    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setResponseMessage("");
        if (e.target.name === "password") setPassword(e.target.value);
        if (e.target.name === "passwordConfirm")
            setPasswordConfirm(e.target.value);
    };
    const submitUpdatePassword = async () => {
        setLoading((_) => true);

        if (password !== passwordConfirm) {
            setResponseMessage("Die Passwörter müssen übereinstimmen.");
        }
        if (password === passwordConfirm) {
            if (!token) return;
            const res = await setNewPasswordAPI({
                password: password,
                token: token,
            });
            if (axios.isAxiosError(res)) {
                setResponseMessage(
                    "Das Token wurde bereits genutzt. Bitte fordern Sie ein neues an.",
                );

                setResetPasswordSuccess(false);
                return;
            }

            setResetPasswordSuccess(true);
            setResponseMessage(res.detail);
            setPassword("");
            setPasswordConfirm("");
        }

        setLoading((_) => false);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100svh",
                alignItems: "center",
                pt: "3rem",
            }}
        >
            <Typography variant="h5" sx={{ mb: "1rem" }}>
                Passwort Zurücksetzen
            </Typography>
            <Box>
                <Box display="flex" alignItems="center" sx={{ width: "500px" }}>
                    <LabeledTextInput
                        label="Neues passwort"
                        value={password}
                        name="password"
                        handleChange={handlePasswordChange}
                        placeholder="neues Passwort eingeben"
                        type="password"
                        fullWidth
                    />
                </Box>
                <LabeledTextInput
                    label="Passwort bestätigen"
                    value={passwordConfirm}
                    name="passwordConfirm"
                    handleChange={handlePasswordChange}
                    placeholder="...Passwort erneut eingeben"
                    type="password"
                    fullWidth
                />
                {responseMessage && !resetPasswordSuccess && (
                    <ErrorDisplay msg={responseMessage} />
                )}
                {responseMessage && resetPasswordSuccess && (
                    <SuccessDisplay msg={responseMessage} color="#000" />
                )}
                <Button
                    onClick={submitUpdatePassword}
                    sx={{ maxHeight: "44px", width: "500px" }}
                >
                    {loading ? (
                        <CircularProgress sx={{ mr: "1rem" }} />
                    ) : (
                        "Passwort erneuern"
                    )}
                </Button>
            </Box>
            <Box
                sx={{
                    mt: "1rem",
                    justifyContent: "space-between",
                    display: "flex",
                    width: "500px",
                }}
            >
                <Link to="/forgot-password">Zur Passwortwiederherstellung</Link>

                <Link to="/login">Zum Login</Link>
            </Box>
        </Box>
    );
};

export default SetNewPasswordPage;
