import axios from "axios";
import { BASE_API_URL } from "./constants";
import {
    type LoginResult,
    type LoginResponse,
    type RegistrationData,
    type LoginData,
    type RegistrationResult,
    type RegistrationResponse,
    User,
    PassswordResetData,
} from "interfaces";
import { getWithHeader, patchWithHeader, postWithHeader } from "./base.api";
// import { errorHandler } from './apiUtils';

export const isLoggedIn = () => localStorage.hasOwnProperty("token");

export const getUserFirstName = () =>
    localStorage.getItem("first_name") || "User";

export const getUserFullName = () => {
    const firstName = localStorage.getItem("first_name") || "User";
    const lastName = localStorage.getItem("last_name") || "";
    return firstName + " " + lastName;
};
export const getUserEmail = () => {
    const email = localStorage.getItem("email") || "";
    return email;
};

export const loginAPI = async ({
    username,
    password,
}: LoginData): Promise<LoginResult> => {
    const url = `${BASE_API_URL}/accounts/login/`;
    const { data } = await axios.post<LoginResponse>(url, {
        username,
        password,
    });
    if (data.account_verified) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("first_name", data.first_name);
        localStorage.setItem("last_name", data.last_name);
        localStorage.setItem("email", data.email);
        localStorage.setItem("mobile", JSON.stringify(data.mobile));
        localStorage.setItem("user", JSON.stringify(data.id));
        if (data.isAdmin) localStorage.setItem("isAdmin", JSON.stringify(data.isAdmin));
    }
    return { ...data, success: true };
};

export const updatePasswordAPI = (
    payload: PassswordResetData,
): Promise<any> => {
    let url = `${BASE_API_URL}/accounts/update-password/`;
    const res = postWithHeader(url, payload);
    return res;
};

export const setNewPasswordAPI = (
    payload: PassswordResetData & { token: string },
): Promise<any> => {
    let url = `${BASE_API_URL}/accounts/set-new-password/${payload.token}/`;
    const res = postWithHeader(url, payload);
    return res;
};

export const logoutAPI = async () => {
    const url = `${BASE_API_URL}/accounts/logout/`;
    localStorage.removeItem("token");
    localStorage.removeItem("token");
    localStorage.removeItem("first_name");
    localStorage.removeItem("last_name");
    localStorage.removeItem("email");
    localStorage.removeItem("mobile");
    localStorage.removeItem("user");
    localStorage.removeItem("isAdmin");
    try {
        await axios.post(
            url,
            {},
            {
                headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                },
            },
        );
    } catch (err) {
        console.error(err);
    }
};

export const registartionAPI = async ({
    firstName,
    lastName,
    email,
    password,
}: RegistrationData): Promise<any> => {
    const url = `${BASE_API_URL}/accounts/register/`;
    const { data } = await axios.post(url, {
        first_name: firstName,
        last_name: lastName,
        username: email,
        email,
        password,
    });
    return data;
};

export const sendRecoveryEmailAPI = async (email: string) => {
    const url = `${BASE_API_URL}/accounts/reset-password/`;
    const data = await postWithHeader(url, { email: email });
    return data;
};

export const getProfileAPI = async () => {
    const url = `${BASE_API_URL}/accounts/profile/`;
    const user = await getWithHeader<User>(url);
    return user;
};

export const updateProfileAPI = async (payload: User): Promise<User | void> => {
    let url = `${BASE_API_URL}/accounts/profile/`;
    const res = await patchWithHeader(url, payload);
    return res;
};
