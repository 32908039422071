import { Box, Typography } from "@mui/material";
import { Country } from "enums";
import { labelStyles } from "components/shared/form/labelStyles";
import { LabelInfoIcon } from "components/shared/form/ToolTipIcon";
import { type PropertyInput } from "interfaces";
import { getLocalizedLabels } from "services";
import { LABELS } from "components/properties/constants";
import {
    alphabetOnly,
    alphabetDashAndPeriodOnly,
    alphaNumericOnly,
} from "regexp";

const labels = getLocalizedLabels(LABELS);
export const leftInputs: PropertyInput[] = [
    {
        name: "invoice_first_name",
        label: "Vorname*",
        placeholder: "",
        type: "text",
        regex: alphabetOnly,
    },
    {
        name: "invoice_street",
        label: `${labels.street}*`,
        placeholder: "Magdeburger Straße",
        type: "text",
        regex: alphaNumericOnly,
    },
    {
        name: "invoice_city",
        label: labels.city + "*",
        placeholder: "Tübingen",
        type: "text",
        regex: alphabetOnly,
    },
    {
        name: "invoice_company",
        label: "Unternehmen",
        placeholder: "Walter GmbH",
        type: "text",
        regex: alphabetDashAndPeriodOnly,
    },
];

export const rightInputs: PropertyInput[] = [
    {
        name: "invoice_last_name",
        label: "Nachname*",
        placeholder: "",
        type: "text",
        regex: alphabetOnly,
    },
    {
        name: "invoice_postcode",
        label: labels.post_code + "*",
        placeholder: "72074",
        type: "number",
        maxLength: 5,
    },
    {
        name: "invoice_country",
        label: labels.country + "*",
        enumObject: Country,
        type: "select",
    },
    {
        name: "invoice_phone",
        label: (
            <Box sx={{ ...labelStyles.wrapper, mt: ".5rem" }}>
                <Typography variant="caption">Telefonnummer/Mobil</Typography>
                <LabelInfoIcon text="Für Rückfragen" />
            </Box>
        ),
        placeholder: "015783345879",
        type: "number",
        maxLength: 13,
    },
];
