import React from "react";
import { useTheme, Box } from "@mui/material";

import {
    FaCameraRetro,
    FaFileAlt,
    FaRulerCombined,
    FaFileContract,
    FaFileImport,
    FaShareSquare,
} from "react-icons/fa";
import { CheckboxInput } from "components/shared/form/CheckboxInput";
import { type ChangeInputEvent } from "@types";

export const ExtraServicesForm = ({
    handleCheckboxChange,
    extraServices,
}: {
    handleCheckboxChange: (e: ChangeInputEvent, saleId: number) => void;
    extraServices: number[];
}) => {
    const extraSalesArray = [
        {
            field: "professional_photo",
            label: "Professionelle Fotos",
            icon: <FaCameraRetro />,
            id: 1,
        },
        { field: "deed", label: "Grundbuchauszug", icon: <FaFileAlt />, id: 2 },
        {
            field: "floor_plan",
            label: "Grundrisserstellung",
            icon: <FaRulerCombined />,
            id: 3,
        },
        {
            field: "professional_contract_review",
            label: "Professionelle Vertragsprüfung",
            icon: <FaFileContract />,
            id: 4,
        },
        {
            field: "export_property",
            label: "Exposé für Ihre Immobilie",
            icon: <FaFileImport />,
            id: 5,
        },
        {
            field: "ad_optimization",
            label: "Inseratsoptimierung",
            icon: <FaShareSquare />,
            id: 6,
        },
    ];

    const { customColors } = useTheme();

    return (
        <Box>
            {extraSalesArray.map((sale) => {
                return (
                    <Box
                        key={sale.field}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            height: "60px",
                            width: { xs: "100%", md: "45%" },
                        }}
                    >
                        <i
                            style={{
                                borderRadius: "30px",
                                boxShadow: "0px 0px 2px #888",
                                padding: "0.3em 0.57em",
                                marginRight: ".5rem",
                                transform: "translateY(3px)",
                            }}
                        >
                            {sale.icon}
                        </i>
                        <CheckboxInput
                            labelColor={customColors.colorPrimary}
                            name={sale.field}
                            label={sale.label}
                            checked={extraServices.includes(sale.id)}
                            handleChange={(e) => {
                                handleCheckboxChange(e, sale.id);
                            }}
                        />
                    </Box>
                );
            })}
        </Box>
    );
};
