import { Box, Typography, useTheme } from "@mui/material";
import { type Property } from "@interfaces";
import { PropertyMenuStep } from "./PropertyMenuStep";
import {
    type CurrentStep,
    usePropertyCreation,
} from "services/property-creation-context";

interface PropertyCreationStepsMenuProps {
    property: Property;
    currentStep: number;
}
import {
    validateStepEight,
    validateStepFive,
    validateStepFour,
    validateStepOne,
    validateStepSeven,
    validateStepSix,
    validateStepThree,
    validateStepTwelve,
    validateStepTwo,
} from "services/validators";

export const PropertyCreationStepsMenu = ({
    property,
    currentStep,
}: PropertyCreationStepsMenuProps) => {
    const { customShadows } = useTheme();
    const { STEPS, setCurrentStep } = usePropertyCreation();
    const handleClick = (idx: CurrentStep) => {
        // const allowAdvanceTo = [0];
        // if (validateStepOne(property).length === 0) {
        //     allowAdvanceTo.push(1);
        // }
        // if (validateStepTwo(property).length === 0) allowAdvanceTo.push(2);
        // if (validateStepThree(property).length === 0) allowAdvanceTo.push(3);
        // if (validateStepFour(property).length === 0) allowAdvanceTo.push(4);
        // if (validateStepFive(property).length === 0) allowAdvanceTo.push(5);
        // if (validateStepSix(property).length === 0) allowAdvanceTo.push(6);
        // if (validateStepSeven(property).length === 0) allowAdvanceTo.push(7);
        // if (validateStepEight(property).length === 0) allowAdvanceTo.push(8);
        // let allStepsValid = true;
        // for (let i = 1; i <= idx; i++) {
        //     if (!allowAdvanceTo.includes(i)) {
        //         allStepsValid = false;
        //         break;
        //     }
        // }
        // if (allStepsValid) {
        //     setCurrentStep(idx);
        // } else {
        //     console.error(
        //         `All previous steps must be completed before advancing to step ${idx}`,
        //     );
        // }
        if (idx <= currentStep) setCurrentStep(idx);
    };
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    display: { xs: "none", md: "flex" },
                    backgroundColor: "#fff",
                    flexWrap: "wrap",
                    boxShadow: customShadows.boxShadowLight,
                    // to show to box shadow
                    mb: "2px",
                }}
            >
                {STEPS.map((step) => {
                    return (
                        <PropertyMenuStep
                            key={step.idx}
                            idx={step.idx}
                            handleClick={handleClick}
                            // label={`${step.idx + 1}.) ${step.label}`}
                            label={`${step.label}`}
                            selected={step.idx <= currentStep}
                        />
                    );
                })}
            </Box>
            <Box
                sx={{
                    backgroundColor: "#fff",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    paddingBlock: "2rem",
                }}
            >
                <Typography variant="h5">{STEPS[currentStep].label}</Typography>
                <Typography
                    variant="h5"
                    sx={{ ml: ".5rem", display: { xs: "block", md: "none" } }}
                >
                    (Schritt {currentStep + 1}/12 )
                </Typography>
            </Box>
        </Box>
    );
};
