import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { CreationSuccess } from "components/properties/CreationSuccess";

const PropertySuccesPage = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CreationSuccess />
        </Box>
    );
};

export default PropertySuccesPage;
