import { TopbarNav } from "./TopbarNav";
import { Box } from "@mui/material";

interface ProtectedRouteNavigationWrapperProps {
    children: React.ReactNode;
}
export const ProtectedRouteNavigationWrapper = ({
    children,
}: ProtectedRouteNavigationWrapperProps) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <TopbarNav />
            <Box
                sx={{
                    paddingTop: "2rem",
                    paddingInline: { xs: "0rem", md: "3rem" },
                }}
            >
                {children}
            </Box>
        </Box>
    );
};
