import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import { propertyFormStyles } from "components/styles";
import { usePropertyCreation } from "services/property-creation-context";
import { CheckboxInput, LabelInfoIcon, TwoColumnForm } from "components";
import { getLocalizedLabels } from "services";
import { LABELS } from "../../constants";
import { type Property } from "@interfaces";
import { usePropertyEdit } from "services/property-edit-context";
import { useParams } from "react-router-dom";

export const StepSixForm = () => {
    const { id } = useParams();
    const labels = getLocalizedLabels(LABELS);
    const { property, handleCheckboxChange, handleRadioButtonChange } = id
        ? usePropertyEdit()
        : usePropertyCreation();

    const ammeneties = [
        "balcony",
        "patio",
        "winter_garden",
        "garden",
        "rooftop_terrace",
        "elevator",
        "full_bath",
        "shower_room",
        "sauna",
        "swimming_pool",
        "fitted_kitchen",
        "chimney",
        "barrier_free",
    ];

    const oldOrNoewArray = ["new_building", "old_building"];

    const conditionArray = [
        "refurbished",
        "renovated",
        "in_need_of_renovation",
    ];

    return (
        <Box sx={propertyFormStyles.centeredContainer}>
            <TwoColumnForm
                leftSide={
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography>Ausstattung</Typography>
                        {ammeneties.map((ammenity) => {
                            return (
                                <CheckboxInput
                                    key={ammenity}
                                    name={ammenity}
                                    label={
                                        ammenity !== "barrier_free" ? (
                                            labels[ammenity]
                                        ) : (
                                            <Typography variant="caption">
                                                {labels[ammenity]}
                                                <LabelInfoIcon text="Barrierefreies Wohnen bedeutet, dass Menschen mit Beeinträchtigungen (z.B. ältere Menschen mit Geh-, Seh- oder Gleichgewichtsstörungen und Personen mit Kleinkindern oder auf Rollatoren Angewiesene etc.) grundsätzlich ohne fremde Hilfe sämtliche Wohnräume in vollem Umfang nutzen können. Hierbei soll der Zugang zu allen Bereichen der Wohnung und deren Ausstattung möglich sein, um den Alltag möglichst problemlos bestreiten zu können. Detaillierte Informationen finden Sie im §4 des deutschen Behindertengleichstellungsgesetzes (BGG)." />
                                            </Typography>
                                        )
                                    }
                                    checked={
                                        property[
                                            ammenity as keyof Property
                                        ] as boolean
                                    }
                                    handleChange={handleCheckboxChange}
                                />
                            );
                        })}
                    </Box>
                }
                rightSide={
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <FormControl>
                            <FormLabel id="old-or-new-building">
                                Alt / Neubau
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="old-or-new-building"
                                name="old-or-new-building"
                                onChange={(e) => {
                                    handleRadioButtonChange(e, [
                                        "old_building",
                                        "new_building",
                                    ]);
                                }}
                            >
                                {oldOrNoewArray.map((option) => {
                                    return (
                                        <FormControlLabel
                                            key={option}
                                            value={option}
                                            control={
                                                <Radio
                                                    checked={
                                                        property[
                                                            option as keyof Property
                                                        ] as boolean
                                                    }
                                                />
                                            }
                                            label={labels[option]}
                                        />
                                    );
                                })}
                            </RadioGroup>
                            <FormLabel id="condition">
                                Renoviertungsbedürftigkeit
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="condition"
                                name="condition"
                                onChange={(e) => {
                                    handleRadioButtonChange(e, [
                                        "renovated",
                                        "in_need_of_renovation",
                                        "refurbished",
                                    ]);
                                }}
                            >
                                {conditionArray.map((option) => {
                                    return (
                                        <FormControlLabel
                                            key={option}
                                            value={option}
                                            control={
                                                <Radio
                                                    checked={
                                                        property[
                                                            option as keyof Property
                                                        ] as boolean
                                                    }
                                                />
                                            }
                                            label={labels[option]}
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                }
            />
        </Box>
    );
};
