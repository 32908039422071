import React from "react";
import "./scss/app.scss";
import { AppContextProvider } from "./services/app-context";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./Router";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./theme";

const App: React.FC = () => {
    return (
        <BrowserRouter basename="/">
            <ThemeProvider theme={theme}>
                <AppContextProvider>
                    <AppRouter />
                </AppContextProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
};

export default App;
