import { type PropertyInput } from "@interfaces";
import { getLocalizedLabels } from "services";
import { LABELS } from "components/properties/constants";
import { Country } from "enums";
import { ToolTipIcon } from "components/shared/form/ToolTipIcon";
import { Typography, Box } from "@mui/material";

import { labelStyles } from "components/shared/form/labelStyles";

const labels = getLocalizedLabels(LABELS);

// flat
export const leftInputs: PropertyInput[] = [
    {
        name: "street",
        label: `${labels.street}*`,
        placeholder: "Magdeburger Straße",
        type: "text",
    },
    {
        name: "post_code",
        label: labels.post_code + "*",
        placeholder: "72074",
        type: "number",
    },

    {
        name: "country",
        label: labels.country + "*",
        enumObject: Country,
        type: "select",
    },
    {
        name: "hide_address",
        label: (
            <Box sx={labelStyles.wrapper}>
                <Typography>{labels.hide_address}</Typography>
                <ToolTipIcon text="Wenn Sie die genaue Adresse anzeigen, wird Ihre Immobilie eher gefunden, weil die genaue Lage ein entscheidendes Kriterium für Interessenten ist" />
            </Box>
        ),
        type: "checkbox",
    },
];

export const rightInputs: PropertyInput[] = [
    {
        name: "house_number",
        label: `${labels.house_number}*`,
        placeholder: "12a",
        type: "text",
    },
    {
        name: "city",
        label: labels.city + "*",
        placeholder: "Tübingen",
        type: "text",
    },
];
