import { Box } from "@mui/material";
import LightLogo from "assets/images/logo_light.png";
import DarkLogo from "assets/images/logo_dark.png";
import { IS_DEV } from "services";
export const AppLogo = ({ darkMode = false }: { darkMode?: boolean }) => {
    return (
        <Box
            sx={{
                // width: "150px",
                margin: "auto 0",
                backgroundRepeat: "no-repeat",
                backgroundPositionY: "center",
                backgroundPositionX: "left",
                backgroundSize: "contain",
                ml: "1rem",
                fontSize: { xs: "18px", sm: "20px", lg: "24px" },
            }}
        >
            Selbst-Vermarkten.de {IS_DEV && "(TEST!)"}
        </Box>
    );
};
