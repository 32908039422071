import { LABELS } from "./../components/properties/constants";
import { getLocalizedLabels } from "services";
const labels = getLocalizedLabels(LABELS);
export enum PropertyType {
    Immobilienart = "",
    Haus = "house",
    Wohnung = "flat",
    Grundstück = "plot",
}

export enum TransactionType {
    "Kaufen/Mieten" = "",
    Kaufen = "sell",
    Mieten = "rent",
}

export enum AvailibilityOptions {
    "Sofort" = "Sofort",
    "Nach Vereinbarung" = "Nach Vereinbarung",
    "Datum" = "fix_date",
}

export enum HouseTypeOptions {
    "Farm" = "Farm",
    "Doppelhaushälfte" = "Doppelhaushälfte",
    "Ferienhaus" = "Ferienhaus",
    "Einfamilienhaus" = "Einfamilienhaus",
    "Mehrfamilienhaus" = "Mehrfamilienhaus",
    "Reihenendhaus" = "Reihenendhaus",
    "Reihenhaus" = "Reihenhaus",
    "Villa" = "Villa",
    "Zweifamilienhaus" = "Zweifamilienhaus",
    "Bungalow" = "Bungalow",
    "Burg / Schloss" = "Burg / Schloss",
}
export enum FlatTypeOptions {
    "Apartment" = "Apartment",
    "Loft" = "Loft",
    "Maisonette" = "Maisonette",
    "Penthouse" = "Penthouse",
}

export enum Country {
    Deutschland = "Deutschland",
    Schweiz = "Schweiz",
    Österreich = "Österreich",
}

export enum EnergyPassportOptions {
    "Keine Angabe" = "Keine Angabe",
    "Ja, Liegt Vor" = "Ja, Liegt Vor",
    "Nein, Immobilie unterliegt nicht den Anforderungen der EnEV" = "Nein, Immobilie unterliegt nicht den Anforderungen der EnEV",
}
export enum EnergyDateOfCreationOptions {
    "Bis 30. April 2014" = "Bis 30. April 2014",
    "Nach 01. Mai 2014" = "Nach 01. Mai 2014",
}
export enum EnergyCardTypeOptions {
    "Verbraucherausweis" = "Verbraucherausweis",
    "Bedarfsausweis" = "Bedarfsausweis",
}

export enum StateOfDevelopment {
    "Unerschlossen" = "Unerschlossen",
    "Teilerschlossen" = "Teilerschlossen",
    "Vollerschlossen" = "Vollerschlossen",
}
