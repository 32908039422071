import { FaTelegramPlane } from "react-icons/fa";
import { Box, Typography, useTheme } from "@mui/material";
import { type Package } from "@interfaces";
import { usePropertyCreation } from "services/property-creation-context";
import { PlanSelectionButtons, SelectionButton } from "./PlanSelectionButtons";
import { TransactionType } from "enums";
import { formatPrice } from "services";
export const PackageCard = ({
    packageData,
    selectedPlan,
    selectedPackage,
    handlePlanSelection,
    handlePackageSelection,
}: {
    packageData: Package;
    selectedPlan?: number | null;
    selectedPackage?: number | null;
    handlePlanSelection?: (packageId: number) => void;
    handlePackageSelection: (packageId: number) => void;
}) => {
    const { property, setProperty } = usePropertyCreation();
    const { customColors } = useTheme();
    const { colorPrimary } = customColors;

    const updatePlanDuration = (duration: number) => {
        setProperty((prev) => ({
            ...prev,
            plan: packageData.id,
            plan_duration: duration,
        }));
        handlePackageSelection(packageData.id);
    };

    const isSelected = packageData.id === selectedPackage;
    const parsedTransactionType =
        property.transaction_type === "sell" ? "sale" : "rent";
    const priceKey = isSelected
        ? `${parsedTransactionType}_cost_${property.plan_duration}_days`
        : `${parsedTransactionType}_cost_30_days`;

    return (
        <Box
            sx={{
                borderRadius: ".5rem",
                border: !isSelected
                    ? `1px solid ${colorPrimary}`
                    : "1px solid green",
                borderTop: !isSelected
                    ? `6px solid ${colorPrimary}`
                    : "6px solid green",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "2rem",
                margin: "2rem 1rem",
                width: { xs: "100%", xl: "20%" },
                flex: 1,
            }}
        >
            <FaTelegramPlane
                style={{
                    fontSize: "3rem",
                    color: isSelected ? "green" : colorPrimary,
                }}
            />
            <h3>{packageData.name}</h3>
            <hr />

            <PlanSelectionButtons
                planData={packageData}
                updatePlanDuration={updatePlanDuration}
                packageIsSelected={isSelected}
                planDuration={property.plan_duration}
                isRent={property.transaction_type === TransactionType.Mieten}
            />

            <Typography variant="h6" sx={{ mt: "1rem" }}>
                {formatPrice(packageData[priceKey as keyof Package] as number)}
            </Typography>
            <p>{packageData.description}</p>
            <SelectionButton
                label={!isSelected ? "Auswählen" : "ausgewählt"}
                handleClick={() => {
                    handlePackageSelection(packageData.id);
                    updatePlanDuration(30);
                }}
                isSelected={isSelected}
            />
        </Box>
    );
};
