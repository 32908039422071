import { useNavigate, useSearchParams } from "react-router-dom";
import { Grid, Box, Typography, Checkbox } from "@mui/material";
import {
    Message,
    MessageDetail,
    MessageStats,
} from "interfaces/message.interface";
import { Tooltip } from "@mui/material";
import {
    FaArrowLeft,
    FaStar,
    FaBan,
    FaInbox,
    FaTrash,
    FaBug,
    FaEnvelope,
    FaEnvelopeOpen,
} from "react-icons/fa";
import "./messages.css";
import { updateCategoryAPI } from "services";
import { Dot } from "./Dot";

type InboxProps = {
    message: MessageDetail;
    updateMessageCategory: (category: string) => void;
    toggleMessageRead: () => void;
};

export const MessageContent = ({
    message,
    updateMessageCategory,
    toggleMessageRead,
}: InboxProps) => {
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                padding: "1rem",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: "1rem",
                }}
            >
                <Box
                    sx={{
                        border: "2px solid #cecece",
                        width: "150px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#cecece",
                        cursor: "pointer",
                    }}
                    onClick={() => navigate("/mailbox")}
                >
                    <FaArrowLeft color="#cecece" />
                    <Typography
                        sx={{
                            color: "#cecece",
                            fontSize: "14px",
                            ml: ".25rem",
                        }}
                    >
                        Zurück
                    </Typography>
                </Box>
                <Tooltip title="als Interessant markieren">
                    <Box>
                        <FaStar
                            onClick={() => updateMessageCategory("interesting")}
                            className={
                                message.category === "interesting"
                                    ? "category-icon-active"
                                    : "category-icon"
                            }
                        />
                    </Box>
                </Tooltip>
                <Tooltip title="als uninteressant markieren">
                    <Box>
                        <FaBan
                            onClick={() =>
                                updateMessageCategory("not_interesting")
                            }
                            id="notInterestingIcon"
                            className={
                                message.category === "not_interesting"
                                    ? "category-icon-active"
                                    : "category-icon"
                            }
                        />
                    </Box>
                </Tooltip>

                <Tooltip title="löschen">
                    <Box>
                        <FaTrash
                            onClick={() => updateMessageCategory("deleted")}
                            id="deletedIcon"
                            className={
                                message.category === "deleted"
                                    ? "category-icon-active"
                                    : "category-icon"
                            }
                        />
                    </Box>
                </Tooltip>

                <Tooltip title="als Spam markieren">
                    <Box>
                        <FaBug
                            onClick={() => updateMessageCategory("spam")}
                            id="spamIcon"
                            className={
                                message.category === "spam"
                                    ? "category-icon-active"
                                    : "category-icon"
                            }
                        />
                    </Box>
                </Tooltip>
                <Tooltip
                    title={
                        message.read
                            ? "als ungelesen markieren"
                            : "als gelesen markieren"
                    }
                >
                    <Box onClick={() => toggleMessageRead()}>
                        {message.read ? (
                            <FaEnvelope id="readIcon" />
                        ) : (
                            <FaEnvelopeOpen />
                        )}
                    </Box>
                </Tooltip>
            </Box>
            <Box sx={{ width: "100%", borderBottom: "1px solid #cecece" }}>
                {message.label === "homesy" && <Dot bgColor="#4celb6" />}
                <Typography variant="body2">
                    Absender: {message.first_name}
                </Typography>
                <Typography variant="body2"> Mail: {message.email}</Typography>
                <Typography variant="body2">Tel.:{message.phone}</Typography>
                {message.label === "homesy" && <Dot bgColor={"#"} />}
            </Box>
            <Grid container>
                <Grid item xs={11} marginTop="8px">
                    <Typography
                        sx={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
                    >
                        {message.message}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

const Section = ({
    children,
    size,
}: {
    children: JSX.Element;
    size?: "large" | "medium" | "small";
}) => {
    if (size === "large")
        return (
            <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
                {children}
            </Grid>
        );
    if (size === "medium")
        return (
            <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
                {children}
            </Grid>
        );
    return (
        <Grid item xs={1.5} sx={{ display: "flex", alignItems: "center" }}>
            {children}
        </Grid>
    );
};
