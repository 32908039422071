import { useState } from 'react'

type SetValue<T> = T | ((prevValue: T) => T)

export const useLocalStorageState = <T>(
  key: string,
  initialValue: T
): [T, (value: SetValue<T>) => void] => {
  // Get initial value from localStorage or use the provided initialValue
  const storedValue = localStorage.getItem(key)
  const initial = storedValue ? JSON.parse(storedValue) : initialValue

  // Create state using useState
  const [state, setState] = useState<T>(initial)

  // Update localStorage whenever state changes
  const updateState = (value: SetValue<T>) => {
    const newValue =
            typeof value === 'function'
              ? (value as (prevValue: T) => T)(state)
              : value
    localStorage.setItem(key, JSON.stringify(newValue))
    setState(newValue)
  }

  return [state, updateState]
}
