import { type PropertyInput } from "@interfaces";
import { getLocalizedLabels } from "services";
import { LABELS } from "components/properties/constants";
import { AvailibilityOptions, FlatTypeOptions } from "enums";

const labels = getLocalizedLabels(LABELS);

// flat
export const leftFlatInputs: PropertyInput[] = [
    {
        name: "living_space",
        label: `${labels.living_space} (m^2)*`,
        placeholder: "120",
        type: "number",
    },
    {
        name: "room_count",
        label: labels.room_count + "*",
        placeholder: "3",
        type: "number",
    },
    {
        name: "year_of_construction",
        label: labels.year_of_construction + "*",
        placeholder: "2000",
        type: "number",
    },
    {
        name: "free_from",
        label: labels.free_from + "*",
        enumObject: AvailibilityOptions,
        type: "select",
    },
];

export const rightFlatInputs: PropertyInput[] = [
    {
        name: "floor",
        label: `${labels.floor}`,
        placeholder: "2",
        type: "number",
    },
    {
        name: "bathroom_count",
        label: labels.bathroom_count + "*",
        placeholder: "1",
        type: "number",
    },
    {
        name: "free_from_date",
        label: labels.free_from_date,
        placeholder: "tt.mm.jjjj",
        type: "date",
    },
    {
        name: "flat_type",
        label: labels.flat_type,
        enumObject: FlatTypeOptions,
        type: "select",
    },
];
