import { useState } from "react";
import {
    Box,
    CircularProgress,
    IconButton,
    Typography,
    imageListClasses,
} from "@mui/material";
import { PropertyImageList } from "components/shared/form/ImageDisplay";
import { propertyFormStyles } from "components/styles";
import { useCallback } from "react";
import { usePropertyCreation } from "services/property-creation-context";

import { MultiImageUploader } from "components";
import { usePropertyEdit } from "services/property-edit-context";
import { useParams } from "react-router-dom";
import { CloseOutlined } from "@mui/icons-material";
import { deletePropertyImageAPI } from "services";

const ImageDisplay = ({
    imageUrl,
    id,
    handleRemoveImage,
    isPreview = false,
}: {
    handleRemoveImage: (id: string) => Promise<void>;
    id: string;
    imageUrl: string;
    isPreview: boolean;
}) => {
    const [loading, setLoading] = useState<boolean>(false);

    const deleteImage = async () => {
        setLoading((_) => true);
        await handleRemoveImage(id);
        setLoading((_) => false);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    width: "150px",
                    height: "150px",
                    border: "2px solid #cecece",
                    padding: "1rem",
                    backgroundImage: `url(${imageUrl})`,
                    backgroundSize: "cover",
                    margin: "1rem",
                    borderRadius: '.4rem',
                    cursor: 'pointer',
                    transition: '600ms',
                    '&:hover': {
                        transform: 'scale(2.5)',


                    }
                }}
            ></Box>
            {!isPreview && (
                <IconButton
                    sx={{
                        border: "1px solid #000",
                        borderRadius: "999px",
                        padding: "2px",
                        color: "#000",
                        transition: "300ms",
                        "&:hover": {
                            color: "red",
                            borderColor: "red",
                        },
                    }}
                    onClick={deleteImage}
                >
                    {loading ? <CircularProgress /> : <CloseOutlined />}
                </IconButton>
            )}
        </Box>
    );
};

export const ImageEditForm = () => {
    const {
        property,
        previewImages,
        setProperty,
        loadingImages,
        setLoadingImages,
    } = usePropertyEdit();
    const handleRemoveImage = async (id: string) => {
        try {
            setLoadingImages(true);
            await deletePropertyImageAPI(id);
            const remainingImages = property.images.filter(
                (img) => img.id !== id,
            );
            setProperty((prev) => ({ ...prev, images: remainingImages }));
        } catch (error) {
            console.error("could not delete image", error);
        } finally {
            setLoadingImages(false);
        }
    };
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "60vh",
            }}
        >
            <Typography variant="body2" sx={{ marginBlock: "1rem" }}>
                Hier können Sie bilder löschen oder hinzufügen.
            </Typography>
            <MultiImageUploader />

            <Typography variant="body2">Ihre Auswahl</Typography>
            <Box sx={{ maxHeight: "200px", display: "flex" }}>
                {property.images.map((img) => (
                    <ImageDisplay
                        key={img.url}
                        imageUrl={img.url}
                        id={img.id}
                        handleRemoveImage={handleRemoveImage}
                        isPreview={false}
                    />
                ))}
                {previewImages.map((img) => (
                    <ImageDisplay
                        key={img.previewUrl}
                        imageUrl={img.previewUrl || ""}
                        id={img.id.toString()}
                        handleRemoveImage={handleRemoveImage}
                        isPreview={true}
                    />
                ))}
            </Box>
        </Box>
    );
};
