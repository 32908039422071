import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { MainWrapper, ProtectedRouteNavigationWrapper } from "components";
import {
    RegistrationPage,
    LoginPage,
    PropertyListPage,
    BasicPropertySearchPage,
    PropertySearchPage,
    PropertyCreationPage,
    PropertyDetailPage,
} from "pages";
import { useApp } from "services/app-context";

import { PropertyCreationProvider } from "services/property-creation-context";
import PropertySuccesPage from "pages/PropertySuccessPage";
import MailboxPage from "pages/MailboxPage";
import PropertyEditPage from "pages/PropertyEditPage";
import { PropertyEditProvider } from "services/property-edit-context";
import ProfilePage from "pages/ProfilePage";
import PasswordRecoveryPage from "pages/PasswordRecoveryPage";
import SetNewPasswordPage from "pages/SetNewPasswordPage";
import { EstateSyncAdminPage } from "pages/EstateSyncAdminPage";
import { RedirectToAdmin } from "RedirectToAdmin";

const ProtectedRoute = ({ element }: { element: JSX.Element }) => {
    const hasToken = localStorage.hasOwnProperty("token");
    if (!hasToken) return <Navigate to="/login" />;

    return (
        <PropertyCreationProvider>
            <PropertyEditProvider>
                <ProtectedRouteNavigationWrapper>
                    {element}
                </ProtectedRouteNavigationWrapper>
            </PropertyEditProvider>
        </PropertyCreationProvider>
    );
};

const AppRouter = () => {
    const { isLoggedIn } = useApp();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const redirect = params.get("redirect");
    return (
        <MainWrapper>
            <Routes>
                <Route
                    path="/property/success"
                    element={
                        <ProtectedRoute element={<PropertySuccesPage />} />
                    }
                />
                <Route
                    path="/property/new"
                    element={
                        <ProtectedRoute element={<PropertyCreationPage />} />
                    }
                />
                <Route
                    path="/property/list"
                    element={<ProtectedRoute element={<PropertyListPage />} />}
                />
                <Route
                    path="/property/detail/:id"
                    element={
                        <ProtectedRoute element={<PropertyDetailPage />} />
                    }
                />
                <Route
                    path="/property/edit/:id"
                    element={<ProtectedRoute element={<PropertyEditPage />} />}
                />
                <Route
                    path="/property/detail-public/:id"
                    element={<PropertyDetailPage />}
                />
                <Route
                    path="/set-new-password/:token"
                    element={<SetNewPasswordPage />}
                />
                <Route
                    path="/property/search"
                    element={<BasicPropertySearchPage />}
                />
                <Route
                    path="/property/results"
                    element={<PropertySearchPage />}
                />

                <Route
                    path="/forgot-password"
                    element={<PasswordRecoveryPage />}
                />

                <Route
                    path="/profile"
                    element={<ProtectedRoute element={<ProfilePage />} />}
                />
                <Route
                    path="/mailbox"
                    element={<ProtectedRoute element={<MailboxPage />} />}
                />
                <Route path="/admin/*" element={<RedirectToAdmin />} />
                <Route
                    path="/estate_admin"
                    element={
                        <ProtectedRoute element={<EstateSyncAdminPage />} />
                    }
                />

                {/* <Route path="/logout" element={<LogOut />} /> */}
                {/* <Route path="/property/preview" element={<PropertyPreview />} /> */}
                {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
                <Route path="/register" element={<RegistrationPage />} />
                <Route
                    path="/login"
                    element={
                        !isLoggedIn ? (
                            <LoginPage />
                        ) : (
                            <Navigate
                                to={
                                    redirect === "mailbox"
                                        ? "/mailbox"
                                        : "/property/list"
                                }
                            />
                        )
                    }
                />
                <Route
                    path="/"
                    element={
                        isLoggedIn ? (
                            <Navigate to="/property/list" />
                        ) : (
                            <Navigate to="/login" />
                        )
                    }
                />
                {/* <Route path="/property/list" element={<PropertyListPage />} /> */}
            </Routes>
        </MainWrapper>
    );
};

export default AppRouter;
