import { useState } from "react";
import { PropertyDetail, PropertyEnquiryForm } from "components";
import { Box, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getPropertyAPI, getPublicPropertyAPI, useApp } from "services";
import { PropertyEditProvider } from "services/property-edit-context";
import { Property } from "@interfaces";
const PropertyDetailPage = () => {
    // const { setDetailProperty, detailProperty } = useApp();
    const { id: detailPropertyId } = useParams();
    const [detailProperty, setDetailProperty] = useState<Property | undefined>(
        undefined,
    );
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchDetailProperty = async () => {
            setLoading((_) => true);
            if (!detailPropertyId) return;
            const res = await getPublicPropertyAPI(detailPropertyId);
            if (res) setDetailProperty(res);
            setLoading((_) => false);
        };
        fetchDetailProperty();
    }, []);

    const loggedInUserId = localStorage.getItem("user");
    const selfOwned = loggedInUserId === detailProperty?.user?.toString();
    return (
        <PropertyEditProvider>
            {detailProperty && !loading && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Grid
                        container
                        spacing="1rem"
                        sx={{ padding: selfOwned ? "" : "2rem" }}
                    >
                        <Grid item xs={12} lg={selfOwned ? 12 : 8}>
                            {detailProperty && (
                                <PropertyDetail property={detailProperty} />
                            )}
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            {!selfOwned && <PropertyEnquiryForm />}
                        </Grid>
                    </Grid>
                </Box>
            )}
        </PropertyEditProvider>
    );
};

export default PropertyDetailPage;
