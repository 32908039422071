export const LABELS = {
  subtitle: {
    en: 'Searching of properties, made easy!',
    de: 'Immobiliensuche leicht gemacht!'
  },
  enterCity: {
    en: 'Enter Your City',
    de: 'Geben Sie Ihre Stadt ein'
  },
  city: {
    en: 'City',
    de: 'Stadt'
  },
  search: {
    en: 'Search',
    de: 'Suche'
  },
  plot: {
    en: 'Plot',
    de: 'Handlung'
  },
  house: {
    en: 'House',
    de: 'Haus'
  },
  flat: {
    en: 'Flat',
    de: 'Eben'
  },
  sell: {
    en: 'Sell',
    de: 'Verkaufen '
  },
  rent: {
    en: 'Rent',
    de: 'Miete'
  },
  price: {
    en: 'Price',
    de: 'Preis'
  },
  postCode: {
    en: 'Postcode',
    de: 'Postleitzahl'
  },
  rooms: {
    en: 'Rooms',
    de: 'Räume'
  },
  area: {
    en: 'Area',
    de: 'Bereich'
  },
  filter: {
    en: 'Filter',
    de: 'Filter'
  },
  noDataFound: {
    en: 'No Data Found',
    de: 'Keine Daten gefunden'
  }
}
