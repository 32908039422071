import React, { useState, type ChangeEvent } from "react";
import { Box, Typography } from "@mui/material";
import { registartionAPI } from "services/authentication";
import { extractErrorMessage } from "services/apiUtils";
import { getLocalizedLabels } from "services/translations";
// import OtpVerificationModalContainer from "./../OtpVerificationModal/index";
import { useApp } from "services/app-context";
import { LABELS } from "components";
import RegistrationForm from "components/register/RegistrationForm";

const RegistrationPage = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const labels = getLocalizedLabels(LABELS);

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    // const [phoneNo, setPhoneNo] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState<string>("");

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setErrorMsg("");
        e.preventDefault();
        const { name, value } = e.target;
        if (name === "firstName") setFirstName(value);
        if (name === "lastName") setLastName(value);
        // if (name === "phoneNo") setPhoneNo(value);
        if (name === "email") setEmail(value);
        if (name === "password") setPassword(value);
        if (name === "passwordConfirm") setPasswordConfirm(value);
    };

    const validateForm = () => {
        let isValid = true;
        if (passwordConfirm !== password) {
            isValid = false;
            setErrorMsg("Dar eingegebenen Passwörter stimmen nicht überein.");
        }

        if (!firstName || !lastName || !email || !password) {
            isValid = false;
            setErrorMsg("Bitte ergänzen Sie die fehlenden Felder.");
        }

        return isValid;
    };

    const registrationHandler = async () => {
        try {
            setLoading((_) => true);

            if (!validateForm()) return;
            const res = await registartionAPI({
                firstName,
                lastName,
                email,
                password,
            });
            setSuccessMsg(res.detail);
        } catch (err) {
            console.error(err);
            const errorMsg = extractErrorMessage(err);
            setErrorMsg(errorMsg);
        } finally {
            setLoading((_) => false);
        }
    };

    const showPasswordFunc = (
        e: React.MouseEvent<HTMLButtonElement>,
        name?: string,
    ) => {
        if (name === "password") setShowPassword((_) => !showPassword);
        if (name === "passwordConfirm")
            setShowPasswordConfirm((_) => !showPasswordConfirm);
    };

    return (
        <Box className="account" sx={{ padding: { xs: "", md: "4rem 25%" } }}>
            <Box
                className="account__wrapper"
                sx={{
                    borderRadius: "8px",
                    padding: { xs: "1rem", lg: "1rem 8rem" },
                    backgroundColor: "rgba(0,0,0,0.05)",
                    boxShadow: "2px 2px 8px rgba(0,0,0,0.4)",
                }}
            >
                <Box>
                    <Box sx={{ padding: "1rem 0" }}>
                        <Typography variant={"h5"}>
                            {labels.welcome} Selbst-Vermarkten.de
                        </Typography>
                        <Typography
                            sx={{
                                borderBottom: "1px solid #cecece",
                            }}
                        >
                            {labels.heading}
                        </Typography>
                    </Box>
                    <RegistrationForm
                        showPasswordConfirm={showPasswordConfirm}
                        showPasswordFunc={showPasswordFunc}
                        handleChange={handleChange}
                        registrationHandler={registrationHandler}
                        showPassword={showPassword}
                        firstName={firstName}
                        lastName={lastName}
                        email={email}
                        password={password}
                        errorMsg={errorMsg}
                        successMsg={successMsg}
                        loading={loading}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default RegistrationPage;
