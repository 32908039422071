import { Box, Typography } from '@mui/material'
export const CardRow = ({ children, sx }: any) => {
  return (
        <Box
            sx={{
              marginBlock: '.5rem',
              display: 'flex',
              alignItems: 'center',
              ml: '1rem',
              width: { xs: '100%' },
              flexWrap: { xs: 'wrap', sm: 'nowrap' },
              ...sx
            }}
        >
            {children}{' '}
        </Box>
  )
}

export const CardRowItem = ({ children }: any) => {
  return (
        <Typography variant="caption" sx={{ width: { xs: '100%', sm: '45%' } }}>
            {children}
        </Typography>
  )
}
