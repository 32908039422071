export const buttonStyles = {
    auth: {
        backgroundColor: "rgba(12,53,130,.88)",
        borderColor: "rgba(12,53,130,.88)",
        color: "#fff",
        cursor: "pointer",
        width: "100%",
        marginBlock: "1rem",
        fontFamily: "sans-serif",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "rgba(12,53,130,.88)",
            opacity: 0.8,
        },
    },
    search: {
        backgroundColor: "rgba(12,53,130,.88)",
        borderColor: "rgba(12,53,130,.88)",
        color: "#fff",
        cursor: "pointer",
        width: "100%",
        marginBlock: "1rem",
        fontFamily: "sans-serif",
        textTransform: "none",
        borderRadius: "4px",
        height: "44px",
        "&:hover": {
            backgroundColor: "rgba(12,53,130,.88)",
            opacity: 0.8,
        },
    },
    select: {
        backgroundColor: "rgba(12,53,130,.88)",
        borderColor: "rgba(12,53,130,.88)",
        color: "#fff",
        cursor: "pointer",
        marginBlock: "1rem",
        fontFamily: "sans-serif",
        textTransform: "none",
        borderRadius: "4px",
        height: "44px",
        minWidth: "100px",
        "&:hover": {
            backgroundColor: "rgba(12,53,130,.88)",
            opacity: 0.8,
        },
    },

    privateProperty: {
        minWidth: "max(30%, 150px)",
        backgroundColor: "rgba(12,53,130,.88)",
        borderColor: "rgba(12,53,130,.88)",
        color: "#fff",
        cursor: "pointer",
        marginBlock: "1rem",
        fontFamily: "sans-serif",
        textTransform: "none",
        borderRadius: "4px",
        height: "44px",
        marginRight: "1rem",
        "&:hover": {
            backgroundColor: "rgba(12,53,130,1)",
            color: "#fff",
            transform: "scale(1.015)",
        },
    },
    secondary: {
        minWidth: "max(30%, 150px)",
        color: "rgba(12,53,130,.88)",
        borderColor: "rgba(12,53,130,.88)",
        backgroundColor: "#fff",
        cursor: "pointer",
        marginBlock: "1rem",
        fontFamily: "sans-serif",
        textTransform: "none",
        borderRadius: "4px",
        height: "44px",
        marginRight: "1rem",
        zIndex: "999",
        "&:hover": {
            color: "rgba(12,53,130,1)",
            backgroundColor: "#fff",
        },
    },
};
