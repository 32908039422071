import { Box } from "@mui/material";
import { PropertyImageList } from "components/shared/form/ImageDisplay";
import { propertyFormStyles } from "components/styles";
import { useCallback } from "react";
import { usePropertyCreation } from "services/property-creation-context";

import { MultiImageUploader } from "components";
import { usePropertyEdit } from "services/property-edit-context";
import { useParams } from "react-router-dom";

export const StepNineForm = () => {
    const { id } = useParams();
    const { property } = usePropertyEdit();

    return (
        <Box sx={propertyFormStyles.centeredContainer}>
            {id ? (
                <PropertyImageList property={property} />
            ) : (
                <MultiImageUploader />
            )}
        </Box>
    );
};
