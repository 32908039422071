import { Box, Button } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import React from "react";
import { Link } from "react-router-dom";
import { inputStyles } from "components/shared/form/inputStyles";

import { getLocalizedLabels } from "services/translations";
import { buttonStyles } from "components/styles/button";
import { ErrorDisplay } from "components/shared/ErrorDisplay";
import { LABELS } from "./constants";

interface LogInFormProps {
    showPassword: boolean;
    showPasswordFunc: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    loginHandler: () => void;
    errorMsg: string;
    successMsg: string;
}

export const LogInForm = ({
    showPassword,
    showPasswordFunc,
    handleChange,
    loginHandler,
    errorMsg,
    successMsg,
}: LogInFormProps) => {
    const labels = getLocalizedLabels(LABELS);

    return (
        <Box className="form">
            <Box className="form__form-group">
                {successMsg ? (
                    <Box className="alert alert-success">{successMsg}</Box>
                ) : (
                    ""
                )}
                <span
                    style={{
                        width: "80px",
                        maxHeight: "32px",
                        lineHeight: "18px",
                        margin: "auto 0",
                    }}
                >
                    {labels.username}
                </span>
                <Box className="form__form-group-field">
                    <Box
                        sx={{
                            padding: "6px",
                            backgroundColor: "rgba(0,0,0,0.1)",
                            height: "32px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <PersonOutlineOutlinedIcon />
                    </Box>
                    <input
                        name="email"
                        type="text"
                        placeholder={labels.username}
                        onChange={handleChange}
                        style={{
                            ...inputStyles,
                            height: "32px",
                            border: "none",
                            backgroundColor: "#fff",
                            width: "100%",
                        }}
                    />
                </Box>
            </Box>
            <Box className="form__form-group">
                <span className="form__form-group-label">
                    {labels.password}
                </span>
                <Box className="form__form-group-field">
                    <Box
                        sx={{
                            padding: "6px",
                            backgroundColor: "rgba(0,0,0,0.1)",
                            height: "32px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <KeyOutlinedIcon />
                    </Box>
                    <input
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder={labels.password}
                        onChange={handleChange}
                        onKeyDown={(e) => {
                            e.key === "Enter" && loginHandler();
                        }}
                        style={{
                            ...inputStyles,
                            backgroundColor: "#fff",
                            height: "32px",
                            border: "none",
                            width: "100%",
                        }}
                    />
                    <button
                        style={{
                            height: "32px",
                            border: "1x solid white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: ".5rem",
                            cursor: "pointer",
                        }}
                        onClick={showPasswordFunc}
                        type="button"
                    >
                        <RemoveRedEyeOutlinedIcon />
                    </button>
                </Box>

                {errorMsg ? <ErrorDisplay msg={errorMsg} /> : ""}
            </Box>
            <Button sx={buttonStyles.auth} onClick={loginHandler}>
                {labels.login}
            </Button>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <Link to="/register">{labels.register}</Link>
                <Link to="/forgot-password">Passwort vergessen?</Link>
            </Box>
        </Box>
    );
};
