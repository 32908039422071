import { type DisplayComponentProps } from '@interfaces'
import { Box, useTheme } from '@mui/material'

export const NavbarItemCentered = ({ children }: DisplayComponentProps) => {
  const { customColors } = useTheme()
  return (
        <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              minWidth: '200px',
              height: '100%',
              justifyContent: 'center',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: customColors.colorHover
              }
            }}
        >
            {children}
        </Box>
  )
}
