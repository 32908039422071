import { Box, Typography } from "@mui/material";
interface ErrorDisplayProps {
    msg: string;
    color?: string;
    fullHeight?: boolean;
}

export const SuccessDisplay = ({
    msg,
    color,
    fullHeight,
}: ErrorDisplayProps) => {
    return (
        <Box
            sx={{
                width: "100%",
                backgroundColor: "#d9ebff",
                border: "1px solid #d7eaff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: fullHeight ? "" : "3rem",
                padding: "0.75rem 1.25rem",
                borderRadius: "8px",
                marginBlock: "1rem",
            }}
        >
            <Typography variant="body2" sx={{ color: color || "#000" }}>
                {msg}
            </Typography>
        </Box>
    );
};
