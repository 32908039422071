import React, { useState } from "react";
import { Button, useTheme, Box, Typography } from "@mui/material";

import { getLocalizedLabels, useApp, createCustomerEnquiryAPI } from "services";
import { FaPhone } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { LABELS } from "./constants";
import "./style.css";
import "scss/component/custom.css";

import {
    LabeledTextAreaInput,
    TextInput,
} from "components/shared/form/TextInput";
import { type ChangeTextAreaEvent } from "types";
import { alphabetOnly, numbersOnly } from "regexp";
import { type Enquiry } from "interfaces";
import { Message, MessageDetail } from "interfaces/message.interface";
import { usePropertyEdit } from "services/property-edit-context";
export const PropertyEnquiryForm = () => {
    const navigate = useNavigate();
    const labels = getLocalizedLabels(LABELS);
    const { customColors } = useTheme();
    const { property } = usePropertyEdit();
    const defaultEnquiry: Enquiry = {
        email: "",
        phone: "",
        name: "",
        message: "",
        property: null,
    };

    const [enquiry, setEnquiry] = useState<Enquiry>(defaultEnquiry);
    const [messageSent, setMessageSent] = useState(false);
    const handleTextChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        regex?: RegExp,
    ) => {
        const { name, value } = e.target;

        const parsedValue = regex ? value.replace(regex, "") : value;
        setEnquiry((prev) => ({ ...prev, [name]: parsedValue }));
    };

    const handleTextAreaChange = (e: ChangeTextAreaEvent) => {
        const { name, value } = e.target;
        setEnquiry((prev) => ({ ...prev, [name]: value }));
    };

    const submitEnquiry = async () => {
        if (!property?.id) return;
        const res: Message = await createCustomerEnquiryAPI({
            ...enquiry,
            property: property.id,
        });
        if (res.id) {
            setMessageSent(true);
        }
    };

    return (
        <>
            {!messageSent ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Box
                        sx={{
                            border: "1px solid gray",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: "1rem",
                            borderRadius: "4px",
                            paddingBlock: "1rem",
                        }}
                    >
                        <Typography>Selbst-Vermarkten Member</Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <FaPhone
                                style={{
                                    marginRight: ".5rem",
                                    fontSize: "1rem",
                                    color: customColors.colorPrimary,
                                }}
                            />
                            <Typography
                                sx={{ color: customColors.colorPrimary }}
                            >
                                {property?.phone}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            border: "1px solid gray",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            mb: "1rem",
                            borderRadius: "4px",
                            padding: "1rem",
                        }}
                    >
                        <TextInput
                            name="name"
                            placeholder="Ihr Name"
                            handleChange={handleTextChange}
                            regex={alphabetOnly}
                            value={enquiry?.name}
                            mb="1rem"
                        />
                        <TextInput
                            name="email"
                            placeholder="Ihre Email"
                            handleChange={handleTextChange}
                            value={enquiry?.email}
                            mb="1rem"
                        />
                        <TextInput
                            name="phone"
                            placeholder="Ihre Telefonnummer"
                            handleChange={handleTextChange}
                            regex={numbersOnly}
                            value={enquiry?.phone}
                            mb="1rem"
                        />
                        <LabeledTextAreaInput
                            name="message"
                            label=""
                            placeholder="Ihre Anfrage"
                            handleTextAreaChange={handleTextAreaChange}
                            value={enquiry?.message}
                            fullWidth
                            mb="1rem"
                        />

                        <Button onClick={submitEnquiry}>Senden</Button>
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        border: "1px solid gray",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: "1rem",
                        borderRadius: "4px",
                        paddingBlock: "1rem",
                    }}
                >
                    <Typography>Ihre Anfrage wurde weitergeleitet!</Typography>
                    <Button
                        onClick={() => navigate("/property/results")}
                        sx={{ mt: "1rem" }}
                    >
                        Zur Übersicht
                    </Button>
                </Box>
            )}
        </>
    );
};
