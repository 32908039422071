import { type ReactNode } from "react";
import { inputStyles, labelledInputStyles } from "./inputStyles";
import { Box } from "@mui/system";
import { type ChangeTextAreaEvent, type ChangeInputEvent } from "types";
import { Typography, useTheme } from "@mui/material";
import "./../../../scss/component/custom.css";
import { ToolTipIcon } from "./ToolTipIcon";
import { numbersOnly } from "regexp";
import {
    InputValidationError,
    ValidationError,
} from "components/properties/ValidationError";
import { usePropertyCreation } from "services/property-creation-context";
import { usePropertyEdit } from "services/property-edit-context";

interface BaseTextInputProps {
    name: string;
    type?: string;
    tooltip?: string;
    fullWidth?: boolean;
    disabled?: boolean;
    mb?: string;
    hide?: boolean;
    placeholder?: string;
    regex?: RegExp;
    maxLength?: number;
    sx?: any;
    hasError?: boolean;
    errorMessage?: string;
}

interface TextInputProps extends BaseTextInputProps {
    handleChange: (
        e: ChangeInputEvent,
        regex?: RegExp,
        maxLength?: number,
    ) => void;
    value?: string;
}
interface LabeledTextInputProps extends BaseTextInputProps {
    handleChange: (
        e: ChangeInputEvent,
        regex?: RegExp,
        maxLength?: number,
    ) => void;
    value: string;
    label: string | JSX.Element | ReactNode;
}
interface LabeledTextAreaProps extends BaseTextInputProps {
    handleTextAreaChange: (e: ChangeTextAreaEvent) => void;
    value: string;
    label: string | JSX.Element | ReactNode;
    rows?: number;
}

interface LabeledNumberInputProps extends BaseTextInputProps {
    handleChange: (
        e: ChangeInputEvent,
        regex?: RegExp,
        maxLength?: number,
    ) => void;
    label: string | JSX.Element | ReactNode;
    value: number | null;
}

export const TextInput = ({
    type = "text",
    name,
    value,
    placeholder,
    handleChange,
    fullWidth,
    hide,
    regex,
    disabled,
    hasError,
    mb,
}: TextInputProps) => {
    const { customColors } = useTheme();
    return !hide ? (
        <>
            <input
                type={type}
                name={name}
                placeholder={disabled ? "" : placeholder}
                value={value}
                onChange={(e) => {
                    handleChange(e, regex);
                }}
                style={{
                    ...inputStyles,
                    border: hasError
                        ? `2px solid ${customColors.colorError}`
                        : "1px solid gray",
                    width: fullWidth ? "100%" : "auto",
                    marginBottom: hasError ? 0 : mb || 0,
                }}
            />
            {hasError && <InputValidationError />}
        </>
    ) : null;
};

export const SearchTextInput = ({
    type,
    name,
    placeholder,
    handleChange,
    fullWidth,
    hide,
    disabled,
    hasError,
    regex,
}: TextInputProps) => {
    const { customColors } = useTheme();
    return !hide ? (
        <input
            type={type}
            name={name}
            placeholder={disabled ? "" : placeholder}
            onChange={handleChange}
            style={{
                ...inputStyles,
                width: fullWidth ? "100%" : "15%",
                margin: "10px",
                border: hasError
                    ? `2px solid ${customColors.colorError}`
                    : "1px solid gray",
            }}
        />
    ) : null;
};

export const LabeledTextInput = ({
    label,
    name,
    placeholder,
    handleChange,
    fullWidth,
    errorMessage,
    value,
    mb = ".5rem",
    disabled = false,
    tooltip,
    hide,
    regex,
    type,
    hasError,
    sx,
}: LabeledTextInputProps) => {
    const { customColors } = useTheme();

    return !hide ? (
        <Box
            sx={{
                ...labelledInputStyles.wrapper,
                ...sx,
            }}
        >
            <Box sx={{ display: "flex" }}>
                <Typography variant="caption" sx={{ ml: ".5rem", mr: ".5rem" }}>
                    {label}
                </Typography>

                {tooltip && <ToolTipIcon text={tooltip} iconSize="small" />}
            </Box>
            <input
                name={name}
                value={value}
                placeholder={disabled ? "" : placeholder}
                onChange={(e) => {
                    handleChange(e, regex);
                }}
                style={{
                    ...inputStyles,
                    width: fullWidth ? "100%" : "15%",
                    marginBottom: hasError ? 0 : mb || 0,
                    background: disabled ? "#cecece" : "",
                    border: hasError
                        ? `2px solid ${customColors.colorError}`
                        : "1px solid gray",
                }}
                disabled={disabled}
                type={type === "password" ? "password" : "text"}
            />
            {hasError && <InputValidationError message={errorMessage} />}
        </Box>
    ) : null;
};

export const LabeledTextAreaInput = ({
    label,
    name,
    placeholder,
    handleTextAreaChange,
    fullWidth,
    value,
    mb = ".5rem",
    rows = 5,
    hide,
    hasError,
    regex,
}: LabeledTextAreaProps) => {
    const { customColors } = useTheme();
    return !hide ? (
        <Box sx={labelledInputStyles.wrapper}>
            <Typography variant="caption" sx={{ ml: ".5rem" }}>
                {label}
            </Typography>
            <textarea
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={handleTextAreaChange}
                style={{
                    ...inputStyles,
                    width: fullWidth ? "100%" : "15%",
                    marginBottom: hasError ? 0 : mb || 0,
                    height: "auto",
                    paddingTop: ".75rem",
                    border: hasError
                        ? `2px solid ${customColors.colorError}`
                        : "1px solid gray",
                }}
                rows={6}
            ></textarea>
            {hasError && <InputValidationError />}
        </Box>
    ) : null;
};

export const LabeledNumberInput = ({
    label,
    name,
    placeholder,
    handleChange,
    fullWidth,
    value,
    mb = ".5rem",
    hide,
    disabled,
    regex = numbersOnly,
    hasError,
    maxLength,
}: LabeledNumberInputProps) => {
    const { customColors } = useTheme();
    const parseValue = (value: number | null) => {
        if (!value) return "";
        if (typeof value !== "number") return Number(value);
        return value;
    };
    return !hide ? (
        <Box sx={{ ...labelledInputStyles.wrapper }}>
            {typeof label === "string" && (
                <Typography
                    variant="caption"
                    sx={{
                        ml: ".5rem",
                    }}
                >
                    {label}
                </Typography>
            )}
            {typeof label !== "string" && label}
            <input
                type="number"
                name={name}
                value={parseValue(value)}
                placeholder={disabled ? "" : placeholder}
                onChange={(e) => {
                    handleChange(e, regex, maxLength);
                }}
                style={{
                    ...inputStyles,
                    width: fullWidth ? "100%" : "15%",
                    marginBottom: hasError ? 0 : mb || 0,
                    backgroundColor: disabled ? "#cecece" : "",
                    border: hasError
                        ? `2px solid ${customColors.colorError}`
                        : "1px solid gray",
                }}
                className={"hide-spinner"}
            />
            {hasError && <InputValidationError />}
        </Box>
    ) : null;
};
