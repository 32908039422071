import { Box, Typography } from "@mui/material";
import { propertyFormStyles } from "components/styles";
import { SelectButton } from "components/shared/SelectButton";
import { usePropertyCreation } from "services/property-creation-context";
import { PropertyType, TransactionType } from "enums";
import { useParams } from "react-router-dom";
import { usePropertyEdit } from "services/property-edit-context";
import { ValidationError } from "components/properties/ValidationError";

export const StepOneForm = () => {
    const { id } = useParams();
    const propertyTypeButtons = [
        { label: "Haus", value: PropertyType.Haus },
        { label: "Wohnung", value: PropertyType.Wohnung },
        { label: "Grundstück", value: PropertyType.Grundstück },
    ];
    const transactionTypeButtons = [
        { label: "Verkaufen", value: TransactionType.Kaufen },
        { label: "Vermieten", value: TransactionType.Mieten },
    ];
    const { property, setProperty, validationErrors, handleSelectChange } = id
        ? usePropertyEdit()
        : usePropertyCreation();

    return (
        <Box sx={propertyFormStyles.centeredContainer}>
            <Typography color="#000">
                Um was für eine Immobilie handelt es sich ? *
            </Typography>
            <Box sx={propertyFormStyles.buttonRow}>
                {propertyTypeButtons.map((type) => {
                    return (
                        <Box
                            sx={propertyFormStyles.buttonWrapper}
                            key={type.value}
                        >
                            <SelectButton
                                label={type.label}
                                fullWidth
                                handleClick={() => {
                                    handleSelectChange(
                                        type.value,
                                        "property_type",
                                    );
                                }}
                                selected={type.value === property.property_type}
                                disabled={id !== undefined}
                                hasError={validationErrors.includes(
                                    "property_type",
                                )}
                            />
                        </Box>
                    );
                })}
            </Box>
            <Typography color="#000">
                Wählen Sie Verkaufen oder Vermieten *
            </Typography>
            <Box sx={propertyFormStyles.buttonRow}>
                {transactionTypeButtons.map((type) => {
                    return (
                        <Box
                            sx={propertyFormStyles.buttonWrapper}
                            key={type.value}
                        >
                            <SelectButton
                                label={type.label}
                                selected={
                                    type.value === property.transaction_type
                                }
                                fullWidth
                                handleClick={() => {
                                    handleSelectChange(
                                        type.value,
                                        "transaction_type",
                                    );
                                }}
                                disabled={id !== undefined}
                                hasError={validationErrors.includes(
                                    "transaction_type",
                                )}
                            />
                        </Box>
                    );
                })}
            </Box>
            {validationErrors.length > 0 && (
                <ValidationError
                    message={
                        "Bitte wählen Sie Immobilienart und den Grund für die Inserierung."
                    }
                />
            )}
        </Box>
    );
};
