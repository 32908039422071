import { Box, Typography, useTheme } from "@mui/material";
import { usePropertyCreation } from "services/property-creation-context";
import { EnergyBar } from "components";
import {
    formatDate,
    formatPrice,
    getEnergyGrade,
    getLocalizedLabels,
} from "services";
import { LABELS } from "../../constants";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "scss/component/custom.css";

import {
    FaLayerGroup,
    FaThLarge,
    FaExternalLinkAlt,
    FaDoorOpen,
    FaBath,
    FaCalendarCheck,
    FaBuilding,
    FaHome,
    FaCar,
    FaFileSignature,
    FaCheckCircle,
    FaMapMarkerAlt,
} from "react-icons/fa";
import { type Property } from "@interfaces";
import { ImageCarousel } from "./ImageCarousel";
import { PropertyImageList } from "components/shared/form/ImageDisplay";
import { useParams } from "react-router-dom";
import { usePropertyEdit } from "services/property-edit-context";

export const StepTenForm = () => {
    const { id } = useParams();
    const labels = getLocalizedLabels(LABELS);
    const { customColors } = useTheme();
    const { property, setCurrentStep } = id
        ? usePropertyEdit()
        : usePropertyCreation();

    const displayBooleanFields = (arrayOfFields: Array<keyof Property>) => {
        const values = arrayOfFields.map((field) => property[field]);
        if (!values.includes(true) && !arrayOfFields.includes("refurbished")) {
            return " Keine Angabe";
        }
        const returnString = arrayOfFields
            .filter((field) => property[field])
            .map((field) => labels[field])
            .join(", ");
        return returnString;
    };

    const detailKeyList = [
        "floor",
        "living_space",
        "plot_area",
        "room_count",
        "bathroom_count",
        "free_from",
        "flat_type",
        "house_type",
        "garage_count",
        "state_of_development",
    ];
    const detailLabelList = [
        "Stockwerk / Anzahl der Geschosse",
        "Quadratmeter",
        "Gründstück",
        "Zimmer",
        "Badezimmer",
        "Frei ab",
        "Wohnungsart",
        "Haustyp",
        "Garage",
        "Erschließungszustand",
    ];

    const furnishingKeyList = [
        "patio",
        "balcony",
        "rooftop_terrace",
        "winter_garden",
        "garden",
        "elevator",
        "full_bath",
        "shower_room",
        "sauna",
        "swimming_pool",
        "fitted_kitchen",
        "chimney",
        "barrier_free",
    ];
    const furnishingLabelList = [
        "Terrasse",
        "Balkon",
        "Dachterasse",
        "Wintergarten",
        "Garten",
        "Fahrstuhl",
        "Vollbad",
        "Duschbad",
        "Sauna",
        "Pool/Schwimmbad",
        "Einbauküche",
        "Kamin",
        "Barrierefrei",
    ];

    const detailIconList = [
        <FaLayerGroup />,
        <FaThLarge />,
        <FaExternalLinkAlt />,
        <FaDoorOpen />,
        <FaBath />,
        <FaCalendarCheck />,
        <FaBuilding />,
        <FaHome />,
        <FaCar />,
        <FaFileSignature />,
    ];

    const styles = {
        row: {
            padding: "1rem 2rem",
            boxShadow: "2px 2px 2px 2px rgba(0,0,0,.2)",
        },
        sectionTitle: {
            fontSize: "1.75rem",
            mb: "1rem",
        },
        detailOfProperty: {
            minWidth: "20%",
            mb: "2rem",
            display: "flex",
            flexDirection: "column",
            fontSize: "1.1rem",
        },
        subtitleSection: {
            mb: "1rem",
            width: "45%",
            textAlign: "justify",
            mr: "1.5rem",
        },
        energySection: {
            mb: "1rem",
            mr: "1.5rem",
            fontSize: "1.25rem",
        },
        sectionSubtitle: {
            fontSize: "1rem",
            fontWeight: 700,
        },
    };

    return (
        <>
            <ImageCarousel />
            <Box sx={{ backgroundColor: "#fff" }}>
                <Box sx={{ ...styles.row, display: "flex" }}>
                    <Box sx={{ flex: 1 }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <FaMapMarkerAlt
                                style={{
                                    fontSize: "1.5rem",
                                    marginRight: ".5rem",
                                }}
                            />
                            <Typography sx={{ fontSize: "1.5rem" }}>
                                {property.street ? property.street + " " : ""}
                                {property.house_number
                                    ? property.house_number
                                    : ""}
                                ,
                            </Typography>
                        </Box>
                        <Typography sx={{ fontSize: "1.5rem", ml: "2rem" }}>
                            {property.post_code ? property.post_code + "," : ""}{" "}
                            {property.city ? property.city + "," : ""}{" "}
                        </Typography>
                        <Typography sx={{ fontSize: "1.5rem", ml: "2rem" }}>
                            {property.country}{" "}
                        </Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Typography
                            sx={{
                                fontSize: "1.5rem",
                                fontWeight: 700,
                                color: customColors.colorPrimary,
                            }}
                        >
                            {property.transaction_type === "sell"
                                ? "Kaufpreis"
                                : "Mietpreis"}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "1.5rem",
                                color: customColors.colorPrimary,
                            }}
                        >
                            {formatPrice(
                                property.price || property.base_rent || 0,
                            )}{" "}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                    }}
                >
                    <Box sx={styles.row}>
                        <Typography sx={styles.sectionTitle}>
                            Details
                        </Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                            {detailKeyList.map((key, idx) => {
                                if (
                                    key === "free_from" &&
                                    property.free_from_date
                                ) {
                                    return (
                                        <Box
                                            key={key}
                                            sx={styles.detailOfProperty}
                                        >
                                            <b>
                                                {detailIconList[idx]} &nbsp;{" "}
                                                {detailLabelList[idx]} &nbsp;
                                            </b>
                                            <li style={{ marginLeft: "2rem" }}>
                                                {`${formatDate(
                                                    property[
                                                        "free_from_date" as keyof Property
                                                    ] as any,
                                                )}`}
                                            </li>
                                        </Box>
                                    );
                                }
                                if (
                                    key === "free_from" &&
                                    !property.free_from_date
                                ) {
                                    return (
                                        <Box
                                            key={key}
                                            sx={styles.detailOfProperty}
                                        >
                                            <b>
                                                {detailIconList[idx]} &nbsp;{" "}
                                                {detailLabelList[idx]} &nbsp;
                                            </b>
                                            <li style={{ marginLeft: "2rem" }}>
                                                {`${
                                                    labels[
                                                        property[
                                                            key as keyof Property
                                                        ] as any
                                                    ]
                                                }`}
                                            </li>
                                        </Box>
                                    );
                                }
                                return (
                                    property[key as keyof Property] && (
                                        <Box
                                            key={key}
                                            sx={styles.detailOfProperty}
                                        >
                                            <b>
                                                {detailIconList[idx]} &nbsp;{" "}
                                                {detailLabelList[idx]} &nbsp;
                                            </b>
                                            <li
                                                style={{ marginLeft: "2rem" }}
                                            >{`${
                                                property[
                                                    key as keyof Property
                                                ] as any
                                            }`}</li>
                                        </Box>
                                    )
                                );
                            })}
                        </Box>
                    </Box>

                    <Box sx={{ ...styles.row }}>
                        <Typography sx={styles.sectionTitle}>
                            Beschreibung
                        </Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                            {property.description && (
                                <Box sx={styles.subtitleSection}>
                                    <Typography sx={styles.sectionSubtitle}>
                                        Allgemein
                                    </Typography>
                                    <Typography>
                                        {property.description}
                                    </Typography>
                                </Box>
                            )}
                            {property.description_of_location && (
                                <Box sx={styles.subtitleSection}>
                                    <Typography sx={styles.sectionSubtitle}>
                                        Zur Lage
                                    </Typography>
                                    <Typography>
                                        {property.description_of_location}
                                    </Typography>
                                </Box>
                            )}
                            {property.description_of_equipment && (
                                <Box sx={styles.subtitleSection}>
                                    <Typography sx={styles.sectionSubtitle}>
                                        Zur Ausstattung
                                    </Typography>
                                    <Typography>
                                        {property.description_of_equipment}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Box sx={styles.row}>
                        <Typography sx={styles.sectionTitle}>
                            Ausstattung
                        </Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                            {furnishingKeyList.map((key, idx) => {
                                return (
                                    property[key as keyof Property] && (
                                        <Box
                                            key={key}
                                            sx={{
                                                minWidth: "20%",
                                            }}
                                        >
                                            {
                                                <b>
                                                    <FaCheckCircle />{" "}
                                                    {furnishingLabelList[idx]}{" "}
                                                </b>
                                            }
                                        </Box>
                                    )
                                );
                            })}
                        </Box>
                    </Box>

                    <Box sx={styles.row}>
                        <Typography sx={styles.sectionTitle}>
                            Bausubstanz & Energieausweis
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                            <Box>
                                <Box sx={styles.energySection}>
                                    <b>Objektzustand: </b>
                                    {displayBooleanFields([
                                        "old_building",
                                        "new_building",
                                    ])}
                                    &nbsp;
                                    {displayBooleanFields([
                                        "renovated",
                                        "refurbished",
                                        "in_need_of_renovation",
                                    ])}
                                    &nbsp;({property.year_of_construction})
                                </Box>
                                {property.energy_certificate && (
                                    <Box sx={styles.energySection}>
                                        <b>Energieausweis: </b>{" "}
                                        {property.energy_certificate}
                                    </Box>
                                )}
                                {property.energy_need && (
                                    <Box sx={styles.energySection}>
                                        <b>Energiebedarf: </b>{" "}
                                        {property.energy_need} kWh/(m^2*a)
                                    </Box>
                                )}
                            </Box>
                            <Box>
                                {
                                    <Box sx={styles.energySection}>
                                        <b>Wesentliche Energieträger: </b>
                                        {displayBooleanFields([
                                            "oil",
                                            "gas",
                                            "district_heating",
                                            "electric",
                                            "pellets",
                                            "solar",
                                        ])}
                                    </Box>
                                }
                                {property.energy_card_type && (
                                    <Box sx={styles.energySection}>
                                        <b>Ausweisart: </b>{" "}
                                        {property.energy_card_type}
                                    </Box>
                                )}

                                {property.energy_need && (
                                    <Box sx={styles.energySection}>
                                        <b>Energieeffizienz-Klasse: </b>{" "}
                                        {getEnergyGrade(property.energy_need)}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <EnergyBar
                            score={property.energy_need}
                            grade={getEnergyGrade(property.energy_need)}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};
