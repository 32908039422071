import { Box } from "@mui/material";
import { propertyFormStyles } from "components/styles";
import { usePropertyCreation } from "services/property-creation-context";

import { getLocalizedLabels } from "services";
import { LABELS } from "../../constants";

import { leftInputs, rightInputs } from "./step-5-inputs";
import { DisplayInput } from "components/shared/form/DisplayInput";
import { isVisible } from "services/display-input.utils";
import { useParams } from "react-router-dom";
import { usePropertyEdit } from "services/property-edit-context";

export const StepFiveForm = () => {
    const labels = getLocalizedLabels(LABELS);
    const { id } = useParams();
    const { property } = id ? usePropertyEdit() : usePropertyCreation();
    const { base_rent, additional_price } = property;
    const parsedBaseRent = base_rent || 0;
    const parsedAdditionalPrice = additional_price || 0;

    return (
        <Box sx={propertyFormStyles.centeredContainer}>
            <Box
                sx={{
                    width: "90%",
                    marginInline: "1rem",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                }}
            >
                {[...leftInputs, ...rightInputs]
                    .filter((inp) => isVisible(inp, property))
                    .map((input, idx) => (
                        <Box sx={{ width: { xs: "100%", lg: "48%" } }}>
                            <DisplayInput key={idx} {...input} />
                        </Box>
                    ))}
                {property.transaction_type === "rent" && (
                    <Box sx={{ width: { xs: "100%", lg: "48%" } }}>
                        <DisplayInput
                            name="total_rental_cost"
                            label={labels.totalRent}
                            type="auto_calculated"
                            automatedValue={
                                parsedBaseRent + parsedAdditionalPrice
                            }
                            fullWidth
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};
