import { type Labels } from "interfaces";

export const LABELS: Labels = {
    subtitle: {
        en: "Searching of properties, made easy!",
        de: "Immobiliensuche leicht gemacht!",
    },
    enterCity: {
        en: "Enter Your City",
        de: "Geben Sie Ihre Stadt ein",
    },
    city: {
        en: "City",
        de: "Stadt",
    },
    search: {
        en: "Search",
        de: "Suche",
    },
    plot: {
        en: "Plot",
        de: "Grundstück",
    },
    house: {
        en: "House",
        de: "Haus",
    },
    flat: {
        en: "Flat",
        de: "Wohnung",
    },
    sell: {
        en: "Sell",
        de: "Verkaufen ",
    },
    rent: {
        en: "Rent",
        de: "Miete",
    },
    price: {
        en: "Price",
        de: "Preis",
    },
    purchase_price: {
        en: "price",
        de: "Kaufpreis in \u20AC",
    },
    postCode: {
        en: "Postcode",
        de: "Postleitzahl",
    },
    rooms: {
        en: "Rooms",
        de: "Räume",
    },
    area: {
        en: "Area",
        de: "Bereich",
    },
    filter: {
        en: "Filter",
        de: "Filter",
    },
    noDataFound: {
        en: "No Data Found",
        de: "Keine Daten gefunden",
    },
    descriptionForm: {
        en: "Headline & description",
        de: "Überschrift & Beschreibung",
    },
    overview: {
        en: "Overview",
        de: "Überblick",
    },
    selectIsRent: {
        en: "Select Sell or Rent",
        de: "Wählen Sie Verkaufen oder Vermieten",
    },
    selectPropertyType: {
        en: "Select type of property",
        de: "Wählen Sie die Art der Immobilie",
    },
    pleaseSelect: {
        en: "Please select",
        de: "Bitte auswählen",
    },
    currentImages: {
        en: "Current Images",
        de: "aktuelle Bilder",
    },
    delete: {
        en: "Delete",
        de: "löschen",
    },

    step: {
        en: "What kind of property is it?",
        de: "Um was für eine Immobilie handelt es sich?",
    },
    next: {
        en: "Next",
        de: "Nächster",
    },
    title: {
        en: "Title",
        de: "Überschrift der Anzeige",
    },
    description: {
        en: "Description",
        de: "Beschreibung",
    },
    house_type: {
        en: "House type",
        de: "Haustyp",
    },
    farm: {
        en: "Farm",
        de: "Bauernhaus",
    },
    semi_detached: {
        en: "Semi detached",
        de: "Doppelhaushälfte",
    },
    one_family_house: {
        en: "One family house",
        de: "Einfamilienhaus",
    },
    cottage: {
        en: "Cottage",
        de: "Ferienhaus",
    },
    multiple_dwelling: {
        en: "Multiple dwelling",
        de: "Mehrfamilienhaus",
    },
    terraced_end: {
        en: "Terraced end house",
        de: "Reihenendhaus",
    },
    terraced: {
        en: "Terraced house",
        de: "Reihenhaus",
    },
    villa: {
        en: "Villa",
        de: "Villa",
    },
    two_family: {
        en: "Two-family house",
        de: "Zweifamilienhaus",
    },
    bungalow: {
        en: "Bungalow",
        de: "Bungalow",
    },
    castle: {
        en: "Castle / palace",
        de: "Burg / Schloss",
    },
    plot_area: {
        en: "Plot Area",
        de: "Gründstück",
    },
    room_count: {
        en: "Number of rooms",
        de: "Anzahl Zimmer",
    },
    floor_count: {
        en: "Number of floors",
        de: "Anzahl Geschosse",
    },
    bedroom_count: {
        en: "Number of bedrooms",
        de: "Anzahl Schlafzimmer",
    },
    bathroom_count: {
        en: "Number of bathrooms",
        de: "Anzahl Badezimmer",
    },
    year_of_construction: {
        en: "Year of construction",
        de: "Baujahr",
    },
    free_from: {
        en: "Free from",
        de: "Frei ab",
    },
    free_from_date: {
        en: "Available From",
        de: "Verfügbar ab",
    },
    immediately: {
        en: "Immediately",
        de: "Sofort",
    },
    living_space: {
        en: "Living space",
        de: "Wohnfläche",
    },
    fix_date: {
        en: "Fix date",
        de: "Datum",
    },
    description_of_location: {
        en: "Location Description",
        de: "Lagebeschreibung",
    },
    description_of_equipment: {
        en: "Equipment Description",
        de: "Austattung",
    },
    floor: {
        en: "Floor",
        de: "Stockwerk",
    },
    by_agreement: {
        en: "By Agreement",
        de: "nach Vereinbarung",
    },
    yes: {
        en: "Yes",
        de: "Ja",
    },
    no: {
        en: "No",
        de: "Nein",
    },
    flat_type: {
        en: "Flat type",
        de: "Wohnungsart",
    },
    apartment: {
        en: "Apartment",
        de: "Apartment",
    },
    loft: {
        en: "Loft",
        de: "Loft",
    },
    maisonette: {
        en: "Maisonette",
        de: "Maisonette",
    },
    penthouse: {
        en: "PentHouse",
        de: "PentHouse",
    },
    state_of_development: {
        en: "State of development",
        de: "Erschließungszustand",
    },
    undeveloped: {
        en: "Undeveloped",
        de: "Unerschlossen",
    },
    partly_developed: {
        en: "Partly Developed",
        de: "Teilerschlossen",
    },
    fully_developed: {
        en: "Fully Developed",
        de: "Vollerschlossen",
    },
    basicInformation: {
        en: "Basic Information",
        de: "Grundinformation",
    },
    general: {
        en: "General",
        de: "Allgemeines",
    },
    addressInformation: {
        en: "Address Information",
        de: "Adresse",
    },
    street: {
        en: "Street",
        de: "Straße",
    },
    house_number: {
        en: "House Number",
        de: "Hausnummer",
    },

    country: {
        en: "Country",
        de: "Land",
    },
    hide_address: {
        en: "Do not publish street and house number",
        de: "Verbergen von Straße und Hausnummer in der Anzeige",
    },
    post_code: {
        en: "Post Code",
        de: "PLZ",
    },
    energyData: {
        en: "Energy Data",
        de: "Energiedaten",
    },
    energy_certificate: {
        en: "Is there an energy certificate?",
        de: "Liegt ein Energieausweis vor?",
    },
    notSpecified: {
        en: "Not specified",
        de: "keine Angabe",
    },
    yesAvailable: {
        en: "Yes, is available",
        de: "Ja, liegt vor",
    },
    notSubjectToEnEV: {
        en: "No, real estate is not subject to the requirements of the EnEV",
        de: "Nein, Immobilie unterliegt nicht den Anforderungen der EnEV",
    },
    notApplicable: {
        en: "Not Applicable",
        de: "Unzutreffend",
    },
    energy_date_of_creation: {
        en: "Date of creation",
        de: "Erstellungsdatum",
    },
    untilApril: {
        en: "Until 30th april 2014",
        de: "Bis 30. April 2014",
    },
    fromMay: {
        en: "From 01. may 2014",
        de: "Nach 01. Mai 2014",
    },
    energy_card_type: {
        en: "Type of energy card",
        de: "Ausweisart",
    },
    consumptionCertificate: {
        en: "Consumption certificate",
        de: "Verbraucherausweis",
    },
    needCertificate: {
        en: "Certificate of need",
        de: "Bedarfsausweis",
    },
    energy_year_of_construction: {
        en: "Year of construction according to energy certificate",
        de: "Baujahr laut Energieausweis",
    },
    energy_consumption: {
        en: "Energy Consumption",
        de: "Endenergieverbrauch",
    },
    energy_need: {
        en: "Energy Need",
        de: "Jährlicher Endenergiebedarf pro m^2 in kWh",
    },
    energy_for_hot_water_included: {
        en: "Energy consumption for hot water is included",
        de: "Energieverbrauch für Warmwasser ist enthalten",
    },
    essentialEnergySources: {
        en: "Essential energy sources",
        de: "Wesentliche Energieträger",
    },
    gas: {
        en: "Gas",
        de: "Gas",
    },
    district_heating: {
        en: "District heating",
        de: "Fernwärme",
    },
    oil: {
        en: "Oil",
        de: "Öl",
    },
    electric: {
        en: "Electric",
        de: "Elektro",
    },
    pellets: {
        en: "Pellets",
        de: "Pellets",
    },
    solar: {
        en: "Solar",
        de: "Solar",
    },
    typeOfHeating: {
        en: "Type of heating",
        de: "Heizungsart",
    },
    central_heating: {
        en: "Central heating",
        de: "Zentralheizung",
    },
    underfloor_heating: {
        en: "Underfloor heating",
        de: "Fußbodenheizung",
    },
    storey_heating: {
        en: "Storey heating",
        de: "Etagenheizung",
    },
    tiled_stove: {
        en: "Tiled stove",
        de: "Kachelofen",
    },
    open_fireplace: {
        en: "Open fireplace",
        de: "Offener Kamin",
    },
    pricing: {
        en: "Pricing",
        de: "Preisgestaltung",
    },

    price_on_request: {
        en: "Price by request",
        de: "Preis auf Anfrage",
    },
    additional_price: {
        en: "Additional costs by month",
        de: "Hausgeld pro Monat",
    },
    garage_count: {
        en: "Garage",
        de: "Anzahl Stellplätze",
    },
    garage_price: {
        en: "Sell price for each garage",
        de: "Kaufpreis je Stellplatz",
    },
    base_rent: {
        en: "Base rent per month in €",
        de: "Kaltmiete in €",
    },
    additionalRentCost: {
        en: "Additional costs for rent by month in €",
        de: "Nebenkosten pro Monat in €",
    },
    garageRentCost: {
        en: "Rent price for each garage",
        de: "Mietpreis je Stellplatz",
    },
    heating_cost: {
        en: "Heating costs",
        de: "Heizkosten",
    },
    heating_cost_included: {
        en: "Heating costs are included",
        de: "Heizkosten in Nebenkosten enthalten",
    },
    totalRent: {
        en: "Total rent",
        de: "Warmmiete (in €) aus Ihren Angaben errechnet",
    },
    deposit: {
        en: "Deposit in €",
        de: "Kaution in €",
    },
    equipment: {
        en: "Equipment",
        de: "Ausstattung",
    },
    balcony: {
        en: "Balcony",
        de: "Balkon",
    },
    patio: {
        en: "Patio",
        de: "Terrasse",
    },
    winter_garden: {
        en: "Winter garden",
        de: "Wintergarten",
    },
    garden: {
        en: "Garden",
        de: "Garten",
    },
    basement: {
        en: "Basement",
        de: "Keller",
    },
    rooftop_terrace: {
        en: "Rooftop terrace",
        de: "Dachterrasse",
    },
    elevator: {
        en: "Elevator",
        de: "Fahrstuhl",
    },
    full_bath: {
        en: "Full bath",
        de: "Vollbad",
    },
    shower_room: {
        en: "Shower room",
        de: "Duschbad",
    },
    sauna: {
        en: "Sauna",
        de: "Sauna",
    },
    swimming_pool: {
        en: "Pool / Swimming pool",
        de: "Pool / Schwimmbad",
    },
    fitted_kitchen: {
        en: "Fitted kitchen",
        de: "Einbauküche",
    },
    guest_toilet: {
        en: "Guest toilet",
        de: "Gäste-WC",
    },
    chimney: {
        en: "Chimney",
        de: "Kamin",
    },
    barrier_free: {
        en: "Barrier-free",
        de: "Barrierefrei",
    },
    condition: {
        en: "Condition",
        de: "Zustand",
    },
    new_building: {
        en: "New building",
        de: "Neubau",
    },
    old_building: {
        en: "Old building",
        de: "Altbau",
    },
    refurbished: {
        en: "Refurbished",
        de: "saniert",
    },
    renovated: {
        en: "Renovated",
        de: "renoviert",
    },
    in_need_of_renovation: {
        en: "In need of renovation",
        de: "renovierungsbedürftig",
    },
    additionalInfo: {
        en: "Additional Information",
        de: "zusätzliche Information",
    },
    name: {
        en: "Name",
        de: "Name",
    },
    email: {
        en: "Email",
        de: "Email",
    },
    phone: {
        en: "Phone",
        de: "Telefon",
    },
    hide_name: {
        en: "Hide name",
        de: "Name nicht anzeigen",
    },
    hide_email: {
        en: "Hide email",
        de: "E-Mail nicht anzeigen",
    },
    hide_phone: {
        en: "Hide phone",
        de: "Telefonnummer nicht anzeigen",
    },
    contactInformation: {
        en: "Contact Information",
        de: "Kontakt Informationen",
    },
    uploadImages: {
        en: "Upload Images",
        de: "Bilder hochladen",
    },
    previous: {
        en: "Previous",
        de: "Bisherige",
    },
    create_property: {
        en: "Create Property",
        de: "Eigenschaft erstellen",
    },
    listingId: {
        en: "Listing ID",
        de: "Auflistung ICH WÜRDE",
    },
    created: {
        en: "Created",
        de: "Erstellt",
    },
    updated: {
        en: "Updated",
        de: "Aktualisiert",
    },
    deactivate: {
        en: "Deactivate",
        de: "Deaktivieren",
    },
    activate: {
        en: "Activate",
        de: "Aktivieren",
    },
    edit: {
        en: "Edit",
        de: "Bearbeiten",
    },
    archive: {
        en: "Archive",
        de: "Archivieren",
    },

    handoverAgents: {
        en: "Handover To Agents",
        de: "Übergabe an Agenten",
    },
    view: {
        en: "View Details",
        de: "Details anzeigen",
    },
    extraService: {
        en: "Extra Services",
        de: "zusätzliche Dienstleistungen",
    },
    cancelButton: {
        en: "Cancel",
        de: "Stornieren",
    },
    servicesReq: {
        en: "Request Services",
        de: "Services anfordern",
    },
};
