import { type ChangeEvent, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PropertyFilter, CardRow, CardRowItem } from "components";
import {
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography,
    Box,
    Button,
} from "@mui/material";
import buyImg from "assets/images/buy.png";
import rentImg from "assets/images/rent.png";
import { LocationOnOutlined } from "@mui/icons-material";

import {
    formatDate,
    formatPrice,
    searchPropertyListAPI,
    useApp,
} from "services";
import { buttonStyles, typographyStyles } from "components/styles";
import {
    FaExternalLinkAlt,
    FaDoorOpen,
    FaCar,
    FaClock,
    FaFileWord,
    FaEuroSign,
} from "react-icons/fa";

const PropertySearchPage = () => {
    const navigate = useNavigate();
    const { properties } = useApp();
    const [searchParams, setSearchParams] = useSearchParams();
    const [showFilter, setShowFilter] = useState<boolean>(true);

    const filterHandler = async () => {
        const tempParams = searchParams;

        if (!searchParams.get("page")) {
            searchParams.set("page", "1");
        }
        if (!searchParams.get("limit")) {
            searchParams.set("limit", "6");
        }
        setSearchParams(tempParams);
        await searchPropertyListAPI(tempParams);
    };

    const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const tempParams = searchParams;
        if (value === "") searchParams.delete(name);
        else {
            tempParams.set(name, value);
        }

        setSearchParams(tempParams);
    };

    const handleSelectChange = (selectedOption: string, name: string) => {
        const tempParams = searchParams;
        if (selectedOption === "") tempParams.delete(name);
        else tempParams.set(name, selectedOption);

        setSearchParams(tempParams);
    };

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target;
        const tempParams = new URLSearchParams(searchParams);
        const isSelected = tempParams.get(name);
        if (isSelected === "true") {
            tempParams.delete(name);
        } else tempParams.set(name, "true");
        setSearchParams(tempParams);
    };

    const toggleShowFilter = () => {
        setShowFilter((prev) => !prev);
    };

    return (
        <Box
            sx={{
                margin: "auto",
                maxWidth: { xs: "100%", md: "80%" },
            }}
        >
            <PropertyFilter
                filterHandler={filterHandler}
                handleTextChange={handleTextChange}
                handleCheckboxChange={handleCheckboxChange}
                handleSelectChange={handleSelectChange}
                toggleShowFilter={toggleShowFilter}
                showFilter={showFilter}
            />

            <Grid container spacing={2} sx={{ marginTop: "1.5rem" }}>
                {properties.map((property, index) => (
                    <Grid key={index} item xs={12} sm={6}>
                        <Card
                            sx={{
                                padding: "1rem",
                                position: "relative",
                                boxShadow: " 1px 4px 8px #aeaeae",
                            }}
                        >
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "0px",
                                    right: "-.1rem",
                                }}
                            >
                                <img
                                    alt="transaction"
                                    src={
                                        property.transaction_type === "sell"
                                            ? buyImg
                                            : rentImg
                                    }
                                    style={{
                                        maxWidth: "100px",
                                    }}
                                />
                            </Box>

                            <CardContent>
                                <h3>{property.title}</h3>
                                <Box
                                    sx={{
                                        display: "flex",
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        image={
                                            property.images[0]
                                                ? property.images[0]?.url
                                                : ""
                                        }
                                        src={
                                            property.images[0]
                                                ? property.images[0].url
                                                : ""
                                        }
                                        alt="PropertyView"
                                        sx={{
                                            maxWidth: "150px",
                                            maxHeight: "150px",
                                        }}
                                    />
                                    <Box sx={{ flex: 1 }}>
                                        {/* Title Row */}
                                        <CardRow sx={{ mb: "1rem", ml: 0 }}>
                                            <LocationOnOutlined fontSize="large" />
                                            <Typography
                                                sx={typographyStyles.card_title}
                                            >
                                                {`${property.city}, ${property.post_code}, ${property.country}`}
                                            </Typography>
                                        </CardRow>

                                        <CardRow>
                                            <CardRowItem>
                                                <FaEuroSign />{" "}
                                                {property.transaction_type ===
                                                "sell"
                                                    ? "Kaufpreis"
                                                    : "Mietpreis"}
                                                :{" "}
                                                {formatPrice(
                                                    property.price ||
                                                        property.base_rent ||
                                                        0,
                                                )}
                                            </CardRowItem>{" "}
                                            &euro;
                                            <CardRowItem>
                                                <FaExternalLinkAlt />{" "}
                                                Wohnfläche:{" "}
                                                {property.living_space}
                                            </CardRowItem>
                                        </CardRow>
                                        <CardRow>
                                            <CardRowItem>
                                                <FaFileWord /> Listing-ID:
                                                {property.id
                                                    ? 1000 + property.id
                                                    : "N/A"}
                                            </CardRowItem>{" "}
                                            <CardRowItem>
                                                <FaDoorOpen /> Anzahl Zimmer:{" "}
                                                {property.room_count}
                                            </CardRowItem>
                                        </CardRow>
                                        <CardRow>
                                            <CardRowItem>
                                                <FaClock /> Erstellt:{" "}
                                                {formatDate(
                                                    property.created_on,
                                                )}
                                            </CardRowItem>{" "}
                                            <CardRowItem>
                                                <FaCar /> Anzahl Stellplätze:{" "}
                                                {property.garage_count}
                                            </CardRowItem>
                                        </CardRow>
                                    </Box>
                                </Box>
                                <Button
                                    sx={{
                                        ...buttonStyles.search,
                                        width: "50%",
                                    }}
                                    onClick={() =>
                                        navigate(
                                            `/property/detail-public/${property.id}`,
                                        )
                                    }
                                >
                                    Details Anzeigen
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default PropertySearchPage;
