import React, { type ReactNode } from "react";
import { useApp } from "services/app-context";
import { Box, useTheme } from "@mui/material";

interface MainWrapperProps {
    children: ReactNode;
}

const MainWrapper: React.FC<MainWrapperProps> = ({ children }) => {
    const { loading } = useApp();
    const theme = useTheme();
    return !loading ? (
        <Box
            sx={{
                padding: 0,
                margin: 0,
                backgroundColor: "#f2f4f7",
                minHeight: "100dvh",
            }}
        >
            {children}
        </Box>
    ) : (
        <div className="load">
            <div className="load__icon-wrap">
                <svg className="load__icon">
                    <path
                        fill="#4ce1b6"
                        d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                    />
                </svg>
            </div>
        </div>
    );
};

export default MainWrapper;
