import { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { propertyFormStyles } from "components/styles";
import { usePropertyCreation } from "services/property-creation-context";
import { CheckboxInput, LabeledTextInput, TwoColumnForm } from "components";
import { formatPrice, getLocalizedLabels } from "services";
import { LABELS } from "../../constants";

import { PackageCard } from "./PackageCard";
import { DisplayInput } from "components/shared/form/DisplayInput";
import { leftInputs, rightInputs } from "./step-12-inputs";
import { type Coupon, type Package } from "@interfaces";
import { alphaNumericOnly } from "regexp";
import { ValidationError } from "components/properties/ValidationError";

export const StepTwelveForm = () => {
    const { customColors } = useTheme();
    const labels = getLocalizedLabels(LABELS);
    const {
        coupons,
        packages,
        property,
        handleTextChange,
        setProperty,
        agreedToTerms,
        setAgreedToTerms,
        validationErrors,
        setValidationErrors,
    } = usePropertyCreation();
    const { transaction_type } = property;
    const [selectedPlan, setSelectedPlan] = useState<number | null>(
        property.plan_duration,
    );
    const [couponCode, setCouponCode] = useState<string>("");
    const [selectedPackage, setSelectedPackage] = useState<number | null>(
        property.plan,
    );
    const [discount, setDiscount] = useState<number>(0);

    const selectPackage = (packageId: number) => {
        setSelectedPackage(packageId);
        setValidationErrors((prev) => prev.filter((err) => err !== "plan"));
    };
    const selectPlan = (planId: number) => {
        setSelectedPlan(planId);
    };
    const price_key = `${transaction_type === "sell" ? "sale" : "rent"}_cost_${
        property.plan_duration
    }_days`;

    const currPackage = packages.filter((p) => p.id === selectedPackage)[0];
    const price = currPackage ? currPackage[price_key as keyof Package] : 0;
    const currentPackagePrice = formatPrice(price as number);

    const handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const couponMatch = coupons.filter(
            (coupon) => coupon.code === e.target.value,
        )[0];
        setCouponCode(value);
        setProperty((prev) => ({ ...prev, coupon_code: "" }));
        if (couponMatch) {
            setDiscount(Number(couponMatch.amount));
            setProperty((prev) => ({
                ...prev,
                invoice_coupon_id: couponMatch.id.toString(),
            }));
        }
    };

    const getPercentValue = (coupon?: Coupon) => {
        if (!coupon) {
            return 0;
        }
        const percent = Number(coupon.amount) / 100;
        return percent;
    };

    return (
        <Box
            sx={{
                ...propertyFormStyles.centeredContainer,
                width: "100%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                }}
            >
                {packages.map((packageData) => (
                    <PackageCard
                        key={packageData.id}
                        packageData={packageData}
                        selectedPlan={selectedPlan}
                        selectedPackage={selectedPackage}
                        handlePlanSelection={selectPlan}
                        handlePackageSelection={selectPackage}
                    />
                ))}
            </Box>
            {validationErrors.includes("plan") && (
                <Box
                    sx={{
                        border: "1px solid rgb(128,0,0,1)",
                        padding: "8px",
                        borderRadius: "4px",
                        marginBottom: "2rem",
                    }}
                >
                    <ValidationError message={"Bitte wählen Sie ein Paket."} />
                </Box>
            )}
            <Typography variant="h6" sx={{ color: customColors.colorPrimary }}>
                Rechnungsadresse
            </Typography>
            <TwoColumnForm
                leftSide={
                    <Box>
                        {leftInputs.map((input) => (
                            <DisplayInput key={input.name} {...input} />
                        ))}
                    </Box>
                }
                rightSide={
                    <Box>
                        {rightInputs.map((input) => (
                            <DisplayInput key={input.name} {...input} />
                        ))}
                    </Box>
                }
            />
            <Typography
                variant="h6"
                sx={{ marginBlock: "1rem", color: customColors.colorPrimary }}
            >
                Ihre Rechnung erhalten Sie nach der Buchung
            </Typography>
            <TwoColumnForm
                leftSide={
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box sx={{ marginBottom: "1rem", marginLeft: "1rem" }}>
                            <Typography variant="h5">
                                Paket: {currPackage?.name}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                            <LabeledTextInput
                                label="Rabatcode"
                                maxLength={6}
                                regex={alphaNumericOnly}
                                name="coupon_code"
                                value={couponCode}
                                type="text"
                                handleChange={handleCouponChange}
                                fullWidth
                            />
                        </Box>
                        <Typography variant="h5" sx={{ marginBlock: ".5rem" }}>
                            Gesamtsumme: {currentPackagePrice}
                        </Typography>
                        <Typography variant="h5" sx={{ marginBlock: ".5rem" }}>
                            Ersparnis: {formatPrice(discount)}
                        </Typography>
                        <Typography variant="h5" sx={{ marginBlock: ".5rem" }}>
                            Endsumme:{" "}
                            {!discount
                                ? formatPrice(Number(price))
                                : discount > Number(price)
                                ? "Gratis"
                                : formatPrice(Number(price) - Number(discount))}
                        </Typography>

                        <CheckboxInput
                            name="agbs"
                            label="Ich bestätige die Nutzungsbedingungen gelesen zu haben und bin mit diesen einverstanden."
                            checked={agreedToTerms}
                            handleChange={() => {
                                setAgreedToTerms((prev) => !prev);
                            }}
                        />
                    </Box>
                }
                rightSide={<></>}
            />
        </Box>
    );
};
