import { Button, useTheme, Box } from '@mui/material'

export const SelectionButton = ({
  isSelected,
  label,
  handleClick
}: {
  isSelected: boolean
  label?: string
  handleClick: () => void
}) => {
  const { customColors } = useTheme()
  const { colorPrimary } = customColors
  const buttonStyles = {
    active: {
      backgroundColor: !isSelected ? colorPrimary : 'transparent',
      borderColor: colorPrimary,
      marginInline: '.25rem',
      transition: '500ms',
      color: '#fff',
      '&:hover': {
        transform: 'scale(1.05)',
        backgroundColor: !isSelected ? colorPrimary : 'transparent',
        borderColor: colorPrimary,
        color: '#fff'
      }
    },
    disabled: {
      visibility: 'hidden'
    }
  }

  return (
    <Button
      sx={isSelected ? buttonStyles.disabled : buttonStyles.active}
      onClick={handleClick}
    >
      {label}
    </Button>
  )
}

export const PlanSelectionButtons = ({
  planData,
  disabled,
  updatePlanDuration,
  planDuration,
  packageIsSelected,
  isRent
}: any) => {
  const theme = useTheme()
  const { customColors } = theme
  const pricingPrefix = isRent ? 'rent' : 'sale'
  const showBtn30Days = Boolean(planData[`${pricingPrefix}_cost_30_days`])
  const showBtn90Days = Boolean(planData[`${pricingPrefix}_cost_90_days`])
  const showBtn60Days = Boolean(planData[`${pricingPrefix}_cost_60_days`])
  const buttonStyles = {
    active: {
      borderColor: 'green',
      backgroundColor: 'green',
      color: '#fff',
      flex: 1,
      marginInline: '.5rem',
      fontWeight: 700,
      '&:focus': {
        backgroundColor: 'green',
        color: '#fff',
        flex: 1,
        marginInline: '.5rem'
      }
    },
    disabled: {
      backgroundColor: customColors.colorBackground,
      color: customColors.colorPrimary,
      marginInline: '.5rem',
      flex: 1
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      {showBtn30Days
        ? (
          <Button
            sx={
              planDuration === 30 && packageIsSelected
                ? buttonStyles.active
                : buttonStyles.disabled
            }
            onClick={(e) => updatePlanDuration(30)}
          >
            30 Tage
          </Button>
        )
        : (
          ''
        )}
      {showBtn60Days
        ? (
          <Button
            sx={
              planDuration === 60 && packageIsSelected
                ? buttonStyles.active
                : buttonStyles.disabled
            }
            onClick={(e) => updatePlanDuration(60)}
          >
            60 Tage
          </Button>
        )
        : (
          ''
        )}
      {showBtn90Days
        ? (
          <Button
            sx={
              planDuration === 90 && packageIsSelected
                ? buttonStyles.active
                : buttonStyles.disabled
            }
            onClick={(e) => updatePlanDuration(90)}
          >
            90 Tage
          </Button>
        )
        : (
          ''
        )}

    </Box>
  )
}
