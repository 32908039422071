import axios, { type AxiosError } from "axios";

export const getWithHeader = async <T,>(url: string): Promise<T | void> => {
    try {
        const { data } = await axios.get<T>(url, {
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`,
            },
        });
        return data;
    } catch (error: any) {
        console.error(error.response.data);
    }
};

export const postWithHeader = async <T,>(
    url: string,
    payload: T,
): Promise<any> => {
    try {
        const { data } = await axios.post<T>(url, payload, {
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`,
            },
        });
        return data;
    } catch (error: any) {
        console.error(error?.response?.data);
        return error as AxiosError;
    }
};

export const patchWithHeader = async <T,>(
    url: string,
    payload: T,
): Promise<T | void> => {
    try {
        const { data } = await axios.patch<T>(url, payload, {
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`,
            },
        });
        return data;
    } catch (error: any) {
        console.error(error.response.data);
    }
};

export const deleteMultipleWithHeader = async <T,>(
    url: string,
    ids: number[],
): Promise<T | AxiosError> => {
    try {
        const { data } = await axios.post<T>(url, ids, {
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`,
            },
        });
        return data;
    } catch (error: any) {
        console.error(error?.response?.data);
        return error as AxiosError;
    }
};
