import { type PropertyCreateDto } from "@interfaces";
import axios, { type AxiosError } from "axios";
import { formatForDto } from "./date-utils";
export const errorHandler = (error: AxiosError) => {
    if (error.status === 401 || error.status === 403) {
        localStorage.removeItem("token");
    } else if (error.status === 404) {
        console.error("notfound");
        // location.href = "/404";
    } else {
    }
};

// export const extractErrorMessage = (apiResponse: AxiosError) => {
//     const data = apiResponse.response?.data;
//     const errorKey = data ? Object.keys(data) : []
//     let message = "";
//     if (apiResponse?.response?.status === 400) {
//         // if (errorKey == "detail") {
//             // message = data[errorKey];
//         } else if (errorKey == "non_field_errors") {
//             // message = data[errorKey][0];
//         } else {
//             // message = errorKey[0] + " : " + data[errorKey[0]];
//         }
//     } else {
//         message = "Something went wrong!";
//     }
//     return message;
// };

export const extractErrorMessage = (error: any): string => {
    let message = "Not an axios Error";
    if (axios.isAxiosError(error)) {
        const data = error.response?.data as { detail: string };
        const errorKeys = data ? Object.keys(data) : [];
        if (errorKeys.includes("detail")) {
            return (message = data.detail);
        }
        if (error?.response?.status === 400) {
            message = "Something went wrong!";
        }
        if (error?.response?.status === 401) {
            return (message = "Bitte melden Sie sich an.");
        }
    }
    return message;
};

export const getQueryParameter = (field: string, url: string) => {
    const href = url || window.location.href;
    const reg = new RegExp("[?&]" + field + "=([^&#]*)", "i");
    const string = reg.exec(href);
    return string ? decodeURI(string[1]) : null;
};

export const createPropertyDto = (property: PropertyCreateDto): any => {
    return {
        ...property,
        free_from_date: formatForDto(property.free_from_date),
        is_active: false,
    };
};

export const parsePropertyDataForForm = () => {};
