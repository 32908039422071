import React, { ChangeEvent, type MouseEvent } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import { Link } from "react-router-dom";
import { getLocalizedLabels } from "services/translations";
import { ErrorDisplay } from "components/shared/ErrorDisplay";
import { LABELS } from "./constants";
import { buttonStyles } from "components/styles/button";
import { inputStyles } from "components/shared/form/inputStyles";
import { SuccessDisplay } from "components/shared/SuccessDisplay";

interface RegistrationFormProps {
    loading: boolean;
    showPasswordConfirm: boolean;
    showPassword: boolean;
    errorMsg: string;
    successMsg: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    showPasswordFunc: (e: MouseEvent<HTMLButtonElement>, name?: string) => void;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    registrationHandler: () => void;
}

const RegistrationForm: React.FC<RegistrationFormProps> = (props) => {
    const {
        showPassword,
        showPasswordConfirm,
        showPasswordFunc,
        handleChange,
        registrationHandler,
        errorMsg,
        successMsg,
        loading,
    } = props;
    const labels = getLocalizedLabels(LABELS);

    const styles = {
        label: {
            width: "120px",
            maxHeight: "32px",
            lineHeight: "18px",
            margin: "auto 0",
        },
        input: {
            ...inputStyles,
            height: "32px",
            border: "none",
            backgroundColor: "#fff",
            width: "100%",
        },
        iconWrapper: {
            padding: "6px",
            backgroundColor: "rgba(0,0,0,0.1)",
            height: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    };

    return !successMsg ? (
        <Box className="form">
            <Box className="form__form-group">
                {/* {successMsg ? <Box align="center" className="alert alert-success">{successMsg}</Box> : ''} */}
                <span className="form__form-group-label" style={styles.label}>
                    {labels.firstname}
                </span>
                <Box className="form__form-group-field">
                    <Box
                        className="form__form-group-icon"
                        sx={styles.iconWrapper}
                    >
                        <PersonOutlineOutlinedIcon />
                    </Box>
                    <input
                        name="firstName"
                        type="text"
                        placeholder={labels.firstname}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        style={styles.input}
                    />
                </Box>
            </Box>
            <Box className="form__form-group">
                <span className="form__form-group-label" style={styles.label}>
                    {labels.lastname}
                </span>
                <Box className="form__form-group-field">
                    <Box
                        className="form__form-group-icon"
                        sx={styles.iconWrapper}
                    >
                        <PersonOutlineOutlinedIcon />
                    </Box>
                    <input
                        name="lastName"
                        type="text"
                        placeholder={labels.lastname}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        style={styles.input}
                    />
                </Box>
            </Box>
            {/* <Box className="form__form-group">
                <span className="form__form-group-label" style={styles.label}>
                    {labels.phoneno}
                </span>
                <Box className="form__form-group-field">
                    <Box
                        className="form__form-group-icon"
                        sx={styles.iconWrapper}
                    >
                        <LocalPhoneOutlinedIcon />
                    </Box>
                    <input
                        name="phoneNo"
                        type="text"
                        placeholder={labels.phoneno}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        style={styles.input}
                    />
                </Box>
            </Box> */}
            <Box className="form__form-group">
                <span className="form__form-group-label" style={styles.label}>
                    {labels.email}
                </span>
                <Box className="form__form-group-field">
                    <Box
                        className="form__form-group-icon"
                        sx={styles.iconWrapper}
                    >
                        <EmailOutlinedIcon />
                    </Box>
                    <input
                        name="email"
                        type="text"
                        placeholder={labels.email}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        style={styles.input}
                    />
                </Box>
            </Box>
            <Box className="form__form-group">
                <span className="form__form-group-label" style={styles.label}>
                    {labels.password}
                </span>
                <Box className="form__form-group-field">
                    <Box
                        className="form__form-group-icon"
                        sx={styles.iconWrapper}
                    >
                        <KeyOutlinedIcon />
                    </Box>
                    <input
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder={labels.password}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        style={styles.input}
                    />
                    <button
                        className={`form__form-group-button${
                            showPassword ? " active" : ""
                        }`}
                        onClick={(e) => showPasswordFunc(e, "password")}
                        type="button"
                    >
                        <RemoveRedEyeOutlinedIcon />
                    </button>
                </Box>
            </Box>
            <Box className="form__form-group">
                <span className="form__form-group-label" style={styles.label}>
                    {labels.password}
                </span>
                <Box className="form__form-group-field">
                    <Box
                        className="form__form-group-icon"
                        sx={styles.iconWrapper}
                    >
                        <KeyOutlinedIcon />
                    </Box>
                    <input
                        name="passwordConfirm"
                        type={showPasswordConfirm ? "text" : "password"}
                        placeholder={labels.passwordConfirm}
                        onKeyDown={(e) => {
                            e.key === "Enter" && registrationHandler();
                        }}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        style={styles.input}
                    />
                    <button
                        className={`form__form-group-button${
                            showPasswordConfirm ? " active" : ""
                        }`}
                        onClick={(e) => showPasswordFunc(e, "passwordConfirm")}
                        type="button"
                    >
                        <RemoveRedEyeOutlinedIcon />
                    </button>
                </Box>

                {errorMsg ? <ErrorDisplay color="#000" msg={errorMsg} /> : ""}
            </Box>
            <Box style={{ marginBottom: "15px", color: "#999999" }}>
                Mit Ihrer Anmeldung bestätigen Sie, dass Sie unsere
                <a
                    target="_blank"
                    href="https://www.selbst-vermarkten.de/agb.html"
                    rel="noreferrer"
                >
                    {" "}
                    AGBs{" "}
                </a>
                und
                <a
                    target="_blank"
                    href="https://www.selbst-vermarkten.de/datenschutz.html"
                    rel="noreferrer"
                >
                    {" "}
                    Datenschutzbestimmungen{" "}
                </a>
                gelesen und akzeptiert haben.
                <br />
            </Box>
            <Button sx={buttonStyles.auth} onClick={registrationHandler}>
                {loading ? <CircularProgress /> : labels.register}
            </Button>
            <Box>
                {labels.haveAnAccount} <Link to="/login">{labels.login}</Link>
            </Box>
        </Box>
    ) : (
        <>
            <Typography variant="h5">Erfolg!</Typography>
            <SuccessDisplay fullHeight color="#000" msg={successMsg} />
            <Box>
                <Link to="/login">Zum Login</Link>
            </Box>
        </>
    );
};

export default RegistrationForm;
