import { type ChangeEvent, useCallback, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PropertyFilter, CardRow, CardRowItem, SidebarItem } from "components";
import {
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography,
    Box,
    Button,
} from "@mui/material";
import buyImg from "assets/images/buy.png";
import rentImg from "assets/images/rent.png";
import { LocationOnOutlined } from "@mui/icons-material";

import {
    formatDate,
    formatPrice,
    getMessageDetailAPI,
    getMessagesAPI,
    getToggleReadAPI,
    searchPropertyListAPI,
    sendMailAPI,
    sendTestMail,
    updateCategoryAPI,
    useApp,
} from "services";
import { FaInbox, FaStar, FaBan, FaTrash, FaBug } from "react-icons/fa";
import { Dot } from "components/messages/Dot";
import {
    Message,
    MessageAPIResponse,
    MessageDetail,
    MessageStats,
} from "interfaces/message.interface";
import { Inbox } from "components/messages/Inbox";
import { MessageContent } from "components/messages/MessageContent";

const MailboxPage = () => {
    const [messages, setMessages] = useState<Message[]>();
    const [messagesStats, setMessageStats] = useState<MessageStats>();
    const [openMessage, setOpenMessage] = useState<MessageDetail | undefined>();
    const [searchParams, setSearchParams] = useSearchParams();

    const sendMail = async () => {
        await sendTestMail();
    };

    const updateMessageCategory = async (newCategory: string) => {
        const id = searchParams.get("messageId");
        if (!id || !openMessage) return;
        const res = await updateCategoryAPI(id, { category: newCategory });
        await fetchMessages();
        setOpenMessage(res);
    };

    const toggleMessageRead = async () => {
        const id = searchParams.get("messageId");
        if (!id || !openMessage) return;
        const res = await getToggleReadAPI(id);
        await fetchMessages();
    };

    const fetchMessages = useCallback(async () => {
        const query = searchParams.toString();
        const data = await getMessagesAPI(query);
        if (data) {
            setMessages(data.messages);
            setMessageStats(data.stats);
        }
    }, [searchParams]);

    const fetchOpenedMessage = useCallback(async () => {
        const messageId = searchParams.get("messageId");
        if (!messageId) {
            setOpenMessage(undefined);
            return;
        }
        const data = await getMessageDetailAPI(messageId);
        setOpenMessage(data);
    }, [searchParams]);

    useEffect(() => {
        fetchMessages();
        fetchOpenedMessage();
    }, [fetchMessages]);

    return (
        <Grid
            sx={{
                margin: "auto",
                maxWidth: { xs: "100%" },
                bgcolor: "#fff",
                padding: "1rem",
                borderRadius: "8px",
            }}
            container
            flexDirection={"column"}
        >
            <Box sx={{ mb: "2rem" }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                    Anfragen von Kunden
                </Typography>

                <Typography variant="body1">
                    Verwalten Sie hier Anfragen an ihren Immobilien.
                </Typography>
            </Box>
            <Grid container>
                <Grid item xs={12} md={2}>
                    <Box sx={{ mb: "1rem" }}>
                        <SidebarItem
                            paramName={"category"}
                            paramValue={""}
                            label="Posteingang"
                            Icon={FaInbox}
                            count={messagesStats?.inbox}
                        />
                        <SidebarItem
                            paramName={"category"}
                            paramValue={"interesting"}
                            label="Interessant"
                            Icon={FaStar}
                            count={messagesStats?.interesting}
                        />
                        <SidebarItem
                            paramName={"category"}
                            paramValue={"not_interesting"}
                            label="Uninteressant"
                            Icon={FaBan}
                            count={messagesStats?.not_interesting}
                        />
                        <SidebarItem
                            paramName={"category"}
                            paramValue={"deleted"}
                            label="Gelöscht"
                            Icon={FaTrash}
                            count={messagesStats?.deleted}
                        />
                        <SidebarItem
                            paramName={"category"}
                            paramValue={"spam"}
                            label="Spam"
                            Icon={FaBug}
                            count={messagesStats?.spam}
                        />
                    </Box>

                    <Box>
                        <Typography sx={{ fontSize: "14px" }}>
                            <b>Labels</b>
                        </Typography>
                        <SidebarItem
                            paramName={"label"}
                            paramValue="immowelt"
                            label="immowelt"
                            Icon={() => <Dot bgColor="#4ce1b6" />}
                        />
                        <SidebarItem
                            paramName={"label"}
                            paramValue="homesy"
                            label="selbst-vermarkten"
                            Icon={() => <Dot bgColor="#0c3582e1" />}
                        />
                        <SidebarItem
                            paramName={"label"}
                            paramValue="immoscout"
                            label="immoscout"
                            Icon={() => <Dot bgColor="lightgreen" />}
                        />
                    </Box>
                    <Typography
                        sx={{
                            color: "orange",
                            fontStyle: "italic",
                            fontSize: "14px",
                            mt: "1rem",
                            cursor: "pointer",
                            transition: "400ms",
                            "&:hover": {
                                color: "red",
                            },
                        }}
                        onClick={() => setSearchParams({})}
                    >
                        Filter zurücksetzen
                    </Typography>
                </Grid>

                <Grid item xs={12} md={10}>
                    {openMessage ? (
                        <MessageContent
                            message={openMessage}
                            updateMessageCategory={updateMessageCategory}
                            toggleMessageRead={toggleMessageRead}
                        />
                    ) : (
                        <Inbox
                            messages={messages}
                            stats={messagesStats}
                            fetchMessages={fetchMessages}
                        />
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MailboxPage;
