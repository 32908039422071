import React, { type ChangeEvent, useState } from 'react'
// import OtpVerificationModalContainer from "./../OtpVerificationModal/index";

import { useLocation, useNavigate } from 'react-router-dom'
import BasicSearch from 'components/search/BasicSearch'

const BasicPropertySearchPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [params, setParams] = useState<URLSearchParams>(
    new URLSearchParams(location.search)
  )
  const getResults = () => {
    const baseUrl = '/property/results'
    const searchUrl = `${baseUrl}?${params.toString()}`
    navigate(searchUrl)
    // for (let key in params) {
    //     url = `${url}${key}=${params.get(key)}&`;
    // }
  }

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    const tempParams = params
    tempParams.set(name, value)
    setParams(tempParams)
  }

  return (
        <BasicSearch
            handleTextChange={handleTextChange}
            searchPropertyHandler={getResults}
        />
  )
}

export default BasicPropertySearchPage
