import { Box, Radio, Typography, useTheme } from "@mui/material";
import {
    usePropertyCreation,
    type CurrentStep,
} from "services/property-creation-context";

interface PropertyMenuStepProps {
    selected: boolean;
    label: string;
    handleClick: (currentStep: CurrentStep) => void;
    idx: CurrentStep;
}

export const PropertyMenuStep = ({
    label,
    handleClick,
    selected,
    idx,
}: PropertyMenuStepProps) => {
    const { palette, customColors, customFontSizes } = useTheme();
    const { currentStep } = usePropertyCreation();
    const isCurrent = idx === currentStep;
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
                backgroundColor: isCurrent ? customColors.colorHover : "#fff",
                alignItems: "center",
                paddingBlock: "1rem",
                flex: "auto",
            }}
            onClick={() => {
                handleClick(idx);
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    paddingInline: ".5rem",
                }}
            >
                <Typography
                    fontSize={customFontSizes.small}
                    sx={{
                        textDecoration: isCurrent ? "underline" : null,
                    }}
                >
                    {label}
                </Typography>

                <Radio
                    sx={{
                        color: `${palette.primary.main}`,
                        "&:hover": {
                            backgroundColor: isCurrent
                                ? customColors.colorHover
                                : "#fff",
                        },
                    }}
                    checked={selected}
                />
            </Box>
        </Box>
    );
};
