import { Box, Collapse, Typography, useTheme } from "@mui/material";
import { NavbarItemCentered } from "./NavbarItemCentered";
import {
    ChatBubbleOutlineOutlined,
    ChevronRight,
    HomeOutlined,
    NoteAddOutlined,
} from "@mui/icons-material";
import AddchartIcon from "@mui/icons-material/Addchart";
import { type ReactNode } from "react";

import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useNavigate } from "react-router-dom";
import { IS_DEV, logoutAPI, useApp } from "services";

export const TopbarNavRightSide = (): JSX.Element => {
    const { showTopNavDropdown, setShowTopNavDropdown } = useApp();
    const navigate = useNavigate();
    const { customShadows, customColors } = useTheme();
    const { user } = useApp();

    const goToProfile = () => {
        navigate("/profile");
    };

    const handleLogout = async () => {
        await logoutAPI();
        navigate("/login");
    };
    return (
        <Box
            sx={{
                flex: 1,
                position: "relative",
                zIndex: 101,
            }}
        >
            <NavbarItemCentered>
                <Box
                    onClick={(_) => {
                        setShowTopNavDropdown(!showTopNavDropdown);
                    }}
                    sx={{
                        flex: 1,
                        display: "flex",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: showTopNavDropdown
                            ? "#cecece"
                            : "#fff",
                        transition: "1000ms",
                    }}
                >
                    <Typography>
                        Guten Tag,{" "}
                        {user?.first_name || localStorage.getItem("first_name")}{" "}
                        &nbsp;{" "}
                        <ChevronRight
                            sx={{
                                fontSize: "16px",
                                transform: "translate(-10px,3px)",
                            }}
                        />
                    </Typography>
                </Box>
            </NavbarItemCentered>

            <Collapse
                in={showTopNavDropdown}
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: showTopNavDropdown
                        ? customColors.colorHover
                        : "#fff",
                }}
            >
                <Box>
                    <DropDownItem
                        icon={<PersonOutlineOutlinedIcon />}
                        label="Kontoeinstellungen"
                        handleClick={goToProfile}
                    />

                    <DropDownItem
                        icon={<NoteAddOutlined />}
                        label="Inserieren"
                        handleClick={() => navigate("/property/new")}
                    />
                    <DropDownItem
                        icon={<HomeOutlined />}
                        label="Meine Immobilien"
                        handleClick={() => navigate("/property/list")}
                    />
                    <DropDownItem
                        icon={<ChatBubbleOutlineOutlined />}
                        label="Nachrichten"
                        handleClick={() => navigate("/mailbox")}
                    />
                    {IS_DEV && (
                        <DropDownItem
                            icon={<AddchartIcon />}
                            label="Estate Sync Admin"
                            handleClick={() => navigate("/estate_admin")}
                        />
                    )}
                    <DropDownItem
                        icon={<LogoutOutlinedIcon />}
                        label="Ausloggen"
                        handleClick={handleLogout}
                    />
                </Box>
            </Collapse>
        </Box>
    );
};

const DropDownItem = ({
    label,
    icon,
    handleClick,
}: {
    label: string;
    icon: ReactNode;
    handleClick: () => void;
}) => {
    const { customColors } = useTheme();
    return (
        <Box
            sx={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "1rem",
                "&:hover": {
                    backgroundColor: customColors.colorHover,
                },
            }}
            onClick={handleClick}
        >
            {icon}
            <Typography>{label}</Typography>
        </Box>
    );
};
