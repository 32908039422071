import { createTheme } from "@mui/material";
import { type Theme } from "@mui/material/styles/createTheme";

declare module "@mui/material/styles" {
    interface Theme {
        customColors: {
            colorBackground: string;
            colorBackgroundBody: string;
            colorText: string;
            colorTextAdditional: string;
            colorHover: string;
            colorBorder: string;
            colorIcon: string;
            imgInvert: string;
            colorFieldsBorder: string;
            colorBubble: string;
            colorBubbleActive: string;
            colorScrollbar: string;
            colorFitness: string;
            colorPrimary: string;
            colorError: string;
        };
        customShadows: {
            boxShadowLight: string;
        };
        customFontSizes: {
            small: string;
            normal: string;
            large: string;
        };
    }
    interface ThemeOptions {
        customColors: {
            colorBackground: string;
            colorBackgroundBody: string;
            colorText: string;
            colorTextAdditional: string;
            colorHover: string;
            colorBorder: string;
            colorIcon: string;
            imgInvert: string;
            colorFieldsBorder: string;
            colorBubble: string;
            colorBubbleActive: string;
            colorScrollbar: string;
            colorFitness: string;
            colorPrimary: string;
            colorError: string;
        };
        customShadows: {
            boxShadowLight: string;
        };
        customFontSizes: {
            small: string;
            normal: string;
            large: string;
        };
    }
}

const CUSTOM_COLORS = {
    colorBackground: "white",
    colorBackgroundBody: "#f2f4f7",
    colorText: "#646777",
    textBlue: "#70bbfc",
    colorTextAdditional: "#646777",
    colorHover: "#fafbfe",
    colorBorder: "#eff1f5",
    colorIcon: "#dddddd",
    imgInvert: "invert(0%)",
    colorFieldsBorder: "#f2f4f7",
    colorBubble: "rgba(242, 244, 247, 0.65)",
    colorBubbleActive: "rgba(234, 238, 255, 0.6)",
    colorScrollbar: "#b4bfd0",
    colorFitness: "#646777",
    colorPrimary: "rgba(12,53,130,0.88)",
    colorError: "rgba(128,0,0,1)",
};
export const theme: Theme = createTheme({
    customColors: {
        ...CUSTOM_COLORS,
    },
    customShadows: {
        boxShadowLight: "0px 2px 5px 0px rgba(0,0,0,.3)",
    },
    customFontSizes: {
        small: ".85rem",
        normal: "1rem",
        large: "1.25rem",
    },

    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: CUSTOM_COLORS.colorText,
                    ":disabled": {
                        backgroundColor: "#000 !important",
                    },
                },
                h5: {
                    color: CUSTOM_COLORS.colorPrimary,
                    fontSize: "1rem",
                    textTransform: "uppercase",
                    fontWeight: 600,
                },
                caption: {
                    fontSize: ".85rem",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    border: `2px solid ${CUSTOM_COLORS.colorPrimary}`,
                    backgroundColor: CUSTOM_COLORS.colorPrimary,
                    color: "#fff",
                    ":disabled": {
                        border: "2px solid #aaaaaa",
                        backgroundColor: "#cecece",
                        color: "gray",
                    },
                    ":hover": {
                        color: CUSTOM_COLORS.colorPrimary,
                    },
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    color: "#000",
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: "1rem",
                },
            },
        },
    },
});
