import { typographyStyles } from "./typography";
export const detailPageStyles = {
    cardContent: {
        flex: 1,
        minWidth: { xs: "100%", sm: "45%" },
        // display: "flex",
        // justifyContent: { xs: "center", md: "flex-start" },
    },
    card: {
        display: "flex",
        padding: "1rem 2rem",
        boxShadow: "2px 2px 2px 2px rgba(0,0,0,.2)",
        flexDirection: { xs: "column", md: "row" },
    },
    columnCard: {
        display: "flex",
        flexDirection: "column",
        padding: "1rem 2rem",
        boxShadow: "2px 2px 2px 2px rgba(0,0,0,.2)",
    },
    imageContainer: {
        backgroundColor: "rgba(0,0,0,0.85)",
    },
    icon: {
        fontSize: "1.5rem",
        marginRight: ".5rem",
        color: "rgba(12,53,130,0.88)",
    },
};
