import { Typography } from "@mui/material";
import { useTheme } from "@mui/material";

export const ValidationError = ({ message }: { message: String }) => {
    const { customColors } = useTheme();
    if (message) {
        return (
            <Typography color={customColors.colorError}>{message}</Typography>
        );
    }
    return (
        <Typography color={customColors.colorError}>
            Bitte korrigieren Sie die fehlerhaften Felder.
        </Typography>
    );
};

export const InputValidationError = ({ message }: { message?: String }) => {
    const { customColors } = useTheme();
    return (
        <Typography
            color={customColors.colorError}
            sx={{
                fontSize: "10px",
                margin: 0,
                padding: 0,
            }}
        >
            Dieses Feld ist fehlerhaft. {message && message}
        </Typography>
    );
};
