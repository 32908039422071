import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material'
import { type ReactNode } from 'react'

interface CustomLinkProps {
  children: ReactNode
  to: string
}

export const CustomLink = ({ children, to }: CustomLinkProps): JSX.Element => {
  const { customColors } = useTheme()
  return (
        <Link
            style={{
              textDecoration: 'none',
              color: customColors.colorText
            }}
            to={to}
        >
            {children}
        </Link>
  )
}
