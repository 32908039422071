export const typographyStyles = {
    card_title: {
        fontSize: { xs: ".9rem", md: "1.1rem", xl: "1.2rem" },
        fontWeight: 400,
        color: "#000 !important",
    },
    card_info: {
        marginInline: "1rem",
    },
    large: {
        fontSize: { xs: "1rem", md: "1.2rem", lg: "1.4rem", xl: "1.5rem" },
    },
};
