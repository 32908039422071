import React, { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { loginAPI, sendRecoveryEmailAPI } from "services/authentication";
import { getLocalizedLabels } from "services/translations";
import { ErrorDisplay, LABELS, LabeledTextInput, LogInForm } from "components";
import { Link, useNavigate } from "react-router-dom";
import { SuccessDisplay } from "components/shared/SuccessDisplay";

const PasswordRecoveryPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [successMsg, setSuccessMsg] = useState<string>("");
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setErrorMsg("");
        const { name, value } = e.target;
        if (name === "email") {
            setEmail(value);
        }
    };

    const sendRecoveryMail = async () => {
        setLoading((_) => true);
        const data = await sendRecoveryEmailAPI(email);
        if (data) setSuccessMsg(data.detail);
        if (!data) setErrorMsg("Die Email konnte nicht zugestellt werden.");

        setLoading((_) => false);
        setEmail((_) => "");
    };

    const labels = getLocalizedLabels(LABELS);

    return (
        <Box
            sx={{
                display: "flex",
                height: "100svh",
                justifyContent: "center",
                alignItems: { xs: "", md: "center" },
            }}
        >
            <Box
                sx={{
                    paddingBlock: "1rem",
                    display: "flex",
                    borderRadius: "4px",
                    background: "rgba(240,240,243)",
                    justifyContent: "center",
                    boxShadow: "2px 2px 8px rgba(0,0,0,0.4)",
                }}
            >
                <Box sx={{ maxWidth: "60%" }}>
                    <Box sx={{ mb: "1rem" }}>
                        <Typography variant="h5" className="account__title">
                            {labels.welcome}
                            <span
                                className="account__logo"
                                style={{ color: "blue" }}
                            >
                                Selbst-Vermarkten.de
                            </span>
                        </Typography>
                    </Box>
                    <Typography variant="body2" sx={{ mb: "1rem" }}>
                        Um ihr Passwort zurückzusetzen geben Sie bitte
                        nachfolgend ihre Email ein. Wir senden ihnen einen
                        Wiederherstellungslink zu.
                    </Typography>
                    <LabeledTextInput
                        label="Email"
                        value={email}
                        handleChange={handleChange}
                        placeholder="Email"
                        name="email"
                        fullWidth
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Button onClick={sendRecoveryMail}>Senden</Button>
                        {loading && <CircularProgress />}
                    </Box>
                    {successMsg && <SuccessDisplay msg={successMsg} />}
                    {errorMsg && <ErrorDisplay msg={errorMsg} />}
                    <Box sx={{ mt: "2rem" }}>
                        <Link to="/login">{"Hier geht's zum Login >"}</Link>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default PasswordRecoveryPage;
