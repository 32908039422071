import { type User } from "interfaces";

export const defaultUser: User = {
    username: "",
    first_name: "",
    last_name: "",
    account_verified: false,
    email: "",
    id: -1,
    mobile: "",
    email_notification_disabled: false,
};
