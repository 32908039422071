import { useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";

type SidebarDotProps = {
    bgColor: string | undefined;
    size?: string;
    alignSelfTop?: boolean;
    sx?: any;
};

export const Dot = ({ bgColor, size, alignSelfTop, sx }: SidebarDotProps) => {
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                height: size || "12px",
                width: size || "12px",
                borderRadius: "999px",
                backgroundColor: bgColor,
                border: bgColor ? `1px solid ${bgColor}` : "1px solid #000",
                alignSelf: alignSelfTop ? "flex-start" : "center",
                ...sx,
            }}
        ></Box>
    );
};
