import { Box, Typography } from "@mui/material";
interface ErrorDisplayProps {
    msg: string;
    color?: string;
}

export const ErrorDisplay = ({ msg, color = "red" }: ErrorDisplayProps) => {
    return (
        <Box
            sx={{
                width: "100%",
                backgroundColor: "#f8d7da",
                border: "1px solid #f5c6cb",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "3rem",
                padding: "0.75rem 1.25rem",
                borderRadius: "8px",
                marginBlock: "1rem",
            }}
        >
            <Typography variant="body2" sx={{ color: color || "#000" }}>
                {msg}
            </Typography>
        </Box>
    );
};
