import { type PropertyInput } from "@interfaces";
import { getLocalizedLabels } from "services";
import { LABELS } from "components/properties/constants";
import { HouseTypeOptions, AvailibilityOptions } from "enums";

const labels = getLocalizedLabels(LABELS);

// house
export const leftHouseInputs: PropertyInput[] = [
    {
        name: "plot_area",
        label: `${labels.plot_area} (m^2)*`,
        placeholder: "165",
        type: "number",
        isRequired: true,
    },
    {
        name: "room_count",
        label: labels.room_count + "*",
        placeholder: "4",
        type: "number",
        isRequired: true,
    },
    {
        name: "bathroom_count",
        label: labels.bathroom_count + "*",
        placeholder: "1",
        type: "number",
    },
    {
        name: "free_from",
        label: labels.free_from + "*",
        enumObject: AvailibilityOptions,
        type: "select",
        isRequired: true,
    },
    {
        name: "house_type",
        label: labels.house_type,
        enumObject: HouseTypeOptions,
        type: "select",
    },
];

export const rightHouseInputs: PropertyInput[] = [
    {
        name: "living_space",
        label: `${labels.living_space} (m^2)*`,
        placeholder: "120",
        type: "number",
        isRequired: true,
    },
    {
        name: "floor_count",
        label: labels.floor_count,
        placeholder: "3",
        type: "number",
    },
    {
        name: "year_of_construction",
        label: labels.year_of_construction + "*",
        placeholder: "2000",
        type: "number",
        isRequired: true,
    },
    {
        name: "free_from_date",
        label: labels.free_from_date,
        placeholder: "tt.mm.jjjj",
        type: "date",
    },
];
