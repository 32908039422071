import { type Labels } from 'interfaces'

export const LABELS: Labels = {
  username: {
    en: 'Email Address',
    de: 'E-Mail-Adresse'
  },
  password: {
    en: 'Password',
    de: 'Passwort'
  },
  forgetPassword: {
    en: 'Forgot a password ?',
    de: 'Passwort vergessen ?'
  },
  login: {
    en: 'Login',
    de: 'Einloggen'
  },
  register: {
    en: 'Not a member? Register now!',
    de: 'Kein Mitglied? Jetzt registrieren!'
  },
  stayLogged: {
    en: 'stay logged in ',
    de: 'Angemeldet bleiben'
  },
  welcome: {
    en: 'Welcome to ',
    de: 'Willkommen zu '
  },
  heading: {
    en: 'Real Estate made simple',
    de: 'Immobilien leicht gemacht'
  }
}
