import { Box, Button, useTheme } from "@mui/material";
import { getLocalizedLabels } from "services/translations";
import { LABELS } from "./constants";
import "scss/component/custom.css";
import { buttonStyles } from "components/styles/button";
import { TextInput } from "components/shared/form/TextInput";

interface BasicSearchProps {
    handleTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    searchPropertyHandler: () => void;
}

const BasicSearch = ({
    handleTextChange,
    searchPropertyHandler,
}: BasicSearchProps): JSX.Element => {
    const labels = getLocalizedLabels(LABELS);
    const { customColors } = useTheme();

    return (
        <Box
            sx={{
                border: "8px solid black",
                minHeight: "100dvh",
                backgroundImage: `linear-gradient(to bottom left, yellow,  ${customColors.colorPrimary}, blue)`,
            }}
        >
            <Box className="adjust">
                <h1 style={{ color: "#fff" }}>Selbst-Vermarkten</h1>
                <h4 style={{ color: "#fff" }}> {labels.subtitle}</h4>
                <Box
                    sx={{
                        marginBlock: "1rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "50%",
                    }}
                >
                    <TextInput
                        type="search"
                        handleChange={handleTextChange}
                        placeholder={labels.enterCity}
                        name="city"
                    />

                    <Button
                        type="submit"
                        value={labels.search}
                        onClick={searchPropertyHandler}
                        sx={{ ...buttonStyles.search, ml: "2rem" }}
                    >
                        Suche
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default BasicSearch;
