import { type PropertyInput } from "@interfaces";
import { getLocalizedLabels } from "services";
import { LABELS } from "components/properties/constants";
import { AvailibilityOptions, StateOfDevelopment } from "enums";

const labels = getLocalizedLabels(LABELS);
// plot
export const leftPlotInputs: PropertyInput[] = [
    {
        name: "plot_area",
        label: `${labels.living_space} (m^2)*`,
        placeholder: "120",
        type: "number",
    },
    {
        name: "free_from",
        label: labels.free_from + "*",
        enumObject: AvailibilityOptions,
        type: "select",
    },
];

export const rightPlotInputs: PropertyInput[] = [
    {
        name: "state_of_development",
        label: labels.state_of_development,
        enumObject: StateOfDevelopment,
        type: "select",
    },

    {
        name: "free_from_date",
        label: labels.free_from_date,
        placeholder: "tt.mm.jjjj",
        type: "date",
    },
];
