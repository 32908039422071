import { Box } from "@mui/material";
import { propertyFormStyles } from "components/styles";
import { usePropertyCreation } from "services/property-creation-context";
import { TwoColumnForm } from "components";
import { getLocalizedLabels } from "services";
import { LABELS } from "../../constants";
import { DisplayInput } from "components/shared/form/DisplayInput";
import { leftHouseInputs, rightHouseInputs } from "./step-2-house-inputs";
import { leftFlatInputs, rightFlatInputs } from "./step-2-flat-inputs";
import { leftPlotInputs, rightPlotInputs } from "./step-2-plot-inputs";
import { useParams } from "react-router-dom";
import { usePropertyEdit } from "services/property-edit-context";

export const StepTwoForm = () => {
    const labels = getLocalizedLabels(LABELS);
    const { id } = useParams();
    const { property, validationErrors } = id
        ? usePropertyEdit()
        : usePropertyCreation();

    if (property.property_type === "house") {
        return (
            <Box sx={propertyFormStyles.centeredContainer}>
                <TwoColumnForm
                    leftSide={
                        <Box>
                            {leftHouseInputs.map((input) => (
                                <DisplayInput key={input.name} {...input} />
                            ))}
                        </Box>
                    }
                    rightSide={
                        <Box>
                            {rightHouseInputs.map((input) => (
                                <DisplayInput key={input.name} {...input} />
                            ))}
                        </Box>
                    }
                />
            </Box>
        );
    }
    if (property.property_type === "flat") {
        return (
            <Box sx={propertyFormStyles.centeredContainer}>
                <TwoColumnForm
                    leftSide={
                        <Box>
                            {leftFlatInputs.map((input) => (
                                <DisplayInput key={input.name} {...input} />
                            ))}
                        </Box>
                    }
                    rightSide={
                        <Box>
                            {rightFlatInputs.map((input) => (
                                <DisplayInput key={input.name} {...input} />
                            ))}
                        </Box>
                    }
                />
            </Box>
        );
    }
    if (property.property_type === "plot") {
        return (
            <Box sx={propertyFormStyles.centeredContainer}>
                <TwoColumnForm
                    leftSide={
                        <Box>
                            {leftPlotInputs.map((input) => (
                                <DisplayInput key={input.name} {...input} />
                            ))}
                        </Box>
                    }
                    rightSide={
                        <Box>
                            {rightPlotInputs.map((input) => (
                                <DisplayInput key={input.name} {...input} />
                            ))}
                        </Box>
                    }
                />
            </Box>
        );
    }
    return <></>;
};
