import React, { useEffect, useState, useCallback } from "react";
import {
    Button,
    Grid,
    Card,
    CardContent,
    Box,
    Typography,
    CardMedia,
    CircularProgress,
} from "@mui/material";

import {
    FaEuroSign,
    FaExternalLinkAlt,
    FaFileWord,
    FaDoorOpen,
    FaClock,
    FaCar,
} from "react-icons/fa";
import {
    formatDate,
    handoverToAgentAPI,
    updatePropertyAPI,
    getLocalizedLabels,
    formatPrice,
    createExtraServicesAPI,
} from "services";
import { typographyStyles } from "components/styles";

import { LocationOnOutlined } from "@mui/icons-material";
import moment from "moment";
import { ExtraServicesForm } from "./PropertyExtraServicesForm";
import buyImg from "assets/images/buy.png";
import rentImg from "assets/images/rent.png";
import immoNetImg from "assets/images/App-Immonet.png";
import immoScoutImg from "assets/images/App-Immoscout24.png";
import immoWeltImg from "assets/images/App-Immowelt.png";

import { useNavigate } from "react-router-dom";
import { LABELS } from "./constants";
import { type Property } from "@interfaces";
import "./style.css";
import "scss/component/custom.css";
import { buttonStyles } from "components/styles/button";

import { CardRowItem, CardRow } from "./BasicComponents";
import {
    DisplayDialog,
    type DisplayDialogProps,
    defaultDisplayDialog,
} from "components/shared/DisplayDialog";
import { type ChangeInputEvent } from "@types";
import { isAxiosError } from "axios";
import { defaultPropertyImageUrl } from "services/img-utils";

interface PrivatePropertySummaryProps {
    property: Property;
    isOwner: boolean;
    refetchProperties: () => Promise<void>;
}

export const PrivatePropertySummary = ({
    property,
    isOwner,
    refetchProperties,
}: PrivatePropertySummaryProps) => {
    const navigate = useNavigate();
    const labels = getLocalizedLabels(LABELS);
    const [packageValidityText, setPackageValidityText] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [dialog, setDialog] =
        useState<DisplayDialogProps>(defaultDisplayDialog);
    const [extraServices, setExtraServices] = useState<number[]>([]);

    const calculateRemainingPackageTime = useCallback(() => {
        if (!property.package_end_date) return 0;
        const now = moment(new Date()); // todays date
        const end = moment(property.package_end_date); // another date
        const duration = moment.duration(end.diff(now));
        const totalMinutes = duration.asMinutes();
        return totalMinutes;
    }, [property.package_end_date]);

    const calculatePackageValidity = useCallback(() => {
        const package_end_date = property.package_end_date;
        if (!package_end_date) {
            return;
        }
        const totalMinutes = calculateRemainingPackageTime();
        let remainingMinutes;

        const days = Math.floor(totalMinutes / (60 * 24));
        remainingMinutes = totalMinutes - days * 60 * 24;
        const hours = Math.floor(remainingMinutes / 60);
        remainingMinutes -= hours * 60;
        const mins = Math.floor(remainingMinutes);
        remainingMinutes -= mins;
        let packageValidityText = `Verbleibende Laufzeit: ${days} Tage, ${hours} Std. 
        ${mins} Min ${(remainingMinutes * 60).toFixed(0)}s`;
        if (totalMinutes <= 0) {
            packageValidityText = "Marketing package has expired";
        }
        setPackageValidityText(packageValidityText);
    }, [calculateRemainingPackageTime, property.package_end_date]);

    useEffect(() => {
        calculatePackageValidity();
    }, [property.package_end_date, calculatePackageValidity]);

    const imageUrl =
        property.images && property.images.length > 0
            ? property.images[0].url
            : defaultPropertyImageUrl;


    const togglePropertyActiveState = async () => {
        setLoading((_) => true);
        if (!property.id) return;
        await updatePropertyAPI(property.id, {
            ...property,
            is_active: !property.is_active,
        });
        await refetchProperties();
        setLoading((_) => false);
    };

    const archiveProperty = async () => {
        setLoading((_) => true);
        if (!property.id) return;
        await updatePropertyAPI(property.id, {
            ...property,
            archived: true,
            is_active: false,
        });
        handleCloseDialog();
        await refetchProperties();
        setLoading((_) => false);
    };

    const requestServices = async (extraServices: number[]) => {
        if (!property.id) return;
    };
    const handleCloseDialog = () => {
        setDialog(defaultDisplayDialog);
    };

    const openArchiveDialog = () => {
        setDialog((prev) => ({
            ...prev,
            isOpen: true,
            id: "archive",
            confirmElement: (
                <Button
                    sx={buttonStyles.privateProperty}
                    onClick={async () => {
                        await archiveProperty();
                    }}
                >
                    {loading && <CircularProgress sx={{ mr: "1rem" }} />}
                    Ja, archivieren
                </Button>
            ),
            title: "Bestätigung erforderlich",
            subtitle:
                "Möchten Sie wirklich das Inserat archivieren ? Sie können es danach nicht wieder online stellen.",
            onClose: handleCloseDialog,
        }));
    };

    const handleExtraServicesChange = (e: ChangeInputEvent, saleId: number) => {
        if (extraServices.includes(saleId)) {
            const newServicesArray = extraServices.filter(
                (service) => service !== saleId,
            );
            setExtraServices(newServicesArray);
        } else {
            setExtraServices((prev) => [...prev, saleId]);
        }
    };

    const openServicesDialog = () => {
        setDialog((prev) => ({
            ...prev,
            isOpen: true,
            id: "services",
            confirmElement: (
                <Button
                    sx={buttonStyles.privateProperty}
                    onClick={() => requestServices(extraServices)}
                    disabled={loading}
                >
                    {loading && <CircularProgress />}
                    Unverbindlich Anfragen
                </Button>
            ),
            title: "Services",
            subtitle:
                "Lassen Sie uns wissen von welchen Zusatzangeboten Sie gerne gebrauch machen möchten.",
            onClose: handleCloseDialog,
        }));
    };
    const requestSalesHelp = async () => {
        if (!property.id) return;
        const res = await handoverToAgentAPI(property.id);
        if (!isAxiosError(res)) {
            alert(
                "Vielen Dank! Wir haben Ihre Anfrage erhalten und werden uns in Kürze bei Ihnen melden!",
            );
            handleCloseDialog();
            await refetchProperties();
        }
    };

    const openSalesHelpDialog = () => {
        setDialog((prev) => ({
            ...prev,
            isOpen: true,
            id: "sales-help",
            confirmElement: (
                <Button
                    sx={{ ...buttonStyles.privateProperty }}
                    onClick={requestSalesHelp}
                >
                    Unverbindlich Anfragen
                </Button>
            ),
            title: "Hilfe beim Verkauf",
            subtitle:
                "Bestätigen Sie nachfolgend, dass Sie Hilfe beim Verkauf anfordern möchten. Wir werden uns darauf hin in kürze bei Ihnen melden.",
            onClose: handleCloseDialog,
        }));
    };

    return (
        <Grid item xs={12} sm={6}>
            <Card
                sx={{
                    padding: "1rem",
                    position: "relative",
                    boxShadow: " 1px 4px 8px #aeaeae",
                    mb: "1rem",
                    cursor: "pointer",
                    "&:hover": {
                        boxShadow: " 1px 8px 12px #aeaeae",
                    },
                }}
            // onClick={() => navigate(`/property/detail/${property.id}`)}
            >
                <Box
                    sx={{
                        position: "absolute",
                        display: "flex",
                        top: "0rem",
                        alignItems: "center",
                        fontWeight: 700,
                        right: "-.2rem",
                    }}
                >
                    <Box sx={{ mr: "1rem" }}>
                        <img
                            alt="Immonet"
                            src={immoNetImg}
                            className="img-welt"
                        />
                        <img
                            alt="ImmoScout"
                            src={immoScoutImg}
                            className="img-welt"
                        />
                        <img
                            alt="ImmoWelt"
                            src={immoWeltImg}
                            className="img-welt"
                        />
                    </Box>
                    <Box sx={{ mr: "8rem" }}>
                        {calculateRemainingPackageTime() > 0
                            ? packageValidityText
                            : "abgelaufen"}
                    </Box>

                    <img
                        alt="transaction"
                        src={
                            property.transaction_type === "sell"
                                ? buyImg
                                : rentImg
                        }
                        style={{
                            position: "absolute",
                            maxWidth: "100px",
                            top: "-2px",
                            right: "0",
                        }}
                    />
                </Box>

                <CardContent>
                    <h3>{property.title}</h3>
                    <Box sx={{ display: "flex", mb: "1rem" }}>
                        {property.archived && (
                            <Typography
                                sx={{
                                    padding: "1rem",
                                    backgroundColor: "#cecece",
                                    width: "150px",
                                    display: "flex",
                                    justifyContent: "center",
                                    mr: "1rem",
                                }}
                            >
                                Archiviert
                            </Typography>
                        )}
                        {!property.is_active ? (
                            <Typography
                                sx={{
                                    padding: "1rem",
                                    backgroundColor: "#cecece",
                                    width: "150px",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                Deaktiviert
                            </Typography>
                        ) : (
                            <Typography
                                sx={{
                                    padding: "1rem",
                                    backgroundColor: "green",
                                    color: "#fff",
                                    width: "150px",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                Aktiv
                            </Typography>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: {
                                xs: "column",
                                md: "row",
                            },
                        }}
                    >
                        <CardMedia
                            component="img"
                            image={imageUrl}
                            alt="PropertyView"
                            sx={{
                                maxWidth: { xs: "100%", md: "150px" },
                                maxHeight: "150px",
                                borderRadius: "4px",
                            }}
                        />

                        <Box
                            sx={{
                                flex: 1,
                            }}
                        >
                            {/* Title Row */}
                            <CardRow sx={{ mb: "1rem", ml: 0 }}>
                                <LocationOnOutlined
                                    sx={{
                                        fontSize: {
                                            xs: "12px",
                                            sm: "16px",
                                            md: "32px",
                                        },
                                    }}
                                />
                                <Typography sx={typographyStyles.card_title}>
                                    {`${property.city}, ${property.post_code}, ${property.country}`}
                                </Typography>
                            </CardRow>
                            <CardRow>
                                <CardRowItem>
                                    <FaEuroSign />{" "}
                                    {property.transaction_type === "sell"
                                        ? "Kaufpreis"
                                        : "Mietpreis"}
                                    :{" "}
                                    {formatPrice(
                                        property.price ||
                                        property.base_rent ||
                                        0,
                                    )}
                                </CardRowItem>{" "}
                                <CardRowItem>
                                    <FaExternalLinkAlt /> Wohnfläche:{" "}
                                    {property.living_space}
                                </CardRowItem>
                            </CardRow>
                            <CardRow>
                                <CardRowItem>
                                    <FaFileWord /> Listing-ID:
                                    {property.id ? 1000 + property.id : "N/A"}
                                </CardRowItem>{" "}
                                <CardRowItem>
                                    <FaDoorOpen /> Anzahl Zimmer:{" "}
                                    {property.room_count}
                                </CardRowItem>
                            </CardRow>
                            <CardRow>
                                <CardRowItem>
                                    <FaClock /> Erstellt:{" "}
                                    {formatDate(property.created_on)}
                                </CardRowItem>{" "}
                                <CardRowItem>
                                    <FaCar /> Anzahl Stellplätze:{" "}
                                    {property.garage_count}
                                </CardRowItem>
                            </CardRow>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        {!property.archived && (
                            <Button
                                sx={{
                                    ...buttonStyles.privateProperty,
                                    flex: 1,
                                    // display: property.archived ? "none" : "block",
                                }}
                                disabled={loading || (!property.is_active && !property.is_paid)}
                                onClick={togglePropertyActiveState}
                            >
                                {loading && (
                                    <CircularProgress
                                        sx={{
                                            mr: "1rem",
                                        }}
                                    />
                                )}
                                {property.is_active
                                    ? labels.deactivate
                                    : labels.activate}
                            </Button>
                        )}
                        <Button
                            sx={{
                                ...buttonStyles.privateProperty,
                                flex: 1,

                                display: property.archived ? "none" : "block",
                            }}
                            disabled={property.archived}
                            onClick={() =>
                                navigate(`/property/edit/${property.id}`)
                            }
                        >
                            {labels.edit}
                        </Button>
                        <Button
                            sx={{
                                ...buttonStyles.privateProperty,
                                flex: 1,
                                display: property.archived ? "none" : "block",
                            }}
                            disabled={property.archived}
                            onClick={openArchiveDialog}
                        >
                            {labels.archive}
                        </Button>

                        <Button
                            sx={{
                                ...buttonStyles.privateProperty,
                                flex: 1,
                                display: property.archived ? "none" : "block",
                            }}
                            disabled={property.archived}
                            onClick={openServicesDialog}
                        >
                            Services
                        </Button>
                        <Button
                            sx={{
                                ...buttonStyles.privateProperty,
                                flex: 1,
                                display: property.archived ? "none" : "block",
                            }}
                            disabled={
                                property.archived ||
                                property.agent_handover_requested
                            }
                            onClick={openSalesHelpDialog}
                        >
                            {property.agent_handover_requested
                                ? "Hilfe bereits angefordert"
                                : "Hilfe beim Verkauf"}
                        </Button>
                        <Button
                            sx={{
                                ...buttonStyles.privateProperty,

                                flex: 1,
                                maxWidth: property.archived ? "1px" : "none",
                            }}
                            onClick={() => {
                                navigate(`/property/detail/${property.id}`);
                            }}
                        >
                            Details Anzeigen
                        </Button>
                    </Box>
                </CardContent>

                <DisplayDialog
                    {...dialog}
                    content={
                        dialog.id === "services" && (
                            <ExtraServicesForm
                                extraServices={extraServices}
                                handleCheckboxChange={handleExtraServicesChange}
                            />
                        )
                    }
                />
            </Card>
        </Grid>
    );
};
