export const imageUploaderStyles = {
  uploader: {
    margin: 'auto',
    border: '2px solid gray',
    display: 'flex',
    alignItems: 'center',
    width: '800px',
    justifyContent: 'center',
    height: '100px',
    cursor: 'pointer',
    backgroundColor: 'rgba(222,222,222,1)',

    '&:hover': {
      backgroundColor: 'rgba(222,222,222,.7)',
      color: '#fff'
    },
    mb: '2rem'
  },
  img: {
    border: '1px solid #cecece',
    padding: '4px',
    width: '125px',
    height: '125px',
    borderRadius: '.4rem',
    marginInline: '1rem',
    marginBlock: '.5rem',
    boxShadow: '6px 6px 12px 2px rgba(20,20,20, 0.25)',
    transition: '300ms'
  },
  indexDisplay: {
    color: '#fff',
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: 99,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translate(62.5px, 1.25rem)',
    zIndex: 999
  },
  iconButton: {
    padding: 0,
    transition: '300ms',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.25)'
    }
  }
}
