import { EnergyPassportOptions, TransactionType } from "enums";
import { Property } from "@interfaces";
import { usePropertyCreation } from "./property-creation-context";
import { useParams } from "react-router-dom";
import { usePropertyEdit } from "./property-edit-context";

export const validateStepOne = (property: Property) => {
    const errors = [];
    if (!property.property_type) {
        errors.push("property_type");
    }
    if (!property.transaction_type) {
        errors.push("transaction_type");
    }
    return errors;
};

const getPropertyTypes = (
    property: Property,
): {
    isPlot: boolean;
    isFlat: boolean;
    isHouse: boolean;
    isHouseOrFlat: boolean;
} => {
    const isPlot = property.property_type === "plot";
    const isFlat = property.property_type === "flat";
    const isHouse = property.property_type === "house";
    const isHouseOrFlat =
        property.property_type === "house" || property.property_type === "flat";
    return { isPlot, isHouse, isFlat, isHouseOrFlat };
};

export const validateStepTwo = (property: Property) => {
    const errors = [];
    const { isHouseOrFlat } = getPropertyTypes(property);
    if (
        (!property.plot_area && property.property_type === "house") ||
        (!property.plot_area && property.property_type === "plot")
    ) {
        errors.push("plot_area");
    }
    if (!property.living_space && isHouseOrFlat) errors.push("living_space");
    if (isHouseOrFlat && !property.bathroom_count)
        errors.push("bathroom_count");
    if (!property.room_count && isHouseOrFlat) errors.push("room_count");
    if (
        (!property.year_of_construction && isHouseOrFlat) ||
        (Number(property.year_of_construction) > 9999 && isHouseOrFlat)
    )
        errors.push("year_of_construction");
    if (!property.free_from) errors.push("free_from");
    if (property.free_from === "fix_date" && !property.free_from_date)
        errors.push("free_from_date");
    console.error("validate step two error", errors);
    return errors;
};

export const validateStepThree = (property: Property) => {
    const errors = [];
    const { isHouseOrFlat } = getPropertyTypes(property);
    if (!property.street) errors.push("street");
    if (!property.post_code || property.post_code.toString().length !== 5)
        errors.push("post_code");
    if (!property.country) errors.push("country");
    if (!property.house_number) errors.push("house_number");
    if (!property.city) errors.push("city");

    return errors;
};

export const validateStepFour = (property: Property) => {
    const errors = [];
    const { isHouseOrFlat } = getPropertyTypes(property);
    if (!property.energy_certificate && isHouseOrFlat)
        errors.push("energy_certificate");
    if (
        property.energy_certificate ===
            EnergyPassportOptions["Ja, Liegt Vor"] &&
        isHouseOrFlat
    ) {
        if (!property.energy_date_of_creation)
            errors.push("energy_date_of_creation");
        if (!property.energy_year_of_construction)
            errors.push("energy_year_of_construction");
        if (!property.energy_card_type) errors.push("energy_card_type");
        if (!property.energy_need) errors.push("energy_need");
    }
    return errors;
};

export const validateStepFive = (property: Property) => {
    const errors = [];
    if (!property.price && property.transaction_type === TransactionType.Kaufen)
        errors.push("price");
    if (property.transaction_type === TransactionType.Mieten) {
        if (!property.base_rent) errors.push("base_rent");
        // if (!property.additional_price) errors.push("additional_price");
    }
    return errors;
};

export const validateStepSix = (property: Property) => {
    return [];
};
export const validateStepSeven = (property: Property) => {
    const errors = [];
    const errorMessages: { [key: string]: string } = {};
    if (!property.title) errors.push("title");
    if (property.title.length > 100) {
        errors.push("title_max_length");
        errorMessages["title"] =
            "Die Überschrift der Anzeige darf eine Länge von 100 Zeichen nicht überschreiten.";
    }
    if (!property.description) errors.push("description");
    return { errors, errorMessages };
};

export const validateStepEight = (property: Property) => {
    const errors = [];
    if (!property.name) errors.push("name");
    return errors;
};

export const validateStepTwelve = (property: Property) => {
    const errors = [];
    if (!property.invoice_first_name) errors.push("invoice_first_name");
    if (!property.invoice_last_name) errors.push("invoice_last_name");
    if (!property.invoice_street) errors.push("invoice_street");
    // if (!property.invoice_company) errors.push("invoice_company");
    if (!property.invoice_postcode) errors.push("invoice_postcode");
    if (!property.invoice_country) errors.push("invoice_country");
    if (!property.plan) errors.push("plan");
    if (!property.invoice_city) errors.push("invoice_city");
    return errors;
};
