import { type PropertyType, type TransactionType } from "@enums";
import { type Property, type PropertyInput } from "@interfaces";

const hasPropertyTypeMatch = (
    allowedPropertyTypes: PropertyType[] | undefined,
    currentPropertyType: PropertyType | undefined,
): boolean => {
    if (currentPropertyType == null) return false;
    if (!allowedPropertyTypes || allowedPropertyTypes.length === 0) return true;
    if (allowedPropertyTypes.includes(currentPropertyType)) return true;
    return false;
};

const hasTransactionTypeMatch = (
    allowedTransactionType: TransactionType | undefined,
    currentTransactionType: TransactionType | undefined,
) => {
    if (currentTransactionType == null) return false;
    if (allowedTransactionType == null) return true;
    if (allowedTransactionType === currentTransactionType) return true;
    return false;
};
export const isVisible = (
    input: PropertyInput,
    property: Property,
): boolean => {
    const { transaction_type, property_type } = property;
    const matchesTransactionType = hasTransactionTypeMatch(
        input.transaction_type,
        transaction_type,
    );
    const matchesPropertyType = hasPropertyTypeMatch(
        input.allowed_property_types,
        property_type,
    );
    const isVisible =
        hasTransactionTypeMatch(input.transaction_type, transaction_type) &&
        hasPropertyTypeMatch(input.allowed_property_types, property_type);

    return isVisible;
};
