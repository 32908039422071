import { type PropertyInput } from "@interfaces";
import { getLocalizedLabels } from "services";
import { LABELS } from "components/properties/constants";
import { PropertyType, TransactionType } from "enums";
import { LabelInfoIcon } from "components/shared/form/ToolTipIcon";
import { Typography, Box } from "@mui/material";

import { labelStyles } from "components/shared/form/labelStyles";

const labels = getLocalizedLabels(LABELS);

// flat
export const leftInputs: PropertyInput[] = [
    {
        name: "price",
        label: `${labels.price}*`,
        placeholder: "120,000\u20AC",
        type: "number",
        transaction_type: TransactionType.Kaufen,
    },
    {
        name: "base_rent",
        label: `${labels.base_rent}*`,
        placeholder: "900\u20AC",
        type: "number",
        transaction_type: TransactionType.Mieten,
    },
    {
        name: "garage_count",
        label: labels.garage_count,
        placeholder: "1",
        type: "number",
        allowed_property_types: [PropertyType.Haus, PropertyType.Wohnung],
    },
    {
        name: "deposit",
        label: labels.deposit,
        placeholder: "Kaution in €",
        type: "number",
        transaction_type: TransactionType.Mieten,
        allowed_property_types: [PropertyType.Haus, PropertyType.Wohnung],
    },
];

export const rightInputs: PropertyInput[] = [
    {
        name: "additional_price",
        label: (
            <Box sx={{ ...labelStyles.wrapper }}>
                <Typography variant="caption">
                    {labels.additional_price}
                </Typography>
                <LabelInfoIcon text="Jede Immobilie kostet im Unterhalt Geld. Hierzu zählen: Betriebskosten, Heizkosten, Hausmeister, Wohngebäudeversicherung, ..." />
            </Box>
        ),
        placeholder: "400\u20AC",
        type: "number",
        allowed_property_types: [PropertyType.Haus, PropertyType.Grundstück],
    },
    {
        name: "additional_price",
        label: (
            <Box sx={{ ...labelStyles.wrapper }}>
                <Typography variant="caption">
                    {labels.additionalRentCost}
                </Typography>
            </Box>
        ),
        placeholder: "400\u20AC",
        type: "number",
        allowed_property_types: [PropertyType.Wohnung],
    },

    {
        name: "garage_price",
        label: `${labels.garage_price}`,
        placeholder: "8000\u20AC",
        type: "number",
        transaction_type: TransactionType.Kaufen,
        allowed_property_types: [PropertyType.Haus, PropertyType.Wohnung],
    },
    {
        name: "garage_price",
        label: `${labels.garageRentCost}`,
        placeholder: "200\u20AC",
        type: "number",
        transaction_type: TransactionType.Mieten,
        allowed_property_types: [PropertyType.Haus, PropertyType.Wohnung],
    },
];

// Das Hausgeld deckt die monatlichen Nebenkosten der Immobilie ab. Das Hausgeld setzt sich aus den Heiz-, Wasser- und Betriebskosten zusammen.
