import axios from "axios";
import { AxiosError } from "axios";
import { BASE_API_URL } from "./constants";
import {
    getWithHeader,
    patchWithHeader,
    deleteMultipleWithHeader,
} from "./base.api";
import { MessageAPIResponse } from "interfaces/message.interface";

export const sendMailAPI = async () => {
    const url = `${BASE_API_URL}/common/send-test-mail/`;
};

export const getMessagesAPI = async (
    query: string | undefined,
): Promise<MessageAPIResponse | undefined> => {
    let url = `${BASE_API_URL}/properties/messages/`;
    if (query && query !== "") url += `?${query}`;
    const res = await getWithHeader<MessageAPIResponse>(url);
    if (res) return res;
};

export const getMessageDetailAPI = async (id: string) => {
    let url = `${BASE_API_URL}/properties/messages/${id}/`;
    const res = await getWithHeader<any>(url);
    if (res) return res;
};

export const getToggleReadAPI = async (id: string) => {
    let url = `${BASE_API_URL}/properties/messages/${id}/toggle-read`;
    const res = await getWithHeader<any>(url);
    if (res) return res;
};

export const updateCategoryAPI = async <T,>(id: string, payload: T) => {
    let url = `${BASE_API_URL}/properties/messages/${id}/`;
    const res = await patchWithHeader<any>(url, payload);
    if (res) return res;
};

export const deleteMultipleMessagesAPI = async <T,>(ids: number[]) => {
    let url = `${BASE_API_URL}/properties/messages/delete-multiple`;
    const res = await deleteMultipleWithHeader(url, ids);
    return res;
};
