export const filterCardStyles = {
  position: 'relative !important',
  display: 'flex !important',
  flexDirection: 'column !important',
  minWidth: '0 !important',
  wordWrap: 'break-word !important',
  backgroundColor: '#fff !important',
  backgroundClip: 'border-box !important',
  border: '1px solid rgba(0, 0, 0, 0.125) !important',
  borderRadius: '0.25rem !important',
  width: 'auto'
}
