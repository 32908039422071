import { Box, Typography, useTheme } from '@mui/material'
import { propertyFormStyles } from 'components/styles'
import { usePropertyCreation } from 'services/property-creation-context'
import { CheckboxInput } from 'components'
import { getLocalizedLabels } from 'services'
import { LABELS } from '../../constants'
import { type Property } from '@interfaces'
import {
  FaCameraRetro,
  FaFileAlt,
  FaRulerCombined,
  FaFileContract,
  FaFileImport,
  FaShareSquare
} from 'react-icons/fa'

export const StepElevenForm = () => {
  const { customColors } = useTheme()
  const labels = getLocalizedLabels(LABELS)
  const { property, handleCheckboxChange } = usePropertyCreation()

  const extraSalesArray = [
    {
      field: 'professional_photo',
      label: 'Professionelle Fotos',
      icon: <FaCameraRetro />
    },
    { field: 'deed', label: 'Grundbuchauszug', icon: <FaFileAlt /> },
    {
      field: 'floor_plan',
      label: 'Grundrisserstellung',
      icon: <FaRulerCombined />
    },
    {
      field: 'professional_contract_review',
      label: 'Professionelle Vertragsprüfung',
      icon: <FaFileContract />
    },
    {
      field: 'export_property',
      label: 'Exposé für Ihre Immobilie',
      icon: <FaFileImport />
    },
    {
      field: 'ad_optimization',
      label: 'Inseratsoptimierung',
      icon: <FaShareSquare />
    }
  ]

  return (
        <Box sx={propertyFormStyles.centeredContainer}>
            <Box>
                <Typography>
                    An welchen Dienstleistungen wären Sie interessiert? Gerne
                    lassen wir für Sie ein <b>unverbindliches Angebot</b>{' '}
                    erstellen.
                </Typography>
            </Box>
            <Box
                sx={{
                  width: { xs: '100%', md: '50%' },
                  display: 'flex',
                  flexWrap: 'wrap',
                  paddingBlock: '2rem'
                }}
            >
                {extraSalesArray.map((sale) => {
                  return (
                        <Box
                            key={sale.field}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              height: '60px',
                              width: { xs: '100%', md: '45%' }
                            }}
                        >
                            <i
                                style={{
                                  borderRadius: '30px',
                                  boxShadow: '0px 0px 2px #888',
                                  padding: '0.3em 0.57em',
                                  marginRight: '.5rem',
                                  transform: 'translateY(3px)'
                                }}
                            >
                                {sale.icon}
                            </i>
                            <CheckboxInput
                                labelColor={customColors.colorPrimary}
                                name={sale.field}
                                label={sale.label}
                                checked={
                                    property[
                                      sale.field as keyof Property
                                    ] as boolean
                                }
                                handleChange={handleCheckboxChange}
                            />
                        </Box>
                  )
                })}
            </Box>
        </Box>
  )
}
