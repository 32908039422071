import HelpIcon from '@mui/icons-material/Help'
import { Tooltip } from '@mui/material'

interface ToolTipIconProps {
  text: string
  iconSize?: 'small' | 'medium' | 'large'
  fontSize?: string
}

export const ToolTipIcon = ({
  text,
  iconSize,
  fontSize = '1rem'
}: ToolTipIconProps) => {
  return (
        <Tooltip title={text} placement="right-start" arrow>
            <HelpIcon
                style={{ marginLeft: '.25rem' }}
                fontSize={iconSize || 'medium'}
            />
        </Tooltip>
  )
}

export const LabelInfoIcon = ({
  text,
  iconSize,
  fontSize = '1rem'
}: ToolTipIconProps) => {
  return (
        <Tooltip title={text} placement="right-start" arrow>
            <HelpIcon
                style={{ marginLeft: '.25rem' }}
                fontSize={iconSize || 'medium'}
                sx={{ fontSize: '1.15rem' }}
            />
        </Tooltip>
  )
}
