export const inputStyles = {
    height: "44px",
    outline: 0,
    border: "1px solid gray",
    paddingLeft: "10px",
    borderRadius: "4px",
    cursor: "pointer",
};

export const labelledInputStyles = {
    wrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flex: 1,
        minWidth: "45%",
    },
};
