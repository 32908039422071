import { useEffect } from "react";
import { Box, CardMedia } from "@mui/material";
import { usePropertyCreation } from "services/property-creation-context";
import { SelectButton } from "components";
import { getPropertyAPI, getPublicPropertyAPI, useApp } from "services";
import { Carousel } from "react-responsive-carousel";
import { detailPageStyles } from "components/styles";

import { useLocation, useParams } from "react-router-dom";
import { usePropertyEdit } from "services/property-edit-context";

export const ImageCarousel = () => {
    const { id } = useParams();
    const location = useLocation();
    const isCreationPage = location.pathname.includes("new");
    const { user } = useApp();
    const { previewImages, setCurrentStep } = id
        ? usePropertyEdit()
        : usePropertyCreation();
    const { property, setProperty } = usePropertyEdit();

    const styles = {
        // same as StepTenForm rows
        row: {
            padding: "1rem 2rem",
            boxShadow: "2px 2px 2px 2px rgba(0,0,0,.2)",
            backgroundColor: "#fff",
        },
        cardMedia: {
            border: "4px solid red",
            maxWidth: { xs: "100%", md: "50%" },
            maxHeight: "500px",
            objectFit: "contain",
        },
    };

    useEffect(() => {
        if (!isCreationPage) {
            const fetchPropertyToEdit = async () => {
                if (!id) return;
                const res =
                    !location.pathname.includes("public") && user?.id
                        ? await getPropertyAPI(id)
                        : await getPublicPropertyAPI(id);
                setProperty(res);
            };
            fetchPropertyToEdit();
        }
    }, [isCreationPage]);

    return isCreationPage ? (
        <>
            {previewImages && previewImages.length > 0 ? (
                <Carousel
                    className={"custom-carousel"}
                    showThumbs={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    interval={5000}
                >
                    {previewImages.map((image, idx) => {
                        return image.previewUrl ? (
                            <Box
                                key={image.id}
                                style={detailPageStyles.imageContainer}
                            >
                                <CardMedia
                                    component="img"
                                    image={image.previewUrl}
                                    alt={`Preview ${idx}`}
                                    sx={{
                                        ...styles.cardMedia,
                                    }}
                                />
                            </Box>
                        ) : (
                            <div>Bild derzeit nicht verfügbar</div>
                        );
                    })}
                </Carousel>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "75px",
                        background: "#cecece",
                    }}
                >
                    Aktuell keine Bilder hinzugefügt
                    <SelectButton
                        label="Jetzt Auswählen >"
                        handleClick={() => {
                            setCurrentStep(8);
                        }}
                    />
                </Box>
            )}
        </>
    ) : (
        <>
            {property?.images && property.images.length > 0 ? (
                <Carousel
                    className={"custom-carousel"}
                    showThumbs={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    interval={10000}
                >
                    {property.images.map((image, idx) => {
                        return image ? (
                            <Box
                                key={image.id}
                                sx={detailPageStyles.imageContainer}
                            >
                                <CardMedia
                                    component="img"
                                    src={image.url}
                                    alt={`Preview ${idx}`}
                                    sx={{
                                        ...styles.cardMedia,
                                    }}
                                />
                            </Box>
                        ) : (
                            <div>Bild derzeit nicht verfügbar</div>
                        );
                    })}
                </Carousel>
            ) : (
                <Box
                    sx={{
                        ...styles.row,
                        display: "flex",
                        justifyContent: "center",
                        backgroudColor: "#fff",
                    }}
                >
                    Aktuell stehen für diese Immobilie keine Bilder zur
                    Verfügung.
                </Box>
            )}
        </>
    );
};
