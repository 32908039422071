import { LABELS } from "./constants";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAlt";
import {
    FormControlLabel,
    Checkbox,
    CardContent,
    Button,
    Box,
    Grid,
    Typography,
} from "@mui/material";
import { SearchSelectInput, SearchTextInput } from "components";
import { getLocalizedLabels } from "services";
import { filterCardStyles } from "components/styles/filter-card";
import { type ChangeEvent } from "react";
import { TransactionType, PropertyType } from "enums";
import { buttonStyles } from "components/styles/button";
import { useSearchParams } from "react-router-dom";

interface PropertyFilterProps<T extends string> {
    showFilter: boolean;
    filterHandler: () => void;
    toggleShowFilter: () => void;
    handleTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleCheckboxChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (selectedOption: T, name: string) => void;
}

export const PropertyFilter = ({
    showFilter,
    toggleShowFilter,
    filterHandler,
    handleTextChange,
    handleCheckboxChange,
    handleSelectChange,
}: PropertyFilterProps<any>) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const labels = getLocalizedLabels(LABELS);

    const propertyEquipmentFilters = [
        "balcony",
        "patio",
        "winter_garden",
        "garden",
        "rooftop_terrace",
        "elevator",
        "full_bath",
        "shower_room",
        "sauna",
        "swimming_pool",
        "fitted_kitchen",
        "chimney",
        "barrier_free",
    ];

    const propertyConditionFilters = [
        "new_building",
        "old_building",
        "refurbished",
        "renovated",
        "in_need_of_renovation",
    ];

    return (
        <CardContent sx={filterCardStyles}>
            <Typography sx={{ marginBlock: ".5rem" }} variant="h4">
                Ihre Immobiliensuche
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                }}
            >
                <FilterAltOutlinedIcon
                    fontSize="large"
                    sx={{ color: "#0c3582e1" }}
                />
                <Button
                    sx={{
                        unset: "all",
                        textDecoration: "underline",
                    }}
                    onClick={toggleShowFilter}
                >
                    {showFilter ? "Filter Minimieren" : "Filter Anzeigen"}
                </Button>
            </Box>
            {showFilter ? (
                <>
                    <b>Allgemeines</b>
                    <Grid container>
                        <SearchSelectInput
                            name="property_type"
                            enumObject={PropertyType}
                            handleSelectChange={handleSelectChange}
                        />
                        <SearchSelectInput
                            name="transaction_type"
                            enumObject={TransactionType}
                            handleSelectChange={handleSelectChange}
                        />
                        <SearchTextInput
                            type="text"
                            name="street"
                            placeholder="Straße"
                            handleChange={handleTextChange}
                        />
                        <SearchTextInput
                            type="text"
                            name="city"
                            placeholder="Stadt"
                            handleChange={handleTextChange}
                        />
                        <SearchTextInput
                            type="number"
                            name="price"
                            placeholder="Preis bis"
                            handleChange={handleTextChange}
                        />
                        <SearchTextInput
                            type="text"
                            name="post_code"
                            placeholder="Postleitzahl"
                            handleChange={handleTextChange}
                        />
                        <SearchTextInput
                            type="number"
                            name="room_count"
                            placeholder="Zimmer bis"
                            handleChange={handleTextChange}
                        />
                        <SearchTextInput
                            type="text"
                            name="keyword"
                            placeholder="Stichwörter"
                            handleChange={handleTextChange}
                        />
                    </Grid>

                    <b>Ausstattung</b>
                    <Grid container sx={{ mb: 2 }} justifyContent="flex-end">
                        {propertyEquipmentFilters.map((filter, rowIndex) => (
                            <Grid
                                key={rowIndex}
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={2}
                            >
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    sx={{
                                                        paddingBlock: 0,
                                                        ml: "10px",
                                                    }}
                                                    onChange={
                                                        handleCheckboxChange
                                                    }
                                                    name={filter}
                                                    checked={searchParams
                                                        .toString()
                                                        .includes(filter)}
                                                />
                                            }
                                            label={labels[filter]}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>

                    <b>Zustand</b>
                    <Grid container>
                        {propertyConditionFilters.map((filter, rowIndex) => (
                            <Grid
                                key={rowIndex}
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={2}
                            >
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    sx={{
                                                        paddingBlock: 0,
                                                        ml: "10px",
                                                    }}
                                                    onChange={
                                                        handleCheckboxChange
                                                    }
                                                    name={filter}
                                                />
                                            }
                                            label={labels[filter]}
                                            checked={searchParams
                                                .toString()
                                                .includes(filter)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: "1rem",
                        }}
                    >
                        <Box
                            sx={{
                                width: "50%",
                                display: "flex",
                            }}
                        >
                            <Button
                                onClick={filterHandler}
                                sx={{ ...buttonStyles.search, mr: "1rem" }}
                            >
                                Filter
                            </Button>
                            <Button
                                onClick={() => {
                                    setSearchParams({});
                                }}
                                sx={{ ...buttonStyles.search, ml: "1rem" }}
                            >
                                Zurücksetzen
                            </Button>
                        </Box>
                    </Box>
                </>
            ) : null}
        </CardContent>
    );
};
