import {
    Typography,
    FormControlLabel,
    Checkbox,
    useTheme,
} from "@mui/material";
import { type ChangeInputEvent } from "@types";
import { type ReactNode } from "react";

interface CheckboxProps {
    name: string;
    checked: boolean;

    handleChange: (e: ChangeInputEvent, value?: any) => void;
    label: string | JSX.Element | ReactNode;
    labelColor?: string;
    disabled?: boolean;
    hasError?: boolean;
}
export const CheckboxInput = ({
    name,
    checked,
    handleChange,
    label,
    labelColor,
    disabled = false,
    hasError,
}: CheckboxProps) => {
    const { customColors } = useTheme();
    return (
        <FormControlLabel
            sx={{ color: labelColor }}
            control={
                <Checkbox
                    sx={{
                        paddingBlock: 0,
                        // border: hasError
                        //     ? `2px solid ${customColors.colorError}`
                        //     : "1px solid gray",
                    }}
                    name={name}
                    checked={checked}
                    onChange={handleChange}
                    disabled={disabled}
                />
            }
            label={
                typeof label === "string" ? (
                    <Typography variant="caption">{label}</Typography>
                ) : (
                    label
                )
            }
        />
    );
};
