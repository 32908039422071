import { useSearchParams } from "react-router-dom";
import {
    Grid,
    Box,
    Typography,
    Checkbox,
    Select,
    MenuItem,
    SelectChangeEvent,
    InputLabel,
    FormControl,
    IconButton,
} from "@mui/material";
import { Message, MessageStats } from "interfaces/message.interface";
import { Dot } from "./Dot";
import { deleteMultipleMessagesAPI, formatDateTime } from "services";
import { useState } from "react";
import { FaEnvelope, FaTrash } from "react-icons/fa";

type InboxProps = {
    messages?: Message[];
    stats?: MessageStats;
    fetchMessages: () => {};
};

export const Inbox = ({ messages, stats, fetchMessages }: InboxProps) => {
    const [selectedMessages, setSelectedMessages] = useState<number[]>([]);

    const handleDelete = async () => {
        const res = await deleteMultipleMessagesAPI(selectedMessages);
        fetchMessages();
    };

    if (messages?.length === 0 || !messages) {
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={handleDelete} size="small">
                    <FaTrash />
                </IconButton>
            </Box>
            {messages && messages.length ? (
                messages?.map((msg) => (
                    <InboxRow
                        key={msg.id}
                        msg={msg}
                        selectedMessages={selectedMessages}
                        setSelectedMessages={setSelectedMessages}
                    />
                ))
            ) : (
                <Typography
                    sx={{
                        fontSize: "14px",
                        width: "100%",
                        textAlign: "center",
                    }}
                >
                    Keine Nachrichten gefunden
                </Typography>
            )}
        </Box>
    );
};

const InboxRow = ({
    msg,
    selectedMessages,
    setSelectedMessages,
}: {
    msg: Message;
    selectedMessages: number[];
    setSelectedMessages: (selectedIds: number[]) => void;
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const styles = {
        inboxRowText: {
            fontSize: "14px",
            fontWeight: msg.read ? 400 : 700,
        },
    };
    const openMessage = () => {
        setSearchParams({ messageId: msg.id.toString() });
    };

    const handleMessageSelect = (messageId: number) => {
        const tempIds = selectedMessages;
        if (selectedMessages.includes(msg.id)) {
            const newIdsArray = tempIds.filter((id) => id !== messageId);
            setSelectedMessages(newIdsArray);
        } else {
            setSelectedMessages([...tempIds, messageId]);
        }
    };

    const updateMessages = () => {};

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                paddingBlock: ".5rem",
                borderTop: "1px solid #cecece",
            }}
        >
            <Checkbox
                sx={{ mr: "1rem" }}
                onChange={() => handleMessageSelect(msg.id)}
                checked={selectedMessages.includes(msg.id)}
            />
            <Grid container onClick={openMessage} sx={{ cursor: "pointer" }}>
                <Section size="small">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Dot
                            bgColor={msg.read ? "" : "#0c3582e1"}
                            size="8px"
                            sx={{ marginRight: ".25rem" }}
                            // alignSelfTop
                        />

                        <Typography sx={styles.inboxRowText}>
                            {msg.first_name + "" + msg.last_name}
                        </Typography>
                    </Box>
                </Section>

                <Section size="large">
                    <Typography
                        sx={{ ...styles.inboxRowText, marginInline: ".5rem" }}
                    >
                        {msg.message_preview}
                    </Typography>
                </Section>

                <Section size="medium">
                    <Typography
                        sx={{ ...styles.inboxRowText, marginInline: ".5rem" }}
                    >
                        {formatDateTime(msg.created_on)}
                    </Typography>
                </Section>
            </Grid>
        </Box>
    );
};

const Section = ({
    children,
    size,
}: {
    children: JSX.Element;
    size?: "large" | "medium" | "small";
}) => {
    if (size === "large")
        return (
            <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
                {children}
            </Grid>
        );
    if (size === "medium")
        return (
            <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
                {children}
            </Grid>
        );
    return (
        <Grid item xs={1.5} sx={{ display: "flex", alignItems: "center" }}>
            {children}
        </Grid>
    );
};
