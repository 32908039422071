import { propertyFormStyles } from 'components/styles'
import { Box } from '@mui/system'
import { type ReactNode } from 'react'

export const TwoColumnForm = ({
  leftSide,
  rightSide
}: {
  leftSide: ReactNode
  rightSide: ReactNode
}) => {
  return (
        <Box sx={propertyFormStyles.twoColumnForm}>
            <Box sx={propertyFormStyles.twoColumnFormHalf}>{leftSide}</Box>
            <Box sx={propertyFormStyles.twoColumnFormHalf}>{rightSide}</Box>
        </Box>
  )
}
