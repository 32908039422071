import "./energy_bar.css";
import { Tooltip } from "@mui/material";

export const EnergyBar = ({
    score,
    grade,
}: {
    score: number | null;
    grade: string | null;
}) => {
    if (!score || !grade) return null;
    const getPercent = () => {
        const scoreForCalc = score > 285 ? 293 : score;
        const percent = (scoreForCalc * 100) / 293;
        return percent;
    };
    return (
        <div className="energy_container">
            <div className="pointer" style={{ left: getPercent() + "%" }}>
                <Tooltip placement="top" title="energy">
                    <>
                        {score} kWh / (m<sup>2</sup>a) <br />
                        Energieeffizienz-Klasse: {grade}
                    </>
                </Tooltip>
            </div>
            <div className="energy_bar">
                <div className="bar_color before_bar"></div>
                <div className="bar_color a_energy_bar_plus">A+</div>
                <div className="bar_color a_energy_bar">A</div>
                <div className="bar_color b_energy_bar">B</div>
                <div className="bar_color c_energy_bar">C</div>
                <div className="bar_color d_energy_bar">D</div>
                <div className="bar_color e_energy_bar">E</div>
                <div className="bar_color f_energy_bar">F</div>
                <div className="bar_color g_energy_bar">G</div>
                <div className="bar_color h_energy_bar">H</div>
            </div>
            <div className="box_label">
                <div className="bar_label before_bar_label">0</div>
                <div className="bar_label a_plus_label">30</div>
                <div className="bar_label a_label">50</div>
                <div className="bar_label b_label">75</div>
                <div className="bar_label c_label">100</div>
                <div className="bar_label d_label">130</div>
                <div className="bar_label e_label">160</div>
                <div className="bar_label f_label">200</div>
                <div className="bar_label g_label">250</div>
                <div className="bar_label h_label">250</div>
            </div>
        </div>
    );
};
